import { Cta, CtaWithUrl } from '../../types/StandardHeroContent'
import { ContentfulEntryLink, ContentfulSimpleLink } from '../../types/Contentful/ContentfulTypes'
import { CategoryPageSectionType } from '../../types/TemplateConfiguration'
import { PAGEHEADER_TYPES } from '../../components/header/PageHeaderContainer'

const constructCtaWithUrl = (
  pageReferencedFields: Record<string, ContentfulEntryLink>,
  cta?: Cta
): CtaWithUrl | undefined => {
  if (!cta) {
    return
  }

  const referencedField = pageReferencedFields[cta.contentful_id] as ContentfulSimpleLink
  if (referencedField) {
    const ctaWithUrl: CtaWithUrl = {
      ...cta,
      targetUrl: referencedField.targetUrl,
    }
    return ctaWithUrl
  }
}

export const getCtaSkinColor = (
  ctasWithUrlLength: number,
  ctaIndex: number
): 'primary' | 'secondary' => {
  if (ctasWithUrlLength === 2) {
    return ctaIndex === 0 ? 'primary' : 'secondary'
  } else {
    return 'primary'
  }
}

export const getCtasWithUrl = (
  referencedFields: Record<string, ContentfulEntryLink>,
  cta1?: Cta,
  cta2?: Cta,
  cta3?: Cta
) => {
  const ctasWithUrl: CtaWithUrl[] = [];
  [cta1, cta2, cta3].forEach((cta) => {
    const ctaWithUrl = constructCtaWithUrl(referencedFields, cta)
    if (ctaWithUrl) {
      ctasWithUrl.push(ctaWithUrl)
    }
  })
  return ctasWithUrl
}

export const isOfTypePageHeader = (sectionType: CategoryPageSectionType) => {
  return PAGEHEADER_TYPES.includes(sectionType)
}

export const getFontFamilyType = (isPageHeader: boolean, selectedFontFamily?: string) => {
  let fontFamily
  if (isPageHeader && selectedFontFamily && selectedFontFamily !== 'Graphik') {
    fontFamily = 'special'
  }
  return fontFamily
}

export const getFontFamilyWeight = (isPageHeader: boolean, selectedFontFamily?: string) => {
  let fontWeight
  if (isPageHeader && selectedFontFamily && selectedFontFamily !== 'Graphik') {
    fontWeight = 'normal'
  }
  return fontWeight
}
