import {
  BasicResponsiveImage,
  BoundedContent,
  Carousel,
  CarouselSlide,
  Column,
  FlexBox,
  GridContainer,
  H2,
  H3,
  Link,
  Row,
  StandardTile,
  StandardTileContents,
  StandardTileImage,
  StandardTileName,
  Typography,
  useScreenClass,
} from '@vp/swan'
import { useAppContext } from '../state/AppContext'
import { getTemplateLaneGalleryInfo } from '../utils/templateLane/templateLane'
import { Gallery } from '../types/Gallery'
import { trackClickEvent } from '../utils/tracking/tracking'
import { getAccessibleTextForDots } from '../utils/accessibility/accessibility'
import { DebugPageSections } from './debugComponents/DebugPageSections'
import { TemplateLaneType } from '../types/TemplateLane'
import { Translated } from '../types/Global'
import { SkipToNextSectionLink } from './SkipToNextSectionLink'
import { MARKETING_COLORS } from '@vp/marketing-colors'

interface TemplateLaneProps {
  content: TemplateLaneType;
  galleries?: Gallery[];
  nextSectionId?: string;
  nextSectionTitle?: Translated;
}

const TemplateLane = (props: TemplateLaneProps) => {
  const { content, galleries, nextSectionId, nextSectionTitle } = props
  const {
    locale,
    backgroundColor,
    sidebarEnabled,
    merchandisingCategoryId,
    dictionaryEntries,
  } = useAppContext()

  const isMobile = useScreenClass() === 'xs'
  const darkMode = MARKETING_COLORS[backgroundColor]?.mode.dark
  const desktopSlideCount = sidebarEnabled ? 3 : 4

  // The slidesToShow property now supports responsive values, helps prevent flicker on page load.
  const slidestoShowCount = {
    xs: 2.25,
    sm: desktopSlideCount,
    md: desktopSlideCount,
    lg: desktopSlideCount,
    xl: desktopSlideCount,
  }

  const galleryInfo = getTemplateLaneGalleryInfo(content, locale, galleries)

  if (!galleryInfo?.images || galleryInfo.images.length < 1) {
    return <></>
  }

  const { accessibleTextForNext, accessibleTextForPrevious, designImageAltTextPrefix } =
    dictionaryEntries
  const accessibleTextForDots = getAccessibleTextForDots(
    dictionaryEntries.accessibleTextForDots,
    galleryInfo.images.length
  )

  return (
    <>
      <BoundedContent>
        <Row pt={9} mb='5'>
          <Column span={10}>
            {content?.title?.value && (
              <H2 fontSize='x2large' mb={0} darkMode={darkMode}>
                {content.title.value}
              </H2>
            )}
          </Column>
        </Row>
        <SkipToNextSectionLink nextSectionId={nextSectionId} nextSectionTitle={nextSectionTitle} />
        <Row pb='6'>
          <Column span={sidebarEnabled ? 7 : 5}>
            {content?.subtitle?.value && (
              <Typography darkMode={darkMode}>{content.subtitle.value}</Typography>
            )}
          </Column>
          <Column span={sidebarEnabled ? 5 : 7}>
            <FlexBox alignItems='flex-end' justifyContent='flex-end'>
              {content?.cta?.text && (
                <Typography darkMode={darkMode} fontSize='standard'>
                  <Link
                    skin='cta'
                    href={galleryInfo.url}
                    data-section='Template Lane'
                    data-translation='CTA'
                    onClick={() =>
                      trackClickEvent({
                        categoryId: merchandisingCategoryId,
                        destinationUrl: galleryInfo.url,
                        ctaValue: 'CTA',
                        pageZone: 'Template Lane',
                      })}
                  >
                    {content.cta.text}
                  </Link>
                </Typography>
              )}
            </FlexBox>
          </Column>
        </Row>
        <Row pb='7'>
          <Column span={12}>
            <GridContainer gutter='tight'>
              <Carousel
                swipeToSlide
                darkMode={darkMode}
                slidesToShow={slidestoShowCount}
                gridGutters
                progressIndicator='dots'
                progressIndicatorAlignment='center'
                arrows={!isMobile}
                infinite={false}
                accessibleTextForPrevious={accessibleTextForPrevious}
                accessibleTextForDots={accessibleTextForDots}
                accessibleTextForNext={accessibleTextForNext}
              >
                {galleryInfo.images.map((image: any, index: number) => {
                  return (
                    <CarouselSlide key={`template-lane-image-${index}`}>
                      <StandardTile
                        mb='5'
                        skin='product'
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
                        onClick={() => {
                          trackClickEvent({
                            categoryId: merchandisingCategoryId,
                            destinationUrl: image.galleryUrl,
                            ctaValue: `Tile ${index}`,
                            pageZone: 'Template Lane',
                            index: index + 1,
                          })
                        }}
                      >
                        <StandardTileImage>
                          <BasicResponsiveImage
                            aspectRatio={1}
                            src={`${
                              typeof window !== 'undefined' && !image.imageUrl?.startsWith('http')
                                ? window.location.protocol
                                : ''
                            }${image.imageUrl}`}
                            alt={getDesignImageAltText(designImageAltTextPrefix, image)}
                          />
                        </StandardTileImage>
                        <StandardTileContents>
                          <Link
                            covering
                            skin='unstyled'
                            href={image.galleryUrl}
                            data-section='Template Lane'
                            data-position={index + 1}
                            data-translation={`Tile ${index}`}
                          >
                            <StandardTileName>
                              <H3 darkMode={darkMode} fontSize='small'>
                                {image.comparativeName}
                              </H3>
                            </StandardTileName>
                          </Link>
                        </StandardTileContents>
                      </StandardTile>
                    </CarouselSlide>
                  )
                })}
              </Carousel>
            </GridContainer>
          </Column>
        </Row>
        <DebugPageSections rawSectionData={content} resolvedSectionData={{ galleryInfo }} />
      </BoundedContent>
    </>
  )
}

const showTemplateLane = (content: any, locale: string, galleries?: Gallery[]) => {
  const galleryInfo = getTemplateLaneGalleryInfo(content, locale, galleries)
  return galleryInfo?.images && galleryInfo.images.length > 0
}

const getDesignImageAltText = (designImageAltTextPrefix: string, image: any) => {
  let altText = image.comparativeName
  if (designImageAltTextPrefix) {
    altText = `${designImageAltTextPrefix.trim()} ${image.comparativeName}`
  }
  return altText
}

export { showTemplateLane }

export default TemplateLane
