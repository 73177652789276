import { SidebarLink } from '../../types/TemplateConfiguration'
import { ShortMsxMpv } from '../../types/ShortMsxMpv'
import { PageUrl } from '../../types/Url'
import { ContentfulEntryLink, ContentfulSimpleLink } from '../../types/Contentful/ContentfulTypes'
import { PageAvailability } from '../../types/PageAvailability'

export const getLink = (
  link: SidebarLink,
  mpvs: ShortMsxMpv[],
  pageUrls: PageUrl[],
  pageAvailabilities: PageAvailability[],
  pageReferencedFields: Record<string, ContentfulEntryLink>
) => {
  let text = ''
  let url = ''
  let externallyMarketable = true
  let associatedPageAvailable = true

  if (link?.__typename === 'globalMerchandisingProductView') {
    const mpv = mpvs.find((mpv) => link.mpvId === mpv.mpvId)
    url = pageUrls.find((pageUrl) => link.mpvId === pageUrl.mpvId)?.url || ''
    text = mpv?.name || ''
    externallyMarketable = mpv?.externallyMarketable || false
    associatedPageAvailable =
      pageAvailabilities.find((pageAvailability) => link.mpvId === pageAvailability.mpvId)
        ?.availability || false
  } else if (link?.__typename === 'merchandisingCategory') {
    url = pageUrls.find((pageUrl) => link.mpvId === pageUrl.mpvId)?.url || ''
    text = link.name?.value || ''
    associatedPageAvailable =
      pageAvailabilities.find((pageAvailability) => link.mpvId === pageAvailability.mpvId)
        ?.availability || false
  } else {
    const referencedField =
      link && (pageReferencedFields[`${link.contentful_id}`] as ContentfulSimpleLink)
    if (referencedField) {
      url = referencedField.targetUrl
      text = link.text || ''
    }
  }

  return {
    text,
    url,
    externallyMarketable,
    associatedPageAvailable,
  }
}
