import { Column, H2, Row, UncustomizableMarkup } from '@vp/swan'
import { useStyles } from '@vp/ubik-context'
import React from 'react'
import sanitizeHtml from 'sanitize-html'
import { useAppContext } from '../state/AppContext'
import { DebugPageSections } from './debugComponents/DebugPageSections'

import { stylesheet } from '../styles/UncustomizableMarkupSnowflakes.scss'

interface SeoProps {
  sectionTitle: string;
  content: string;
}

const Seo: React.FC<SeoProps> = (props: SeoProps) => {
  useStyles(stylesheet)
  const { sectionTitle, content } = props
  const { sidebarEnabled } = useAppContext()

  return (
    <>
      <Row>
        <Column span={sidebarEnabled ? 7 : 5} spanXs={12}>
          <H2 fontSize='x2large' marginBottom='5'>
            {sectionTitle}
          </H2>
          <UncustomizableMarkup
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content),
            }}
          />
        </Column>
      </Row>
      <DebugPageSections rawSectionData={{ sectionTitle, content }} />
    </>
  )
}

const showSeoSection = (sectionTitle: string, content: string) => {
  return !!(sectionTitle && content)
}

export { showSeoSection }

export default Seo
