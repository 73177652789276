export default class PricingContext {
  static getPricingContext (): string {
    const doc = PricingContext.getDocument() as any
    return doc?.pcxtV3?.isInitialized() ? doc.pcxtV3.getEncodedContextString() ?? '' : ''
  }

  static isInitialized () {
    const doc = PricingContext.getDocument() as any
    return doc?.pcxtV3?.isInitialized() ?? false
  }

  private static getDocument (): Document | undefined {
    return typeof document !== 'undefined' ? document : undefined
  }
}
