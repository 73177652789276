import { Row, Column, H2, UncustomizableMarkup } from '@vp/swan'
import { useStyles } from '@vp/ubik-context'
import React from 'react'
import sanitizeHtml from 'sanitize-html'
import {
  ContentfulCloudinaryImage,
  ContentfulCloudinaryVideoMedia,
} from '../types/Contentful/ContentfulTypes'
import { InformationMediaType } from '../types/TemplateConfiguration'
import { CloudinaryImageMedia } from './cloudinary/CloudinaryImageMedia'
import PlayPauseVideo from './cloudinary/PlayPauseVideo'
import { stylesheet } from '../styles/UncustomizableMarkupSnowflakes.scss'

interface InformationMediaProps {
  section: InformationMediaType;
  sectionNumber: number;
}

const InformationMedia = (props: InformationMediaProps): React.JSX.Element => {
  const { section, sectionNumber } = props
  useStyles(stylesheet)
  const renderedMedia = renderMedia(sectionNumber, section.name, section.media)
  const pushPull = section.showMediaOnLeft ? 6 : 0

  return (
    <>
      <Row>
        <Column span={6}>
          <H2 fontSize='x2large' marginBottom='5'>
            {section.title?.value}
          </H2>
        </Column>
      </Row>
      <Row>
        <Column span={6} push={pushPull}>
          <UncustomizableMarkup
            className='remove-p-margin'
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(section.info?.value || ''),
            }}
          />
        </Column>
        <Column span={6} pull={pushPull}>
          {renderedMedia}
        </Column>
      </Row>
    </>
  )
}

export const renderMedia = (
  sectionNumber: number,
  name: string,
  media?: ContentfulCloudinaryImage | ContentfulCloudinaryVideoMedia
): React.JSX.Element => {
  const imageMedia = media as ContentfulCloudinaryImage
  const videoMedia = media as ContentfulCloudinaryVideoMedia

  if (imageMedia?.cloudinaryImage?.fullWidthHeroCloudinaryUrl) {
    return <CloudinaryImageMedia media={imageMedia} />
  } else if (videoMedia?.video?.videoUrl) {
    return (
      <PlayPauseVideo
        publicId={videoMedia.video.publicId}
        playerId={`information-media-section-${sectionNumber}`}
        posterImageFrame={videoMedia.posterImageFrame}
        sectionName={name}
        media={videoMedia}
      />
    )
  } else {
    return <></>
  }
}

export const showInformationMediaSection = (section: InformationMediaType) : boolean => {
  return !!section.title?.value && !!section.info?.value
}

export default InformationMedia
