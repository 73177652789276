export const BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_NAME =
  'business_cards_popular_templates_component'

export const BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_VARIATION_1 = 'test'

export const BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_VARIATION_2 = 'test_2'

export const BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_CONTROL = 'control'

export const BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_SUPPORTED_MC_IDS = [
  'businessCards',
]

export const BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_SUPPORTED_LOCALES = [
  'en-US',
  'es-US',
  'en-GB',
]
