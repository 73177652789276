import { Li, Typography, Ul, tokens } from '@vp/swan'
import React from 'react'

interface Props {
  text: string[] | undefined;
}

const BulletPointsDescription: React.FC<Props> = ({ text }) => {
  let numberOfBullets: number = 0
  const bulletsStyle = {
    padding: `${tokens.SwanSemSpaceNone} ${tokens.SwanSemSpaceNone} ${tokens.SwanSemSpace2} ${tokens.SwanSemSpaceNone}`,
  }

  enum BoxOrient {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
  }

  const lineStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: BoxOrient.Vertical,
    MozBoxOrient: BoxOrient.Vertical, // Mozilla fallback
    msBoxOrient: BoxOrient.Vertical, // IE fallback
    lineHeight: tokens.SwanBaseFontLineHeight500,
    maxHeight: `calc(${tokens.SwanBaseFontLineHeight500} * 2)`,
  }

  const handleBulletText = (line: string) => {
    if (line.startsWith('-') || line.startsWith('*')) {
      if (numberOfBullets < 3) {
        numberOfBullets++
        return (
          <Li style={bulletsStyle} key={numberOfBullets}>
            <span style={lineStyle}>{line.substring(2)}</span>
          </Li>
        )
      } else {
        return <></>
      }
    }
  }

  return Array.isArray(text) &&
    text.length > 1 &&
    (text[0].startsWith('-') || text[0].startsWith('*'))
    ? (
      <Ul>{text.map((line, index) => handleBulletText(line.trim().toString()))}</Ul>
      )
    : (
      <Typography style={{ lineHeight: tokens.SwanBaseFontLineHeight500 }}>{text}</Typography>
      )
}

export default BulletPointsDescription
