import { Typography, FlexBox, Button, Link, Box } from '@vp/swan'
import React, { useState } from 'react'
import { useAppContext } from '../../state/AppContext'
import { DebugJsonView } from './DebugJsonView'
import { useStyles } from '@vp/ubik-context'

import { stylesheet } from '../../styles/DebugPageSections.scss'
export interface DebugPageSectionsProps {
  rawSectionData: object;
  resolvedSectionData?: object;
}

export const DebugPageSections = (props: DebugPageSectionsProps) => {
  const { rawSectionData, resolvedSectionData } = props
  const { debugMode } = useAppContext()
  useStyles(stylesheet)
  if (!debugMode) {
    return <></>
  }

  const [showDebugData, setShowDebugData] = useState(false)

  return (
    <>
      <Box className='debug-page-section'>
        <FlexBox flexDirection='column'>
          <Typography>
            Debug information for ⬆️ section. This information will only show in debug mode.
          </Typography>
        </FlexBox>
        <FlexBox>
          <Typography mr='3'>Debug:</Typography>
          {!showDebugData && (
            <Link
              render={(p:HTMLElement) => (
                <Button
                  skin='link'
                  className={p.className}
                  onClick={() => setShowDebugData(!showDebugData)}
                >
                  {p.children}
                </Button>
              )}
            >
              Show details
            </Link>
          )}
          {showDebugData && (
            <Link
              render={(p:HTMLElement) => (
                <Button
                  skin='link'
                  className={p.className}
                  onClick={() => setShowDebugData(!showDebugData)}
                >
                  {p.children}
                </Button>
              )}
            >
              Hide details
            </Link>
          )}
        </FlexBox>
        {showDebugData && (
          <FlexBox flexDirection='column'>
            <Typography fontWeight='bold'>Raw section data</Typography>
            {rawSectionData
              ? (
                <DebugJsonView collapsed src={rawSectionData || {}} />
                )
              : (
                  'Not set'
                )}
            <Typography fontWeight='bold'>Resolved section data</Typography>
            {resolvedSectionData
              ? (
                <DebugJsonView collapsed src={resolvedSectionData || {}} />
                )
              : (
                  'Not set'
                )}
          </FlexBox>
        )}
      </Box>
    </>
  )
}
