import axios from 'axios'
import { DEFAULT_TENANT } from '../../constants/serverSideProps'
import { cutOffDate, filterOrderMpvIds } from './reorderConstants'

const buildStandardHeaders = (auth: any): Headers => {
  const headerOptions = {
    'Access-Control-Allow-Origin': '*',
    Authorization: auth.getAuthorizationHeader() || '',
  }
  const headers = new Headers(headerOptions)
  return headers
}

const buildStandardJsonHeaders = (auth: any): Headers => {
  const headerOptions = {
    'Access-Control-Allow-Origin': '*',
    Authorization: auth.getAuthorizationHeader() || '',
  }
  const headers = new Headers(headerOptions)
  headers.append('Content-Type', 'application/json')
  return headers
}
export const getFilteredOrderDetails = async (
  auth: any,
  shopperId: string,
  locale: string,
  tenantId: string,
  pageIndex: number = 0,
  allItems: any[] = []
): Promise<undefined | Record<any, any>> => {
  const baseUrl = 'https://ripple.orders.vpsvc.com'
  const pageSize = 50
  const myHeaders = buildStandardHeaders(auth)
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  }

  try {
    const response = await fetch(
      `${baseUrl}/items/${tenantId}/shopper/${shopperId}?pageSize=${pageSize}&page=${pageIndex}&includeRetired=false`,
      requestOptions
    )
    if (response && response.status === 200) {
      const data = await response.json()
      const newItems = data.items
      const updatedItems = allItems.concat(newItems)
      const filteredOrders = await filterOrders(
        updatedItems,
        locale
      )
      if (filteredOrders.length > 0) {
        return filteredOrders[0]
      }
      if (newItems.length === 0 || updatedItems.length >= data.total) {
        return undefined
      }
      return getFilteredOrderDetails(
        auth,
        shopperId,
        tenantId,
        locale,
        pageIndex + 1,
        updatedItems
      )
    }
  } catch (error) {
    console.error(error)
    return undefined
  }
  return undefined
}

const filterOrders = async (orders: any[], locale: string) => {
  const filteredOrders: any[] = []
  for (const orderDetails of orders) {
    const cutoffDate = parseDate(cutOffDate)
    if (new Date(orderDetails.orderedDate) < cutoffDate) {
      continue
    }
    let mpvId = orderDetails.externalUseData?.analytics?.product_id
    if (!mpvId) {
      mpvId = await getMpvIdsFromProductKey(
        orderDetails.productData.productKey,
        locale
      )
    }
    orderDetails.mpvId = mpvId
    const selections: Record<string, string> = {}
    orderDetails.productData.productAttributes.forEach((attr:{
      key: string,
      value: string,
      keyDisplayName: string,
      valueDisplayName: string
    }) => {
      selections[attr.key] = attr.value
    })
    orderDetails.selections = selections
    const isMatch = filterOrderMpvIds.some((term) =>
      mpvId.toLowerCase().includes(term.toLowerCase())
    )

    if (isMatch) {
      filteredOrders.push(orderDetails)
    }
  }
  return filteredOrders
}

export const getAltCartId = async (
  auth: any,
  shopperId: string,
  tenantId: string,
  orderDetails: any
) => {
  const baseUrl = 'https://oreos.orders.vpsvc.com'
  const myHeaders = buildStandardJsonHeaders(auth)
  const raw = JSON.stringify([
    {
      lineItemId: orderDetails.lineItemId,
      quantity: orderDetails.originalOrderedQuantity,
      design: {
        livePreviewUrl: orderDetails.designData?.livePreviewUrl ?? '',
        documentReferenceUrl: orderDetails.designData?.docRefUrl ?? '',
        editDocumentUrl: orderDetails.designData?.editDocumentUrl ?? '',
        docReview: {
          required: orderDetails.designData?.docReviewRequired ?? null,
          notes: orderDetails.designData?.docReviewNotes ?? '',
        },
      },
      analytics: orderDetails.externalUseData.analytics,
    },
  ])
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  }
  try {
    const response = await fetch(
      `${baseUrl}/reorderForAltCheckout/shoppers/${shopperId}/orders/${orderDetails.orderNumber}/cartTenant/${tenantId}`,
      requestOptions
    )
    if (response && response.status === 200) {
      const data = await response.json()
      return data.altCartId ?? ''
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

export const addToCart = async (
  auth: any,
  shopperId: string,
  tenantId: string,
  orderDetails: any
) => {
  const baseUrl = 'https://oreos.orders.vpsvc.com'
  const myHeaders = buildStandardJsonHeaders(auth)
  const raw = JSON.stringify([
    {
      lineItemId: orderDetails.lineItemId,
      quantity: orderDetails.originalOrderedQuantity,
      design: {
        livePreviewUrl: orderDetails.designData?.livePreviewUrl ?? '',
        documentReferenceUrl: orderDetails.designData?.docRefUrl ?? '',
        editDocumentUrl: orderDetails.designData?.editDocumentUrl ?? '',
        docReview: {
          required: orderDetails.designData?.docReviewRequired ?? null,
          notes: orderDetails.designData?.docReviewNotes ?? '',
        },
      },
      analytics: orderDetails.externalUseData.analytics,
    },
  ])
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  }
  try {
    const response = await fetch(
      `${baseUrl}/v2/reorder/shoppers/${shopperId}/orders/${orderDetails.orderNumber}/cartTenant/${tenantId}`,
      requestOptions
    )

    const responseData = response.status !== 204 ? await response.json() : null

    return response.ok
      ? {
          success: true,
          status: response.status,
          data: responseData
        }
      : {
          success: false,
          status: response.status,
          error:
            responseData?.error,
          details: responseData || null,
        }
  } catch (error) {
    console.error('Error in addToCart:', error)
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Unknown error occurred',
    }
  }
}

export const getMpvIdsFromProductKey = async (
  productKey: string,
  culture: string
): Promise<string | undefined> => {
  const tenant = DEFAULT_TENANT
  const msxUrl =
    'https://merchandising-site-experience.prod.merch.vpsvc.com/api/v1'
  const requestor = 'category-page'
  const queryString = `cultures=${culture}`

  const url = `${msxUrl}/tenant/${tenant}/productKey/${productKey}/mpvs?requestor=${requestor}&${queryString}`

  try {
    const response = await axios.get(url)
    if (response.data) {
      return Object.values(response.data)[0] as string
    }
  } catch (e) {
    return undefined
  }

  return undefined
}

function parseDate (dateString: string) {
  const [day, month, year] = dateString.split('-')
  return new Date(`${year}-${month}-${day}`)
}
