import { useAppContext } from '../../state/AppContext'
import { ProductTileModel } from '../../utils/productTileCollection/productTileCollection'
import { ProductTile } from '../ProductTile'
import { ProductTileV2 } from '../ProductTileV2'

interface ProductGridLaneErrorProps {
  tile: ProductTileModel;
  index: number;
  productGridLayout?: 'horizontal' | 'vertical';
  hideProductDescription?: boolean;
  colorSwatchCount: number;
}

export const ProductGridLaneError = ({
  tile,
  index,
  productGridLayout,
  hideProductDescription,
  colorSwatchCount,
}: ProductGridLaneErrorProps) => {
  const tileProps = tile.product?.template
  return (
    <>
      {tileProps
        ? (
          <ProductTileV2
            name={tileProps?.tileDetails?.header?.value ?? ''}
            description={
            !hideProductDescription ? tileProps?.tileDetails?.description?.value ?? '' : ''
          }
            image={tileProps?.tileDetails?.image?.cloudinaryImage?.[0]}
            linkUrl={tileProps?.tileDetails?.link?.href}
            callout={'Unavailable (Won\'t show in prod)'}
            calloutSkin='holiday'
            calloutVariant='inverse'
            layout={productGridLayout || 'vertical'}
            disabled
            index={index + 1}
            pageZone=''
            mpvId={tile.mpvId}
            productId={tileProps?.productKey}
            showPrice={!!tile.showPrice}
            enableCampaignCallouts
            colorInformation={tile.colorInformation}
            colorSwatchCount={colorSwatchCount}
          />
          )
        : (
          <ProductTile
            name={tile.name ?? ''}
            description={!hideProductDescription ? tile.description : ''}
            image={tile.image}
            linkUrl={tile.linkUrl}
            callout={'Unavailable (Won\'t show in prod)'}
            calloutSkin='holiday'
            calloutVariant='inverse'
            layout={productGridLayout || 'vertical'}
            productTileLongDescription={
            !hideProductDescription ? tile?.productTileLongDescription : undefined
          }
            disabled
            index={index + 1}
            pageZone=''
            mpvId={tile.mpvId}
            showPrice={!!tile.showPrice}
            enableCampaignCallouts
            colorSwatchCount={colorSwatchCount}
          />
          )}
    </>
  )
}

export const renderProductDebugError = (tile: ProductTileModel) => {
  const { debugMode } = useAppContext()
  return tile && (!tile.externallyMarketable || !tile.associatedPageAvailable) && debugMode
}
