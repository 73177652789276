import PageSectionWrapper from '../../components/PageSectionWrapper'
import { pageCategoryPageV3 } from '../../types/Contentful/ContentfulTypes'
import { BFFPageData } from '../../services/serviceClients/BffClient'
import { getRegisteredTest } from './testRegistry'
import { BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_NAME, BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_VARIATION_1, BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_VARIATION_2 } from '../../constants/DesignTemplateExperimentTest'
import { formatLocale } from '../../utils/buildHelpers'

export interface ExperimentData {
  [key: string]: {
    variationKey?: string;
    [key: string]: any;
  };
}
const getActiveExperiments = (experimentData: ExperimentData) => {
  return Object.keys(experimentData).filter(
    (experimentKey) => !!experimentData[experimentKey]?.variationKey
  )
}

export const TestSection = (experimentData: ExperimentData, sectionType: string) => {
  const activeExperimentKeys = getActiveExperiments(experimentData)

  for (const experimentKey of activeExperimentKeys) {
    const variationKey = experimentData[experimentKey]?.variationKey
    const registeredTest = variationKey
      ? getRegisteredTest(experimentKey, variationKey)
      : undefined
    const sectionOverride = (registeredTest || []).find((test) => test.sectionType === sectionType)
    if (sectionOverride && experimentData.isPageSection) {
      return sectionOverride.component
    }
  }
}

export const addNewTestSections = (props: {
  experimentData: ExperimentData;
  sections: (false | JSX.Element | null | undefined | '')[];
  pageDataFromBFF: BFFPageData;
  pageCategoryPageV3: pageCategoryPageV3;
  locale: string;
  experiments: Record<string, string | undefined>;
  isMobile: boolean;
  identity: any;
  merchandisingCategoryId: string;
}) => {
  const { experimentData, sections, pageDataFromBFF, pageCategoryPageV3, experiments, isMobile, identity, merchandisingCategoryId, locale } = props
  const activeExperimentKeys = getActiveExperiments(experimentData)
  for (const experimentKey of activeExperimentKeys) {
    const variationKey = experimentData[experimentKey]?.variationKey
    const isPageSection = experimentData[experimentKey]?.isPageSection ?? true
    const registeredTest = variationKey
      ? getRegisteredTest(experimentKey, variationKey)
      : undefined
    const sectionOverride = (registeredTest || []).find((test) => !!test.position)
    const displayTestComponent = sectionOverride?.mcIds?.includes(merchandisingCategoryId!) && sectionOverride?.locales?.includes(formatLocale(locale, true))

    if (sectionOverride && displayTestComponent) {
      const position = sectionOverride.position!
      const NewSection = sectionOverride.component
      const sectionProps = { pageDataFromBFF, pageCategoryPageV3 }

      const WrappedNewSection = isPageSection
        ? (
          <PageSectionWrapper key={`test-section-${position}`}>
            <NewSection {...sectionProps} />
          </PageSectionWrapper>
          )
        : (
          <NewSection {...sectionProps} />
          )
      sections.splice(position, 0, WrappedNewSection)
    }
  }

  for (const experimentKey of Object.keys(experiments)) {
    let newSectionPosition = -1
    if (experimentKey === BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_NAME) {
      // @ts-ignore
      const gbbPosition = sections.findIndex((section) => section?.props?.type === 'good better best')
      if (experiments[experimentKey] === BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_VARIATION_1) {
        if (isMobile) {
          newSectionPosition = gbbPosition - 1
        } else {
          newSectionPosition = gbbPosition + 1
        }
      }
      if (experiments[experimentKey] === BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_VARIATION_2) {
        if (identity?.isSignedIn) {
          newSectionPosition = gbbPosition + 1
        } else {
          newSectionPosition = gbbPosition - 1
        }
      }
    }

    const variationKey = experiments[experimentKey]
    const isPageSection = true
    const registeredTest = variationKey
      ? getRegisteredTest(experimentKey, variationKey, {
        newSectionPosition
      })
      : undefined
    const sectionOverride = (registeredTest || []).find((test) => !!test.position)
    const displayTestComponent = sectionOverride?.mcIds?.includes(merchandisingCategoryId!) && sectionOverride?.locales?.includes(formatLocale(locale, true))

    if (sectionOverride && displayTestComponent) {
      const position = sectionOverride.position!
      const NewSection = sectionOverride.component
      const sectionProps = { pageDataFromBFF, pageCategoryPageV3 }

      const WrappedNewSection = isPageSection
        ? (
          <PageSectionWrapper key={`test-section-${position}`}>
            <NewSection {...sectionProps} />
          </PageSectionWrapper>
          )
        : (
          <NewSection {...sectionProps} />
          )
      sections.splice(position, 0, WrappedNewSection)
    }
  }

  return sections
}
