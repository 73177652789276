import React, { createContext, useContext } from 'react'
import { getSupportedLocales, getSupportedMechandisingId, RegisteredTests } from '../ab-tests/utils/testRegistry'
import useExperiment from '../ab-tests/utils/useExperiment'
import { BUSINESS_CARDS_REORDER_EXPERIMENT_CONTROL, BUSINESS_CARDS_REORDER_EXPERIMENT_NAME, BUSINESS_CARDS_REORDER_EXPERIMENT_VARIATION } from './reorderComponents/reorderExperimentData'

type ExperimentsContextValue = {
  [testKey in RegisteredTests]: any;
}
interface ExperimentsProviderProps {
  children: React.ReactNode;
}

const ExperimentsContext = createContext<ExperimentsContextValue>({
  [RegisteredTests.BUSINESS_CARDS_REORDER]: {},
})

const supportedVariations = [
  BUSINESS_CARDS_REORDER_EXPERIMENT_VARIATION,
  BUSINESS_CARDS_REORDER_EXPERIMENT_CONTROL,
]
const supportedLocales = getSupportedLocales(BUSINESS_CARDS_REORDER_EXPERIMENT_NAME, BUSINESS_CARDS_REORDER_EXPERIMENT_VARIATION)
const supportedMerchandisingCategories = getSupportedMechandisingId(BUSINESS_CARDS_REORDER_EXPERIMENT_NAME, BUSINESS_CARDS_REORDER_EXPERIMENT_VARIATION)

const ExperimentsProvider = ({
  children,
} : ExperimentsProviderProps) => {
  const businessCardReorderVariationKey = useExperiment(
    supportedVariations,
    supportedLocales,
    supportedMerchandisingCategories,
    RegisteredTests.BUSINESS_CARDS_REORDER
  )

  const reorderSection = {
    variationKey: businessCardReorderVariationKey,
  }

  const value = {
    [RegisteredTests.BUSINESS_CARDS_REORDER]: reorderSection,
  }

  return (
    <ExperimentsContext.Provider value={value}>{children}</ExperimentsContext.Provider>
  )
}

export const useExperimentsContext = () => useContext(ExperimentsContext)

export default ExperimentsProvider
