import {
  Column,
  Row,
  Card,
  Typography,
  Link,
  FlexBox,
  H2,
  Span,
  Icon,
} from '@vp/swan'
import {
  RawVirtualSectionData,
  TileType,
  UploadFlowTile,
  VirtualSectionTile,
} from '../types/VirtualSection'
import PageConfig from '../utils/pageConfig'
import UploadFlow from '@vp/upload-flow'
import {
  buildVirtualSection,
  isTileEnabled,
  isUploadFlowTileEnabled,
} from '../utils/virtualSection/virtualSection'
import { ShortMsxMpv } from '../types/ShortMsxMpv'
import { MsxMpv } from '../types/Msx'
import PricingContext from '../services/serviceClients/PricingContext'
import { useAppContext } from '../state/AppContext'
import { Gallery } from '../types/Gallery'
import { trackClickEvent } from '../utils/tracking/tracking'
import { ContentfulCloudinaryImageComponent } from '../types/Contentful/ContentfulTypes'
import { DebugPageSections } from './debugComponents/DebugPageSections'
import { useStyles } from '@vp/ubik-context'

import { stylesheet } from '../styles/VirtualSection.scss'
import { DesignServices } from '../types/DesignServices'

export const VirtualSectionTileContent = (props: {
  tileName: string;
  tileDescription: string;
  ctaText?: string;
  tileImage: {
    cloudinaryImage: ContentfulCloudinaryImageComponent;
    altTextOverride: string;
  };
  tileUrl?: string;
  pageZone: string;
  index: number;
  ctaValue: string;
  clickable: boolean;
}) => {
  useStyles(stylesheet)
  const iconNames = ['browseDesigns', 'upload', 'workWithAPro']
  const iconName = iconNames[props.index - 1]
  const virtualSectionHeader = (
    <H2 fontSize='large' className='virtual-section-tile-header'>
      {props.tileName}
    </H2>
  )
  return (
    <FlexBox
      flexDirection='row'
      justifyContent='space-between'
      className='virtual-section-tile-container'
      as='span'
    >
      <FlexBox flexDirection='column' justifyContent='space-between' as='span'>
        {props.clickable
          ? (
            <Link
              skin='unstyled'
              covering
              href={props.tileUrl}
              data-section={props.pageZone}
              data-position={props.index}
              data-translation={props.ctaValue}
            >
              {virtualSectionHeader}
            </Link>
            )
          : (
            <>{virtualSectionHeader}</>
            )}
        <Typography className='virtual-section-tile-description' as='span'>
          {props.tileDescription}
        </Typography>
        <FlexBox alignItems='center' mt='4' className='virtual-section-tile-cta' as='span'>
          <Span fontSize='small' fontWeight='bold' mr='2'>
            {props.ctaText}
          </Span>
          <Icon iconType='arrowRight' size='20p' />
        </FlexBox>
      </FlexBox>
      <FlexBox justifyContent='flex-end' as='span'>
        <Icon iconType={iconName as 'browseDesigns' | 'upload' | 'workWithAPro'} />
      </FlexBox>
    </FlexBox>
  )
}

interface VirtualSectionProps {
  data: RawVirtualSectionData;
  mpvs: ShortMsxMpv[];
  uploadFlowMpv?: MsxMpv;
  galleries?: Gallery[];
  designServices: DesignServices;
}

const VirtualSection = (props: VirtualSectionProps) => {
  const { data, mpvs, designServices, uploadFlowMpv, galleries } = props
  const { locale, vatInclusivity, merchandisingCategoryId } = useAppContext()
  const virtualSection = buildVirtualSection(data, mpvs, designServices, uploadFlowMpv, galleries)

  const virtualSectionTileArray = [
    { tile: 'browseOurDesignsTile', trackingId: 'Browse Design', requiresMpv: true },
    { tile: 'uploadYourDesignTile' },
    { tile: 'designServicesTile', trackingId: 'Design Services', requiresMpv: false },
  ]

  const buildUploadFlowTile = (tile: UploadFlowTile, index: number) => {
    return (
      <Card
        skin='link'
        as='span'
        key={`tile-${tile.tileName.value}`}
        bordered
        evenHeight
        className='virtual-section-tile'
      >
        <VirtualSectionTileContent
          tileName={tile.tileName.value}
          tileDescription={tile.tileDescription.value}
          ctaText={tile.ctaText?.value}
          tileImage={tile.tileImage}
          tileUrl={tile.url}
          pageZone='Virtual Section'
          index={index}
          ctaValue='Upload Design'
          clickable={false}
        />
      </Card>
    )
  }

  const renderTile = (props: {
    tile: VirtualSectionTile;
    categoryId: string;
    destinationUrl: string;
    ctaValue: string;
    index: number;
    pageZone: string;
  }) => {
    const { tile, categoryId, destinationUrl, ctaValue, index, pageZone } = props

    return (
      <Card
        skin='link'
        onClick={() =>
          trackClickEvent({
            categoryId,
            destinationUrl,
            ctaValue,
            index,
            pageZone,
          })}
        key={`tile-${tile.tileName.value}`}
        bordered
        evenHeight
        className='virtual-section-tile'
      >
        <VirtualSectionTileContent
          tileName={tile.tileName.value}
          tileDescription={tile.tileDescription.value}
          ctaText={tile.ctaText?.value}
          tileImage={tile.tileImage}
          tileUrl={tile.url}
          pageZone={pageZone}
          index={index}
          ctaValue={ctaValue}
          clickable
        />
      </Card>
    )
  }

  const renderUploadFlow = (uploadFlowTile: UploadFlowTile, index: number) => {
    const { tenant, requestor } = PageConfig
    const builtUploadFlow = buildUploadFlowTile(uploadFlowTile, index)
    const mpv = uploadFlowTile.mpv
    const quantity = mpv!.quantities?.default

    return (
      <UploadFlow
        vatInc={vatInclusivity}
        analyticsPageData={{
          pageSection: 'Category Page',
          pageStage: 'Discover',
          pageName: `${merchandisingCategoryId}:Category Page`,
          productName: mpv!.name,
          coreProductId: mpv!.coreProductId,
        }}
        tenant={tenant}
        locale={locale}
        pricingContextString={PricingContext.getPricingContext()}
        mpvId={mpv!.mpvId}
        loader={<div className='out-of-stock'>{builtUploadFlow}</div>}
        productKey={mpv!.coreProductId}
        productName={mpv!.name}
        productVersion={mpv!.version}
        quantity={quantity}
        requestor={requestor}
      >
        {builtUploadFlow}
      </UploadFlow>
    )
  }

  return (
    <>
      <Row>
        {virtualSectionTileArray.map(({ tile, trackingId, requiresMpv }, index) => {
          const tileData = virtualSection[tile as TileType]

          if (
            tile === 'uploadYourDesignTile' &&
            isUploadFlowTileEnabled(tileData as UploadFlowTile)
          ) {
            return (
              <Column span={4} spanXs={12} key={`virtual-section-${tile}`}>
                {renderUploadFlow(tileData as UploadFlowTile, index + 1)}
              </Column>
            )
          }

          return (
            isTileEnabled(tileData, requiresMpv) && (
              <Column span={4} spanXs={12} key={`virtual-section-${tile}`}>
                {renderTile({
                  tile: tileData,
                  categoryId: merchandisingCategoryId,
                  destinationUrl: tileData.url!,
                  ctaValue: trackingId!,
                  index: index + 1,
                  pageZone: 'Virtual Section',
                })}
              </Column>
            )
          )
        })}
      </Row>
      <DebugPageSections rawSectionData={data} resolvedSectionData={virtualSection} />
    </>
  )
}

export default VirtualSection
