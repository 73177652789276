import React from 'react'
import { ReactJsonViewProps } from 'react-json-view' // legacy
import loadable from '@loadable/component'
const ReactJson = loadable(() => import('react-json-view'))

export const DebugJsonView = (props: ReactJsonViewProps) => {
  if (typeof document === 'undefined') {
    return <></>
  }

  return <ReactJson {...props} />
}
