import CloudinaryUtil from '../../utils/cloudinaryUtil/cloudinaryUtil'
import {
  ContentfulCloudinaryVideoMedia,
  ContentfulCloudinaryImage,
} from '../../types/Contentful/ContentfulTypes'
import React from 'react'

interface AutoPlayVideoProps {
  media?: ContentfulCloudinaryImage | ContentfulCloudinaryVideoMedia;
  screenClass?: string;
}

export const AutoPlayVideo = (props: AutoPlayVideoProps) => {
  const { media } = props

  const sortedVideos = CloudinaryUtil.getVideos(media)

  return (
    <video loop playsInline muted autoPlay poster={sortedVideos[3].poster}>
      {sortedVideos.map((video) => (
        <source
          key={video.breakpoint}
          src={video.src}
          media={`(min-width: ${video.breakpoint}px)`}
          type='video/mp4'
        />
      ))}
    </video>

  )
}
