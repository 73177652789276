import { FavoriteHeartProvider } from '@vp/favorites-heart'
import { useAppContext } from '../state/AppContext'

import { useEffect, useState } from 'react'
interface FavouritesProviderProps {
  children: React.ReactNode;
}
const FavoriteHeartContainer = ({ children }: FavouritesProviderProps) => {
  const { locale, auth } = useAppContext()
  const [isAuthReady, setIsAuthReady] = useState(false)

  useEffect(() => {
    if (auth.getIdentity()?.accessToken) {
      setIsAuthReady(true)
    }
  }, [auth])

  return isAuthReady
    ? (
      <FavoriteHeartProvider
        auth={auth}
        locale={locale}
        pageSection='Category Page'
      >
        {children}
      </FavoriteHeartProvider>
      )
    : <>{children}</>
}

export default FavoriteHeartContainer
