import { TilePricing } from '../../types/ShortProductTile'
import { CalloutSkin, CalloutVariant, GoodBetterBestTile } from '../../types/GoodBetterBest'
import { ProductTileModel } from '../productTileCollection/productTileCollection'

export const resolveProductTileCallout = (
  productTile: ProductTileModel,
  outOfStockText: string
): {
  callout: string;
  skin: CalloutSkin;
  variant: CalloutVariant;
} => {
  if (productTile.temporarilyOutOfStock) {
    return {
      callout: outOfStockText,
      skin: 'error',
      variant: 'overlay',
    }
  }

  const calloutDetails = productTile.product?.template?.calloutDetails

  return {
    callout: productTile.callout || calloutDetails?.text?.value || '',
    skin: productTile.calloutColor || calloutDetails?.skin || 'standard',
    variant: productTile.calloutVariant || calloutDetails?.variant || 'overlay',
  }
}

export const isProductTileAvailable = (tile: ProductTileModel): boolean => {
  return Boolean(tile && tile.externallyMarketable && tile.associatedPageAvailable)
}

export const showProductTilePrice = (locale: string, tilePricing?: TilePricing): boolean => {
  const localeCountry = locale?.slice(-2)

  if (!tilePricing) {
    return false
  }
  return tilePricing[localeCountry]
    ? tilePricing[localeCountry].isSelected
    : tilePricing['default'].isSelected
}

export const isProductTileInStock = (
  productTile: ProductTileModel | GoodBetterBestTile
): boolean => {
  if (productTile.temporarilyOutOfStock) {
    return false
  }

  return true
}

export const showProductHeart = (
  productId: string | undefined,
  version: number | undefined,
  name: string | undefined,
  mpvId: string | undefined,
  isAuthReady: boolean
) => {
  return productId && version && name && mpvId && isAuthReady
}
