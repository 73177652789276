import React from 'react'
import {
  Divider,
  LeftHandNavigation,
  LeftHandNavigationHeader,
  LinkList,
  LinkListHeading,
  ListItem,
  Link,
  Banner,
  BoundedContent,
} from '@vp/swan'
import StandardHeroContent from '../StandardHeroContent/StandardHeroContent'
import { useAppContext } from '../../state/AppContext'
import { getLink } from '../../utils/sideBarLinks/sidebarLinks'
import PageHeaderWithoutImage from './PageHeaderWithoutImage'
import { trackClickEvent } from '../../utils/tracking/tracking'
import {
  HeroContent,
  NewBanner,
  SidebarGroup,
  SidebarLink,
  SidebarType,
} from '../../types/TemplateConfiguration'
import { renderSidebarDebugError, SidebarLinkError } from '../errorComponents/SidebarLinkError'
import classNames from 'classnames'
import CustomBannerWrapper from '../Banner'
import { MARKETING_COLORS } from '@vp/marketing-colors'
import { BFFPageData } from '../../services/serviceClients/BffClient'
import { DebugPageSections } from '../debugComponents/DebugPageSections'

import { primaryContentExperimentVariationControl, primaryContentExperimentVariationTest } from '../../constants/primaryContentTestData'
import PrimaryContentTestHeader from './PrimaryContentTestHeader'

interface PageHeaderProps {
  headerType: 'page header' | 'sub-category page header' | 'section header';
  configuration: HeroContent | NewBanner;
  pageDataFromBFF: BFFPageData;
  sidebar?: SidebarType;
  isCarousel?: boolean;
  index?: number;
  marginBottom?: Record<string, number> | number;
}

const PageHeader = (props: PageHeaderProps) => {
  const { headerType, configuration, sidebar, pageDataFromBFF, isCarousel, index, marginBottom } =
    props
  const { pageUrls, pageAvailabilities, mpvs } = pageDataFromBFF
  const { sidebarEnabled, merchandisingCategoryId, referencedFields, isPagePartOfPrimarayContentExperiment, primaryContentExperimentVariation } = useAppContext()
  const { backgroundColor } = useAppContext()
  const newBanner = (configuration as NewBanner).banner

  const configurationAsHeroContent = configuration as HeroContent
  const fullWidthImage = !!configurationAsHeroContent.fullWidthImage
  const imageFocalPoint = configurationAsHeroContent?.imageFocalPoint
  const isH1 = shouldBeH1(headerType, isCarousel, index)
  const finalizedBackgroundColor = getBackgroundColor({
    defaultColor: backgroundColor,
    heroType: headerType,
    overrideColor: configurationAsHeroContent.backgroundColor?.name,
  })
  if (!newBanner && !configurationAsHeroContent?.image && !sidebarEnabled) {
    if (configurationAsHeroContent?.title) {
      return (
        <>
          <PageHeaderWithoutImage type={headerType} {...configurationAsHeroContent} />
          <DebugPageSections rawSectionData={configuration} resolvedSectionData={configurationAsHeroContent} />
        </>
      )
    } else {
      return <></>
    }
  }

  const shouldRenderPrimaryContentTestHeader = isPagePartOfPrimarayContentExperiment &&
        primaryContentExperimentVariation !== primaryContentExperimentVariationControl &&
        headerType === 'page header'

  const color = MARKETING_COLORS[finalizedBackgroundColor]
  const leftNav =
    sidebarEnabled && sidebar
      ? (
        <LeftHandNavigation>
          {
            // hide the sidebar header in variation 'test'
            isPagePartOfPrimarayContentExperiment &&
              primaryContentExperimentVariation === primaryContentExperimentVariationTest
              ? null
              : <LeftHandNavigationHeader>{sidebar?.categoryName?.value}</LeftHandNavigationHeader>
          }
          {sidebar?.groups.map(
            (group: SidebarGroup, groupIndex: number) =>
              group.groupName && (
                <div key={group.groupName.value}>
                  {
                    // hide the divider in variation 'test' while rendering the first group of links
                    isPagePartOfPrimarayContentExperiment &&
                    primaryContentExperimentVariation === primaryContentExperimentVariationTest &&
                    groupIndex === 0
                      ? null
                      : <Divider />
                  }
                  <LinkListHeading>{group.groupName.value}</LinkListHeading>
                  <LinkList>
                    {group.links.map((link: SidebarLink, linkIndex: number) => {
                      const { url, text, externallyMarketable, associatedPageAvailable } = getLink(
                        link,
                        mpvs,
                        pageUrls,
                        pageAvailabilities,
                        referencedFields
                      )

                      if (renderSidebarDebugError(externallyMarketable, associatedPageAvailable)) {
                        return <SidebarLinkError url={url} text={text} key={linkIndex} />
                      }

                      return (
                        url &&
                      text &&
                      externallyMarketable &&
                      associatedPageAvailable && (
                        <ListItem key={url}>
                          <Link
                            href={url}
                            onClick={() =>
                              trackClickEvent({
                                categoryId: merchandisingCategoryId,
                                destinationUrl: url,
                                ctaValue: `Group ${groupIndex + 1} Link ${linkIndex + 1}`,
                                pageZone: 'Side Bar',
                                linkType: 'Text Layout Link',
                              })}
                            data-section='Side Bar'
                            data-translation={
                              link.mpvId
                                ? link.mpvId
                                : `Group ${groupIndex + 1} Link ${linkIndex + 1}`
                            }
                          >
                            {text}
                          </Link>
                        </ListItem>
                        )
                      )
                    })}
                  </LinkList>
                </div>
              )
          )}
        </LeftHandNavigation>
        )
      : undefined

  if (newBanner) {
    if (newBanner.bannerTemplate) {
      if (shouldRenderPrimaryContentTestHeader) {
        return (
          <PrimaryContentTestHeader
            newBannerData={newBanner.bannerTemplate?.[0]}
            sidebar={sidebar}
            leftNav={leftNav}
          />
        )
      }

      return (
        <>
          <CustomBannerWrapper
            bannerProps={newBanner.bannerTemplate}
            headerType={headerType}
            sidebar={sidebar}
            sidebarEnabled={sidebarEnabled}
            leftNav={leftNav}
            useH1ForTitle
          />
          <DebugPageSections rawSectionData={configuration} resolvedSectionData={newBanner.bannerTemplate} />
        </>
      )
    } else {
      return <></>
    }
  }

  if (shouldRenderPrimaryContentTestHeader) {
    return (
      <PrimaryContentTestHeader
        oldBannerData={configurationAsHeroContent}
        sidebar={sidebar}
        leftNav={leftNav}
        oldBannerBackgroundColor={color?.code}
      />
    )
  }

  const backgroungVariant = sidebarEnabled ? 'card' : fullWidthImage ? 'full-width-image' : 'card'

  return (
    <BoundedContent paddingX={0} marginBottom={{ xs: 7, sm: 7, md: 8 }}>
      <Banner
        imageFocalPoint={imageFocalPoint ?? 'right bottom'}
        withLeftHandNavigation={!!sidebarEnabled}
        textBoxHorizontalAlign='left'
        style={{ '--swan-public-marketing-background': color?.code } as React.CSSProperties}
        darkMode={color?.mode.dark}
        mb={marginBottom}
        variant={backgroungVariant}
        className={classNames({
          'full-height-carousel-content': isCarousel,
        })}
      >
        {sidebarEnabled && sidebar && leftNav}
        <StandardHeroContent type={headerType} {...configurationAsHeroContent} isH1={isH1} />
      </Banner>
      <DebugPageSections rawSectionData={configuration} resolvedSectionData={configurationAsHeroContent} />
    </BoundedContent>
  )
}

export const shouldBeH1 = (headerType: string, isCarousel?: boolean, index?: number) => {
  if (headerType === 'page header' || headerType === 'sub-category page header') {
    if (!isCarousel) {
      return true
    }

    if (isCarousel && index === 0) {
      return true
    }
  }
  return false
}

export const getBackgroundColor = (props: {
  defaultColor: string;
  overrideColor?: string;
  heroType: string;
}) => {
  const { overrideColor, defaultColor, heroType } = props

  if (overrideColor && heroType !== 'page header') {
    return overrideColor
  }

  return defaultColor
}

export default PageHeader
