import { AccessoriesQuickView, QuickViewATCConfig } from '@vp/gatsby-quick-view-atc'
import React from 'react'
import { useAppContext } from '../state/AppContext'
import PageConfig from '../utils/pageConfig'
import { stylesheet } from '@vp/gatsby-quick-view-atc/style.css'
import { useStyles } from '@vp/ubik-context'
export interface AccessoriesATCProps {
  category: string;
}

export const ChecksAccessoriesATC = (props: AccessoriesATCProps) : React.JSX.Element => {
  const { vistacartTenant, locale, merchandisingCategoryId, auth, vatInclusivity } = useAppContext()
  useStyles(stylesheet)
  const { requestor, tenant, developmentMode } = PageConfig

  if (auth) {
    const config: QuickViewATCConfig = {
      locale,
      vistacartTenant,
      auth: {
        token: auth.getAuthorizationHeader?.() ?? auth.getToken?.(),
        canonicalId: auth.getCanonicalId(),
      },
      vatInclusivity,
      requestor,
      tenant,
      developmentMode,
      merchandisingCategoryMpvId: merchandisingCategoryId,
      trackingData: {
        pageName: 'checks:Category Page',
        pageSection: 'Category Page',
        pageStage: 'Discover',
      },
    }

    return <AccessoriesQuickView {...config} />
  } else {
    return <></>
  }
}
