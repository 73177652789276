import { BoundedContent, Carousel, CarouselSlide } from '@vp/swan'
import { PageHeaderType } from '../../types/TemplateConfiguration'
import PageHeader from './PageHeader'
import { getAccessibleTextForDots } from '../../utils/accessibility/accessibility'
import { trackCarouselClick } from '../../utils/tracking/tracking'
import { getPageZone } from '../StandardHeroContent/StandardHeroContent'
import { useAppContext } from '../../state/AppContext'
import { BFFPageData } from '../../services/serviceClients/BffClient'

interface PageHeaderCarouselProps {
  configuration: PageHeaderType;
  pageDataFromBFF: BFFPageData;
  marginBottom?: Record<string, number> | number;
}

const PageHeaderCarousel = (props: PageHeaderCarouselProps) => {
  const { configuration, pageDataFromBFF, marginBottom } = props
  const { merchandisingCategoryId, dictionaryEntries } = useAppContext()
  const headerType = configuration.type
  const slides = configuration.headers

  const { accessibleTextForNext, accessibleTextForPrevious } = dictionaryEntries
  const accessibleTextForDots = getAccessibleTextForDots(
    dictionaryEntries.accessibleTextForDots,
    slides.length
  )

  return (
    <BoundedContent paddingX={0} marginBottom={{ xs: 7, sm: 7, md: 8 }}>
      <Carousel
        swipeToSlide
        slidesToShow={1}
        slidesToScroll={1}
        skin='full'
        infinite={false}
        progressIndicator='dots-inset'
        mb={marginBottom}
        accessibleTextForPrevious={accessibleTextForPrevious}
        accessibleTextForDots={accessibleTextForDots}
        accessibleTextForNext={accessibleTextForNext}
        afterChange={(currentSlide: number) => {
          trackCarouselClick({
            categoryId: merchandisingCategoryId,
            pageZone: getPageZone(headerType),
            slideIndex: currentSlide + 1,
          })
        }}
      >
        {slides.map((slide, index) => {
          return (
            <CarouselSlide key={`carousel-slide-${index}`}>
              <PageHeader
                headerType={headerType}
                configuration={slide}
                pageDataFromBFF={pageDataFromBFF}
                isCarousel
                index={index}
              />
            </CarouselSlide>
          )
        })}
      </Carousel>
    </BoundedContent>
  )
}

export default PageHeaderCarousel
