import { ShortMerchandisingCategory } from '../../types/ShortMerchandisingCategory'
import { Translated } from '../../types/Global'
import { Document } from '@contentful/rich-text-types'
import { ShortProductTile } from '../../types/ShortProductTile'
import {
  ProductGridItemType,
  ProductGridType,
  ShortProductTileV2,
} from '../../types/TemplateConfiguration'
import PageDataUtil from '../pageDataUtil/pageDataUtil'
import { BFFPageData } from '../../services/serviceClients/BffClient'
import { ContentfulEntryLink, ContentfulProductTile } from '../../types/Contentful/ContentfulTypes'
import { CalloutSkin, CalloutVariant } from '../../types/GoodBetterBest'
import { MSXAttibuteValue } from '../../types/Msx'
import { showProductTilePrice } from '../ProductTile/productTile'
import { appendQueryParams } from '../urls/urls'
import { ShortMsxMpv } from '../../types/ShortMsxMpv'

export interface ProductTileCollectionModel {
  title?: Translated;
  subtitle?: Translated;
  tiles: ProductTileModel[];
}

export interface ProductTileModel {
  name?: string;
  description?: string;
  image?: {
    altTextOverride?: string;
    url?: string;
  };
  callout?: string;
  calloutColor?: CalloutSkin;
  calloutVariant?: CalloutVariant;
  productTileLongDescription?: Document;
  linkUrl?: string;
  temporarilyOutOfStock?: boolean;
  externallyMarketable?: boolean;
  mpvId?: string;
  productId?: string;
  showPrice?: boolean;
  associatedPageAvailable?: boolean;
  colorInformation?: MSXAttibuteValue[];
  product?: { contentful_id: string; template?: ProductTileV2 };
  version?: number;
}

export interface ProductTileV2 {
  calloutDetails?: {
    skin?: CalloutSkin;
    variant?: CalloutVariant;
    text?: {
      value?: string;
    };
  };
  productKey?: string;
  productsList?: {
    selectedProduct?: {
      mpvId?: string;
    };
  };
  category?: {
    selectedCategory?: {
      mpvId: string;
      name: {
        value: string;
      };
      link?: {
        href?: string;
      };
    };
  };
  secondaryLink: {
    link?: { href?: string; text?: string };
    label?: {
      value?: string;
    };
  };
  tileDetails?: ProductTileV2TileDetails;
}

interface ProductTileV2TileDetails {
  image?: {
    cloudinaryImage?: {
      src?: string;
    }[];
  };
  description?: { value?: string };
  header?: {
    value?: string;
  };
  urlText?: { value?: string };
  link?: {
    href?: string;
    text?: string;
    linkType?: string;
  };
}

export default class ProductTileCollectionUtil {
  public static getModel (
    section: ProductGridType,
    pageDataFromBFF: BFFPageData,
    pageReferencedFields: Record<string, ContentfulEntryLink>,
    locale: string
  ): ProductTileCollectionModel {
    const title = section.title
    const subtitle = section.subtitle

    const tiles = section.items.map((item) => {
      // for ProductTileV2
      if ((item.product as ShortProductTileV2)?.template) {
        const mpvId = (item.product as ShortProductTileV2)?.template?.productsList?.selectedProduct
          ?.mpvId
        if (mpvId) {
          const serverSideMpvData = PageDataUtil.getMpv(mpvId, pageDataFromBFF)
          const externallyMarketable = serverSideMpvData?.externallyMarketable ?? true
          const mpvTile = this.getTileV2FromMpv(mpvId, serverSideMpvData)
          let associatedPageAvailable = pageDataFromBFF.pageAvailabilities.find(
            (pageAvailability) => pageAvailability.mpvId === mpvId
          )?.availability
          if ((item.product as ShortProductTileV2)?.template.tileDetails?.link?.linkType) {
            // When link url is explicitly set in authoring, do not check associated page availability
            associatedPageAvailable = true
          }
          return {
            externallyMarketable,
            ...mpvTile,
            ...item,
            mpvId,
            associatedPageAvailable,
          }
        } else {
          return {
            ...item,
            externallyMarketable: true,
            associatedPageAvailable: true,
          }
        }
      }

      if (item.product) {
        const productTileTile = this.getTileFromProductTile(item, pageReferencedFields, locale)
        const mpvId = this.getMpvId(item.product)
        if (mpvId) {
          const mpvTile = this.getTileFromMpv(mpvId, pageDataFromBFF)
          const merchCategoryTile = this.getTileFromMerchCategory(
            item.product as ShortMerchandisingCategory
          )
          let linkUrl = pageDataFromBFF.pageUrls.find(
            (url) => url.mpvId === mpvId
          )?.url
          let associatedPageAvailable = pageDataFromBFF.pageAvailabilities.find(
            (pageAvailability) => pageAvailability.mpvId === mpvId
          )?.availability
          if ((item.product as any)?.__typename === 'productTile' && productTileTile.linkUrl) {
            linkUrl = productTileTile.linkUrl
            // When link url is explicitly set in authoring, do not check associated page availability
            associatedPageAvailable = true
          }
          return {
            ...mpvTile,
            ...productTileTile,
            ...merchCategoryTile,
            linkUrl,
            mpvId,
            associatedPageAvailable,
          }
        } else {
          return {
            ...productTileTile,
            externallyMarketable: true,
            associatedPageAvailable: true,
          }
        }
      }

      return [] // map has to return a value..need to check if retuning null is an option
    })

    return {
      title,
      subtitle,
      tiles,
    }
  }

  public static getTileV2FromMpv (
    mpvId: string,
    serverSideMpvData: ShortMsxMpv | undefined
  ) {
    const tile: ProductTileModel = {
      mpvId,
      showPrice: true,
      productId: serverSideMpvData?.coreProductId,
      temporarilyOutOfStock: serverSideMpvData?.pastaOutOfStock ?? false,
      externallyMarketable: serverSideMpvData?.externallyMarketable ?? true,
      colorInformation: serverSideMpvData?.colorInformation,
      version: serverSideMpvData?.version
    }

    return this.removeUndefinedFields(tile)
  }

  public static getTileFromMpv (
    mpvId: string,
    pageDataFromBFF: BFFPageData
  ) {
    const mpv = PageDataUtil.getMpv(mpvId, pageDataFromBFF)
    const imageUrl = mpv?.defaultProductImage.cloudinaryImage
    const name = mpv?.name
    const description = mpv?.defaultProductDescription
    const externallyMarketable = mpv?.externallyMarketable ?? true
    const version = mpv?.version
    const tile: ProductTileModel = {
      name,
      description,
      externallyMarketable,
      mpvId,
      version,
      showPrice: true,
      productId: mpv?.coreProductId,
      temporarilyOutOfStock: mpv?.pastaOutOfStock ?? false,
      colorInformation: mpv?.colorInformation,
    }

    if (imageUrl) {
      tile.image = {
        altTextOverride: '',
        url: imageUrl,
      }
    }
    return this.removeUndefinedFields(tile)
  }

  public static getMaxColorSwatchCountDesktop (tilesCount: number, sideBarEnabled: boolean) {
    return tilesCount > 4 || sideBarEnabled ? 6 : 8
  }

  public static getNumberOfColorsToDisplay (screenClass: string, colorSwatchCount: number) {
    return screenClass === 'xs' ? 4 : colorSwatchCount
  }

  public static getTileFromProductTile (
    item: ProductGridItemType,
    pageReferencedFields: Record<string, ContentfulEntryLink>,
    locale: string
  ): ProductTileModel {
    let tile: ProductTileModel = {}
    const productTile = item.product as ShortProductTile
    const tileReferencedField =
      productTile && (pageReferencedFields[productTile.contentful_id] as ContentfulProductTile)
    if (productTile?.__typename === 'productTile') {
      const primaryUrl = tileReferencedField?.primaryUrl
      const primaryUrlConsumerParameters = tileReferencedField?.primaryUrlConsumerParameters
      const imageUrl = productTile?.productImage?.cloudinaryImage?.cloudinaryUrl
      const imageAltText = productTile?.productImage?.altTextOverride
      const name = productTile?.productTileHeader
      const description = productTile?.productTileSubHeader
      const linkUrl = primaryUrl
        ? primaryUrlConsumerParameters
          ? appendQueryParams(primaryUrl, primaryUrlConsumerParameters)
          : primaryUrl
        : ''
      const mpvId = productTile?.merchandisingElement?.mpvId
      const callout = productTile.calloutText
      const calloutColor = productTile.calloutColor
      const calloutVariant = productTile.calloutVariant
      // check this | showPrice was coming from a resolver in CPV3 from tile pricing contentful field
      // MCS is not building data correctly for MCS
      const tilePricing = productTile.tilePricing
      const showPrice = showProductTilePrice(locale, tilePricing)
      const productTileLongDescription = productTile?.productTileLongDescription
      tile = {
        name,
        description,
        productTileLongDescription,
        linkUrl,
        mpvId,
        callout,
        calloutColor,
        calloutVariant,
        showPrice,
      }
      if (imageUrl) {
        tile.image = {
          altTextOverride: imageAltText,
          url: imageUrl,
        }
      }
    }
    return this.removeUndefinedFields(tile)
  }

  public static getTileFromMerchCategory (
    merchandisingCategory: ShortMerchandisingCategory
  ): ProductTileModel {
    let tile: ProductTileModel = {}
    if (merchandisingCategory?.__typename === 'merchandisingCategory') {
      const imageUrl = merchandisingCategory?.defaultImage?.cloudinaryImage?.cloudinaryUrl
      const imageAltText = merchandisingCategory?.defaultImage?.altTextOverride
      const name = merchandisingCategory?.name?.value
      const description = merchandisingCategory?.defaultDescription
      const mpvId = merchandisingCategory?.mpvId
      tile = {
        name,
        description,
        mpvId,
        showPrice: true,
        externallyMarketable: true,
      }
      if (imageUrl) {
        tile.image = {
          altTextOverride: imageAltText,
          url: imageUrl,
        }
      }
    }
    return this.removeUndefinedFields(tile)
  }

  private static getMpvId (product: any): string | undefined {
    return product.mpvId || product.merchandisingElement?.mpvId
  }

  private static removeUndefinedFields (obj: any) {
    Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key])
    return obj
  }

  public static getNumberOfProductTilesPerRow (
    sidebarEnabled: boolean,
    sectionType: string,
    tilesCount: number
  ) {
    switch (sectionType) {
      case 'product grid horizontal':
        return 3
      case 'product grid horizontal XL':
        return 2
      default:
        return tilesCount <= 4 ? 4 : sidebarEnabled ? 4 : 6
    }
  }
}
