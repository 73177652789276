import { PreviewModes, PurcsPreview } from '@vp/react-product-preview'
import { FluidImage, ResponsiveImage, StandardTileImage, useScreenClass } from '@vp/swan'
import PageConfig from '../../utils/pageConfig'
import ProductPreviewModal from './ProductPreviewModal'
import { useState } from 'react'

interface ProductPreviewProps {
  filteredOrder: any;
  productPreviewMessages: Record<string, string>;
}

const ProductPreview = (props: ProductPreviewProps) => {
  const [isViewLargerModalOpen, setIsViewLargerModalOpen] = useState(false)
  const isMobile = useScreenClass() === 'xs'
  const { filteredOrder, productPreviewMessages } = props
  const { productData, designData, selections } = filteredOrder
  const { requestor } = PageConfig
  const shouldShowPurcsPreview =
    productData?.productKey &&
    productData?.productAttributes &&
    designData?.docRefUrl

  const openPreviewModal = (isOpen: boolean) => {
    setIsViewLargerModalOpen(isOpen)
  }
  const transformUrl = (url: string): string => {
    const transformedUrlWithHeight = url.replace(/h_\d+/, 'h_500')
    const transformedUrl = transformedUrlWithHeight.replace(/w_\d+/, 'w_500')
    return transformedUrl
  }
  return (
    <StandardTileImage
      mt={1}
      style={{
        flex: shouldShowPurcsPreview ? (isMobile ? '50%' : '15%') : 'unset',
        width: shouldShowPurcsPreview && !isMobile ? 150 : 'unset',
      }}
      mr={shouldShowPurcsPreview ? 4 : 0}
    >
      {shouldShowPurcsPreview
        ? (
          <>
            <PurcsPreview
              requestor={requestor}
              coreProductKey={productData.productKey}
              productVersion={productData.productVersion}
              docRefURL={designData.docRefUrl}
              optionSelections={selections}
              onClickMethod={() => openPreviewModal(true)}
              previewMode={
              isMobile ? PreviewModes.FirstPageOnly : PreviewModes.Carousel
            }
              purcsOrigin='https://lookup.previews.cimpress.io'
              accessibleTextForNext={productPreviewMessages.next}
              accessibleTextForPrevious={productPreviewMessages.previous}
              width={1000}
              previewInstructionsUri={undefined}
            />
            <ProductPreviewModal
              productKey={productData.productKey}
              closeText={productPreviewMessages.close}
              openPreviewModal={openPreviewModal}
              isOpenPreviewModal={isViewLargerModalOpen}
            >
              <PurcsPreview
                requestor={requestor}
                coreProductKey={productData.productKey}
                productVersion={productData.productVersion}
                docRefURL={designData.docRefUrl}
                optionSelections={selections}
                onClickMethod={() => openPreviewModal(true)}
                previewMode={PreviewModes.Carousel}
                purcsOrigin='https://lookup.previews.cimpress.io'
                accessibleTextForNext={productPreviewMessages.next}
                accessibleTextForPrevious={productPreviewMessages.previous}
                width={1000}
                previewInstructionsUri={undefined}
              />
            </ProductPreviewModal>
          </>
          )
        : (
          <>
            <ResponsiveImage
              style={{
                objectFit: 'contain',
                objectPosition: 'top',
                borderRadius: 'unset',
              }}
              height={isMobile ? 86 : 166}
              width={isMobile ? 86 : 166}
              src={designData.livePreviewUrl}
              alt={designData.altTextOverride || ''}
            />
            <ProductPreviewModal
              productKey={productData.productKey}
              closeText={productPreviewMessages.close}
              openPreviewModal={openPreviewModal}
              isOpenPreviewModal={isViewLargerModalOpen}
            >
              <FluidImage
                src={transformUrl(designData.livePreviewUrl)}
                alt={designData.altTextOverride || ''}
              />
            </ProductPreviewModal>
          </>
          )}
    </StandardTileImage>
  )
}

export default ProductPreview
