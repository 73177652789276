import { actionFilters } from '../../types/Contentful/ContentfulTypes'

const getGalleryData = (actionId: string, actionFilters: actionFilters) => {
  let categories: string[] = []
  let productKey = ''
  if (actionId) {
    categories = actionFilters?.categories || []
    productKey = actionId
    return { categories, productKey }
  }
}

export { getGalleryData }
