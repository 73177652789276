import React, { useEffect, useState } from 'react'
import PageConfig from '../../utils/pageConfig'
import { trackMediaClicked, trackMediaViewed } from '../../utils/tracking/tracking'
import { useAppContext } from '../../state/AppContext'
import { tokens } from '@vp/swan'
import { ContentfulCloudinaryVideoMedia } from '../../types/Contentful/ContentfulTypes'

interface PlayPauseVideoProps {
  publicId: string;
  playerId: string;
  posterImageFrame?: number;
  sectionName: string;
  media: ContentfulCloudinaryVideoMedia;
}

const PlayPauseVideo = (props: PlayPauseVideoProps) => {
  const { publicId, playerId, posterImageFrame, sectionName, media } = props
  const { merchandisingCategoryId } = useAppContext()
  const [cloudinaryInstance, setCloudinaryInstance] = useState<any | null>(null)

  useEffect(() => {
    const cloudinaryListener = window.setInterval(function () {
      if ((window as any).cloudinary && (window as any).cloudinary.Cloudinary) {
        const cloudName: string = PageConfig.tenant.toLowerCase()
        const cld = (window as any).cloudinary.Cloudinary.new({
          cloud_name: cloudName,
          secure: true,
        })

        setCloudinaryInstance(cld)
        window.clearInterval(cloudinaryListener)
      }
    }, 100)

    return function () {
      window.clearInterval(cloudinaryListener)
    }
  }, [])

  useEffect(() => {
    const videoListener = window.setInterval(function () {
      if (cloudinaryInstance && cloudinaryInstance?.videoPlayer) {
        const videoConfigs: any = {
          fluid: true,
          colors: {
            accent: tokens.SwanBaseColorBlue700, // $accent-blue
          },
          autoplay: false,
          controls: true,
          logoOnclickUrl: `https://${document.location.hostname}/`,
          logoImageUrl:
            'https://cms.cloudinary.vpsvc.com/image/upload/dpr_auto,q_auto,h_25/v1560966964/logomark.png',
          playedEventPercents: [25, 50, 75, 100],
        }
        if (posterImageFrame) {
          videoConfigs.transformation = {
            startOffset: posterImageFrame,
          }
        }

        const vplayer = cloudinaryInstance.videoPlayer(playerId, videoConfigs)
        vplayer.on('percentsplayed', (event: any) => {
          trackMediaViewed({
            categoryId: merchandisingCategoryId as string,
            videoId: media?.video.publicId,
            videoUrl: media?.video.videoUrl,
            mobileVideoUrl: media?.video.mobileVideoUrl,
            name: sectionName,
            progress: event.eventData.percent,
          })
        })

        const trackVideoClicked = (action: string) => {
          trackMediaClicked({
            categoryId: merchandisingCategoryId as string,
            videoId: media.video.publicId,
            videoUrl: media.video.videoUrl,
            mobileVideoUrl: media.video.mobileVideoUrl,
            name: sectionName,
            action,
          })
        }
        vplayer.on('play', (_event: any) => {
          trackVideoClicked('play')
        })
        vplayer.on('pause', (_event: any) => {
          trackVideoClicked('pause')
        })
        window.clearInterval(videoListener)
      }
    }, 100)

    return function () {
      window.clearInterval(videoListener)
    }
  }, [cloudinaryInstance])

  return (
    <>
      <video id={playerId} data-cld-public-id={publicId} controls className='cld-video-player' />
    </>
  )
}

export default PlayPauseVideo
