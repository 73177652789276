import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { AnchorBar, AnchorBarList, AnchorBarListItem, tokensRaw } from '@vp/swan'
import { useStyles } from '@vp/ubik-context'
import { MARKETING_COLORS } from '@vp/marketing-colors'

import { useAppContext } from '../state/AppContext'
import { pageCategoryPageV3 } from '../types/Contentful/ContentfulTypes'
import {
  CategoryPageSection,
  AnchorLinksType,
  AnchorLinkItemsType,
} from '../types/TemplateConfiguration'
import { getAnchorLinksPropsBasedOnSectionAvailability } from '../utils/anchorLinkUtils/anchorLinksUtil'
import { determineSectionAvailability } from '../utils/pageSectionUtil/pageSectionUtil'
import { getPageHeaderConfig } from './header/PageHeaderContainer'
import { stylesheet } from '../styles/AnchorBar.scss'
import { trackAnchorBarClickEvent } from '../utils/tracking/tracking'
import { getHash } from '../utils/location/location'

interface AnchorBarWrapperProps {
  pageCategoryPageV3: pageCategoryPageV3;
  sections: CategoryPageSection[];
}

const AnchorBarWrapper: React.FC<AnchorBarWrapperProps> = (props: AnchorBarWrapperProps) => {
  useStyles(stylesheet)
  const [activeAnchor, setActiveAnchor] = React.useState()
  const { locale, sidebarEnabled, merchandisingCategoryId } = useAppContext()
  const anchorListNode = useRef<HTMLDivElement>(null)
  const { sections } = props

  const anchorLinkSection = getAnchorLinksPropsBasedOnSectionAvailability(
    sections,
    locale
  ) as AnchorLinksType

  const anchorLinkItems = anchorLinkSection?.items

  if (!anchorLinkItems) {
    return <></>
  }

  const pageHeaderConfig = getPageHeaderConfig(props.pageCategoryPageV3.templateConfiguration)

  const withPageHeaderMarginOverride = (() => {
    if (
      determineSectionAvailability(pageHeaderConfig, locale) &&
      // @ts-ignore
      !(pageHeaderConfig as HeroType).image &&
      !sidebarEnabled
    ) {
      return false
    }
    return true
  })()

  useEffect(() => {
    const element = anchorListNode.current

    const trackAnchorLinkClick = (event: MouseEvent) => {
      // @ts-ignore
      const { innerText } = event.target
      trackAnchorBarClickEvent({
        categoryId: merchandisingCategoryId as string,
        label: innerText,
      })
    }
    element?.addEventListener('click', trackAnchorLinkClick)

    return () => {
      element?.removeEventListener('click', trackAnchorLinkClick)
    }
  }, [])

  useLayoutEffect(() => {
    const sectionId = getHash()
    const sectionById = document.getElementById(sectionId)

    let timeout: NodeJS.Timeout

    const onLoad = (event: Event) => {
      event.preventDefault()
      if (sectionById) {
        timeout = setTimeout(() => {
          const { top } = sectionById.getBoundingClientRect()
          const anchorBarHeight =
            document.querySelector('.swan-anchor-bar-list')?.clientHeight || 0

          const SCROLL_MARGIN = parseInt(tokensRaw.SwanBaseSpace200) || 0
          const scrollValue = window.scrollY + top - anchorBarHeight - SCROLL_MARGIN
          window.scrollTo({ top: scrollValue, behavior: 'smooth' })
        }, 1500)
      }
    }

    document.addEventListener('load', onLoad, { once: true, capture: true })

    return () => {
      clearTimeout(timeout)
      document.removeEventListener('load', onLoad)
    }
  }, [])

  const backgroundColor = MARKETING_COLORS[anchorLinkSection.backgroundColor.name]
  return (
    <>
      <AnchorBar
        className={`anchor-bar-spacing ${
          withPageHeaderMarginOverride ? 'anchor-bar-negative-margin' : ''
        }`}
        sticky
        style={{ backgroundColor: backgroundColor?.code }}
        onActiveAnchorChanged={setActiveAnchor}
        darkMode={backgroundColor?.mode.dark}
      >
        <AnchorBarList ref={anchorListNode}>
          {anchorLinkItems.map((item: AnchorLinkItemsType) => {
            const sectionId = item.categorySectionSectionId
            return (
              <AnchorBarListItem key={sectionId}>
                <a
                  href={`#${sectionId}`}
                  aria-current={activeAnchor === sectionId ? 'true' : undefined}
                >
                  {item.title?.value}
                </a>
              </AnchorBarListItem>
            )
          })}
        </AnchorBarList>
      </AnchorBar>
    </>
  )
}

export default AnchorBarWrapper
