import { BoundedContent, FlexBox } from '@vp/swan'
import { DebugPageInfo } from './DebugPageInfo'
import { useAppContext } from '../../state/AppContext'

const DebugInfo = () => {
  const { debugMode } = useAppContext()

  if (!debugMode) {
    return null
  }

  return (
    <BoundedContent px='5'>
      <FlexBox flexDirection='column'>
        <DebugPageInfo />
      </FlexBox>
    </BoundedContent>
  )
}

export default DebugInfo
