import TokenEngine from '@vp/vp-js-token-engine'

export const getAccessibleTextForDots = (accessibleTextForDots: string, totalSlides: number) => {
  const tokenEngine = new TokenEngine()
  const accessibleTextForDotsArray = Array.apply(null, Array(totalSlides - 1)).map(function (x, i) {
    return tokenEngine.replace(accessibleTextForDots, {
      slideNumber: i + 1,
    })
  })
  return accessibleTextForDotsArray
}
