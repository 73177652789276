export const DEFAULT_CONTENTFUL_ENVIRONMENT_NAME = 'master'
export const DEFAULT_TENANT = 'vistaprint'
export const CONTENTFUL_PAGE_TYPE = 'pageCategoryPageV3'
export const SERVICE_NAME = 'category-page'
export const DEFAULT_URL_ID = 'businessCards'
export const DEVELOPMENT_ENV = 'development'
export const PRODUCTION_ENV = 'production'
export const BFF_CACHE_TTL = 600
export const BFF_CACHE_CHECK_PERIOD = 900
export const CHECK_ACCESSORIES_MPVID = 'checks'
