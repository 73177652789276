import {
  BoundedContent,
  Row,
  Column,
  GridContainer,
  Box,
  Responsive,
  tokens,
  StyleSpaceWithAuto,
  BannerWithLeftHandNavigationBelowBanner,
} from '@vp/swan'
import React from 'react'
import { CategoryPageSectionType } from '../types/TemplateConfiguration'
import { useAppContext } from '../state/AppContext'
import { MARKETING_COLORS } from '@vp/marketing-colors'
import { primaryContentExperimentVariationTest } from '../constants/primaryContentTestData'

const PageSectionWrapper = (props: {
  type?: CategoryPageSectionType;
  marginBottom?: Responsive<StyleSpaceWithAuto> | undefined;
  children: any;
}) => {
  const { type, children, marginBottom } = props
  const { sidebarEnabled, backgroundColor, isPagePartOfPrimarayContentExperiment, primaryContentExperimentVariation } = useAppContext()
  const useBackgroundColor = type === 'template lane' || type === 'faq'
  const faqBackgroundColor = 'warmWhite'

  if (type === 'anchor links') {
    return <>{children}</>
  }

  if (type === 'promo banner' || type === 'section header' || type === 'side by side hero') {
    if (isPagePartOfPrimarayContentExperiment && primaryContentExperimentVariation === primaryContentExperimentVariationTest) {
      return <BannerWithLeftHandNavigationBelowBanner mb={marginBottom}>{children}</BannerWithLeftHandNavigationBelowBanner>
    }
    return <Box mb={marginBottom}>{children}</Box>
  }

  const pageContent = (
    <Box
      marginBottom={marginBottom}
      style={{
        backgroundColor:
          type === 'template lane'
            ? MARKETING_COLORS[backgroundColor]?.code
            : type === 'faq'
              ? MARKETING_COLORS[faqBackgroundColor]?.code
              : undefined,
        paddingTop: type === 'faq' ? tokens.SwanBaseSpace700 : tokens.SwanBaseSpace0,
        paddingBottom: type === 'faq' ? tokens.SwanBaseSpace700 : tokens.SwanBaseSpace0,
      }}
    >
      <GridContainer>
        <Row>
          <Column
            span={sidebarEnabled ? 9 : 12}
            offset={sidebarEnabled ? 3 : 0}
            offsetSm={0}
            spanSm={12}
          >
            {children}
          </Column>
        </Row>
      </GridContainer>
    </Box>
  )

  return useBackgroundColor
    ? (
      <BoundedContent paddingX={0}>{pageContent}</BoundedContent>
      )
    : (
      <BoundedContent>{pageContent}</BoundedContent>
      )
}

export default PageSectionWrapper
