import { Link, ListItem } from '@vp/swan'
import React from 'react'
import { useAppContext } from '../../state/AppContext'

interface SidebarLinkErrorProps {
  url: string;
  text: string;
}

export const SidebarLinkError = ({ url, text }: SidebarLinkErrorProps) => {
  return (
    <ListItem style={{ backgroundColor: 'pink' }}>
      <Link href={url}>{`${text} - Unavailable (Won't show in prod)`}</Link>
    </ListItem>
  )
}

export const renderSidebarDebugError = (
  externallyMarketable: boolean,
  associatedPageAvailable: boolean
) => {
  const { debugMode } = useAppContext()
  return (!externallyMarketable || !associatedPageAvailable) && debugMode
}
