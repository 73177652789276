import { PAGEHEADER_TYPES } from '../../components/header/PageHeaderContainer'
import {
  AnchorLinksType,
  CategoryPageSection,
} from '../../types/TemplateConfiguration'
import PageDataUtil from '../pageDataUtil/pageDataUtil'

const standardSections = ['faq', 'social module', 'seo']
const sectionsWithLesserMarginBottom = [
  'product grid',
  'product grid horizontal XL',
  'product grid horizontal',
  'product lane',
  'template collection grid',
  'template collection lane',
]
export const ANCHORLINK_SECTION_TYPE = 'anchor links'

export const determineSectionAvailability = (section: CategoryPageSection, locale: string) => {
  if (!section.enabled && !standardSections.includes(section.type)) {
    return false
  }

  if (PAGEHEADER_TYPES.includes(section.type)) {
    return false
  }

  if (
    section.specificLocales &&
    section.specificLocales.length > 0 &&
    !section.specificLocales.includes(PageDataUtil.sanitizeLocale(locale))
  ) {
    return false
  }

  return true
}

export const getMarginBottom = (
  currentSection: CategoryPageSection,
  nextSection?: CategoryPageSection
) => {
  const currentSectionType = currentSection.type
  const nextSectionType = nextSection?.type

  const currentSectionLocales = currentSection.specificLocales
  const nextSectionLocales = nextSection?.specificLocales
  const commonLocales =
    currentSectionLocales?.length === 0 ||
    nextSectionLocales?.length === 0 ||
    currentSectionLocales?.some((locale) => nextSectionLocales?.includes(locale))
  const isSectionWithLesserMarginBottom =
    sectionsWithLesserMarginBottom.includes(currentSectionType)

  if (PAGEHEADER_TYPES.includes(currentSectionType)) {
    return 8
  } else if (commonLocales && currentSectionType === nextSectionType) {
    if (isSectionWithLesserMarginBottom) {
      return 6
    }
    return 8
  } else if (isSectionWithLesserMarginBottom) {
    return 8
  }

  return 10
}

export const updateSectionIdforAnchorLinks = (
  sections: CategoryPageSection[]
): CategoryPageSection[] => {
  const updatedSections = [...sections]

  const anchorLinksSection = updatedSections.find(
    (section) => section.type === ANCHORLINK_SECTION_TYPE
  ) as AnchorLinksType
  const anchorLinksWithTitle = anchorLinksSection.items.filter((link) => link.title?.value)

  anchorLinksWithTitle.forEach((link) => {
    const linkedToSectionId = link.categorySectionSectionId
    const linkTitleValue = transformAnchorLinksTitle(link.title?.value)
    const sectionIndex = updatedSections.findIndex(
      (element) => element.sectionId === linkedToSectionId
    )
    if (sectionIndex >= 0) {
      updatedSections[sectionIndex].sectionId = linkTitleValue
    }
    link.categorySectionSectionId = linkTitleValue || link.categorySectionSectionId
  })

  return updatedSections
}

export const isAnchorLinksEnabled = (sections: CategoryPageSection[]) => {
  return sections.find((section) => section.type === ANCHORLINK_SECTION_TYPE && section.enabled)
}

export const updateSectionCount = (sections: CategoryPageSection[]): CategoryPageSection[] => {
  const countMap = new Map<string, number>()
  return sections.map((section) => {
    if (!countMap.has(section.type)) {
      countMap.set(section.type, 1)
      section.sectionTypeCount = 1
    } else {
      countMap.set(section.type, (countMap.get(section.type) as number) + 1)
      section.sectionTypeCount = countMap.get(section.type)
    }
    return section
  })
}

const removeSpecialCharacters = (str: string) => {
  return str.replace(/[^\w\s]/g, '')
}

export const transformAnchorLinksTitle = (anchorLinksTitle: string | undefined): string => {
  if (!anchorLinksTitle) return ''
  // eslint-disable-next-line no-control-regex
  const accentsRegex = /[^\x00-\x7F]/g

  const titleWithoutAccents = anchorLinksTitle.normalize('NFD').replace(accentsRegex, '')
  const titleWithoutSpecialCharacters = removeSpecialCharacters(titleWithoutAccents)

  // Relace all occurences of space with a single hyphen
  const titleWithoutSpaces = titleWithoutSpecialCharacters
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')

  return `_${titleWithoutSpaces}`
}
