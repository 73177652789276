import {
  ContentfulCloudinaryVideoMedia,
  ContentfulCloudinaryImage,
} from '../../types/Contentful/ContentfulTypes'

export default class CloudinaryUtil {
  public static isVideo (
    media?: ContentfulCloudinaryImage | ContentfulCloudinaryVideoMedia
  ): boolean {
    return !!media && !!(media as ContentfulCloudinaryVideoMedia).video
  }

  public static getVideoUrl (
    media: ContentfulCloudinaryImage | ContentfulCloudinaryVideoMedia | undefined,
    screenClass: string
  ) {
    const videoMedia = media as ContentfulCloudinaryVideoMedia | undefined
    const url =
      screenClass === 'xs' ? videoMedia?.video?.mobileVideoUrl : videoMedia?.video?.videoUrl
    return url || videoMedia?.video?.videoUrl
  }

  public static getVideos (
    media: ContentfulCloudinaryImage | ContentfulCloudinaryVideoMedia | undefined
  ) {
    if (!media) return []

    const videoMedia = media as ContentfulCloudinaryVideoMedia
    const publicId = videoMedia?.video?.publicId
    if (!publicId) return []

    const baseUrl = 'https://cms.cloudinary.vpsvc.com/video/upload/'
    const breakpoints = [3840, 2560, 1920, 1080, 768, 480, 360]

    return breakpoints.map((bp) => {
      const transformation = `w_${bp},h_${bp / 2},c_limit,f_auto,q_auto`
      return {
        breakpoint: bp,
        src: `${baseUrl}${transformation}/${publicId}.mp4`,
        poster: `${baseUrl}so_0,${transformation}/${publicId}.jpg`,
      }
    })
  }
}
