import { faqData } from '../../types/Contentful/ContentfulTypes'

export const filterAndMapFAQs = (
  questions: faqData[],
  mapFn: (faq: faqData, index: number) => any
) => {
  return questions
    .filter((entry) => entry.answer && entry.question)
    .map(mapFn)
}
