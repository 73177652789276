import { Column, Row, Typography, useScreenClass } from '@vp/swan'
import React from 'react'
import { useAppContext } from '../state/AppContext'
import { InformationLaneType } from '../types/TemplateConfiguration'
import { DebugPageSections } from './debugComponents/DebugPageSections'

interface InformationLaneProps {
  section: InformationLaneType;
  sectionNumber: number;
}

export const InformationLane = ({ section }: InformationLaneProps) => {
  const isMobile = useScreenClass() === 'xs'

  const { sidebarEnabled } = useAppContext()

  return (
    <>
      <Row>
        <Column span={sidebarEnabled ? 7 : 5}>
          {section.title && (
            <Typography component='h2' fontSize='x2large' marginBottom='4'>
              {section.title.value}
            </Typography>
          )}

          {section.subtitle && (
            <Typography fontSize='standard' marginBottom={7}>
              {section.subtitle.value}
            </Typography>
          )}
        </Column>
      </Row>
      <Row>
        {section.items.map((tile, index) => {
          return (
            <Column marginBottom='7' span={3} spanXs={6} key={`information-tile-${index}`}>
              {tile.tileHeader && (
                <Typography
                  marginBottom='2'
                  fontWeight='bold'
                  fontSize={isMobile ? 'xsmall' : 'standard'}
                >
                  {tile.tileHeader.value}
                </Typography>
              )}
              {tile.tileDescription && (
                <Typography fontSize={isMobile ? 'xsmall' : 'standard'}>
                  {tile.tileDescription.value}
                </Typography>
              )}
            </Column>
          )
        })}
      </Row>
      <DebugPageSections rawSectionData={section} />
    </>
  )
}
