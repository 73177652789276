import { AnchorLinksType, CategoryPageSection } from '../../types/TemplateConfiguration'
import { determineSectionAvailability } from '../pageSectionUtil/pageSectionUtil'

export const getAnchorLinkId = (sectionId: string) => {
  return `anchor-link-${sectionId}`
}

export const getAnchorLinksPropsBasedOnSectionAvailability = (
  sections: CategoryPageSection[],
  locale: string
) => {
  const anchorLinkSection = sections.find((section) => section.type === 'anchor links') as
    | AnchorLinksType
    | undefined

  if (!anchorLinkSection || !anchorLinkSection.enabled) {
    return undefined
  }

  const availableAnchorLinks: AnchorLinksType = { ...anchorLinkSection, items: [] }

  /*
    Determines availability by intersecting page sections and sections added to anchor links.
    Also retains order of page sections over order of sections added to anchor links.
  */
  sections.forEach((section) => {
    const anchorLinkItemForSection = anchorLinkSection.items.find(
      (sectionLinkItem) => sectionLinkItem.categorySectionSectionId === section.sectionId
    )

    const sectionAvailable =
      determineSectionAvailability(section, locale) && !!anchorLinkItemForSection?.title?.value
    if (!sectionAvailable) {
      return
    }
    availableAnchorLinks.items.push(anchorLinkItemForSection)
  })

  if (availableAnchorLinks.items.length <= 0) {
    return undefined
  }

  return availableAnchorLinks
}
