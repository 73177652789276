import { TemplateLaneType } from '../../types/TemplateLane'
import { Gallery } from '../../types/Gallery'
import { removeUrlParameter } from '../urls/urls'

export const getTemplateLaneGalleryInfo = (
  content: TemplateLaneType,
  locale: string,
  galleries?: Gallery[]
) => {
  const config =
    content.galleryInformation?.[`${locale}`] ??
    content.galleryInformation?.[`${content.defaultLocale}`]

  if (!config || !config.selectedGallery) {
    return undefined
  }

  const categories = config.selectedCategories
    ?.map((category) => category.id)
    ?.sort()
    ?.join(',')
  const productKey = config.selectedGallery.productKey

  const galleryInfo = galleries?.find((gallery) => {
    return gallery.productKey === productKey && gallery.categories?.sort().join(',') === categories
  })

  galleryInfo?.images?.forEach((image) => {
    if (image.galleryUrl) {
      const galleryUrl = removeUrlParameter(image.galleryUrl, 'keyword')
      image.galleryUrl = galleryUrl
    }
  })

  return galleryInfo
}
