import {
  StandardTile,
  StandardTileImage,
  FluidImage,
  StandardTileContents,
  StandardTileName,
  H3,
  Link,
} from '@vp/swan'
import React from 'react'
import { DisabledAltText } from '../types/Global'
import { trackTemplateTileClickEvent } from '../utils/tracking/tracking'
export interface TemplateTileProps {
  link: string
  imageUrl: string
  text: string
  mpvId: string
  index: number
  imageRef: any
  sectionType: 'Template Collection Grid' | 'Template Collection Lane'
  itemsCount: number
  sectionTypeCount: number
}
const TemplateTile = (props: TemplateTileProps) => {
  const {
    link,
    imageUrl,
    text,
    mpvId,
    index,
    imageRef,
    sectionType,
    itemsCount,
    sectionTypeCount,
  } = props
  return (
    <StandardTile
      skin='circular'
      mb='5'
      onClick={() =>
        trackTemplateTileClickEvent({
          categoryId: mpvId,
          destinationUrl: link,
          pageZone: sectionType,
          ctaValue: `Tile ${index}`,
          index,
          itemsCount,
          sectionTypeCount,
        })}
    >
      <StandardTileImage>
        <FluidImage src={imageUrl} ref={imageRef} alt={DisabledAltText} />
      </StandardTileImage>
      <StandardTileContents>
        <Link
          covering
          href={link}
          skin='unstyled'
          data-section={sectionType}
          data-position={index}
          data-translation='CTA'
        >
          <StandardTileName>
            <H3 fontSize='standard'>{text}</H3>
          </StandardTileName>
        </Link>
      </StandardTileContents>
    </StandardTile>
  )
}

export default TemplateTile
