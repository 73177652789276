import { ShortMsxMpv } from '../../types/ShortMsxMpv'
import {
  CalloutSkin,
  CalloutVariant,
  GoodBetterBestTile,
  GoodBetterBestValue,
} from '../../types/GoodBetterBest'
import PageDataUtil from '../pageDataUtil/pageDataUtil'
import ProductTileCollectionUtil, { ProductTileModel } from '../productTileCollection/productTileCollection'
import TokenEngine from '@vp/vp-js-token-engine'
import { BFFPageData } from '../../services/serviceClients/BffClient'
import { findGalleryUrl, findProductUrl } from '../urls/urls'
import { ContentfulEntryLink, ContentfulProductTile } from '../../types/Contentful/ContentfulTypes'
import { showProductTilePrice } from '../ProductTile/productTile'

const buildGoodBetterBestTiles = (
  goodBetterBestValues: GoodBetterBestValue[],
  pageDataFromBFF: BFFPageData,
  pageReferencedFields: Record<string, ContentfulEntryLink>,
  dictionaryEntries: Record<string, string>,
  locale: string
): (GoodBetterBestTile | undefined)[] => {
  const tokenEngine = new TokenEngine()

  const goodBetterBestTiles = goodBetterBestValues.map((gbbValue: GoodBetterBestValue) => {
    const { galleries, pageUrls } = pageDataFromBFF
    const isMpv = !!gbbValue?.mpvId
    const mpvId = isMpv ? gbbValue.mpvId : gbbValue.merchandisingElement?.mpvId
    const gbbMpv: ShortMsxMpv | undefined = mpvId
      ? PageDataUtil.getMpv(mpvId, pageDataFromBFF)
      : undefined
    const isGbbMpvExternallyMarketable = gbbMpv && gbbMpv?.externallyMarketable

    if (gbbValue.template) {
      return createGBBProductTileV2(gbbValue, gbbMpv)
    }

    let galleryUrl, productUrl, imageUrl, secondaryUrlTranslation
    if (isGbbMpvExternallyMarketable) {
      galleryUrl = findGalleryUrl(galleries, gbbMpv.coreProductId)
      productUrl = findProductUrl(pageUrls, gbbMpv.mpvId)
      imageUrl = gbbMpv.defaultProductImage.cloudinaryImage
      secondaryUrlTranslation = tokenEngine.replace(dictionaryEntries.secondaryUrlText, {
        productName: gbbValue?.productTileHeader || gbbMpv.name,
      })
    }

    const gbbMpvTile: GoodBetterBestTile | undefined =
      isGbbMpvExternallyMarketable && secondaryUrlTranslation
        ? createGBBMpvTile({
          mpv: gbbMpv,
          imageUrl,
          galleryUrl,
          productUrl,
          secondaryUrlTranslation,
          pageDataFromBFF,
          startDesigningText: dictionaryEntries.startDesigning,
        })
        : undefined

    if (isMpv) {
      return galleryUrl ? gbbMpvTile : undefined
    } else {
      const referencedField = gbbValue && pageReferencedFields[gbbValue.contentful_id]

      if (referencedField) {
        const { primaryUrl, secondaryUrl } = referencedField as ContentfulProductTile

        const gbbProductTile = createGBBProductTile({
          gbbValue,
          primaryUrl,
          secondaryUrl: secondaryUrl || '',
          locale,
        })

        const mergedProductTile = gbbMpvTile
          ? mergeGBBTiles(gbbMpvTile, gbbProductTile)
          : gbbProductTile

        return mergedProductTile.transformedPrimaryUrl ? mergedProductTile : undefined
      }

      return undefined
    }
  })

  return goodBetterBestTiles
}

const createGBBProductTileV2 = (
  gbbValue: GoodBetterBestValue,
  gbbMpv : ShortMsxMpv | undefined
) => {
  const tileProps = gbbValue.template
  if (tileProps) {
    const { calloutDetails, tileDetails, productsList } = tileProps
    const mpvId = productsList?.selectedProduct?.mpvId
    let gbbMpvTile: ProductTileModel = {}
    if (mpvId) {
      gbbMpvTile = ProductTileCollectionUtil.getTileV2FromMpv(
        mpvId,
        gbbMpv
      )
    }

    const gbbTileV2: GoodBetterBestTile = {
      name: tileDetails?.header?.value ?? '',
      description: tileDetails?.description?.value || '',
      imageUrl: tileDetails?.image?.cloudinaryImage?.[0]?.src,
      transformedPrimaryUrl: tileDetails?.link?.href,
      primaryUrlText: tileDetails?.urlText?.value ?? tileDetails?.link?.text ?? '',
      transformedSecondaryUrl: tileProps?.secondaryLink?.link?.href ?? '',
      secondaryUrlText:
        tileProps?.secondaryLink?.label?.value ?? tileProps?.secondaryLink?.link?.text ?? '',
      calloutText: calloutDetails?.text?.value || '',
      calloutColor: calloutDetails?.skin || 'standard',
      calloutVariant: calloutDetails?.variant || 'overlay',
      showPrice: true,
      temporarilyOutOfStock: gbbMpvTile?.temporarilyOutOfStock ?? false,
      productId: gbbMpvTile?.productId,
      colorInformation: gbbMpvTile?.colorInformation,
      mpvId,
    }

    return gbbTileV2
  }
}

const createGBBMpvTile = ({
  mpv,
  imageUrl,
  galleryUrl,
  productUrl,
  secondaryUrlTranslation,
  pageDataFromBFF,
  startDesigningText,
}: {
  mpv: ShortMsxMpv;
  imageUrl: string | undefined;
  galleryUrl: string | undefined;
  productUrl: string | undefined;
  secondaryUrlTranslation: string;
  pageDataFromBFF: BFFPageData;
  startDesigningText: string;
}) => {
  const gbbMpvTile = ProductTileCollectionUtil.getTileFromMpv(
    mpv.mpvId,
    pageDataFromBFF
  )
  return {
    ...gbbMpvTile,
    imageUrl,
    transformedPrimaryUrl: galleryUrl,
    primaryUrlText: startDesigningText,
    transformedSecondaryUrl: productUrl || galleryUrl,
    secondaryUrlText: secondaryUrlTranslation,
    productId: mpv.coreProductId,
  } as GoodBetterBestTile
}

const createGBBProductTile = ({
  gbbValue,
  primaryUrl,
  secondaryUrl,
  locale,
}: {
  gbbValue: GoodBetterBestValue;
  primaryUrl: string;
  secondaryUrl: string;
  locale: string;
}) => {
  const tilePricing = gbbValue.tilePricing
  const showPrice = showProductTilePrice(locale, tilePricing)
  return {
    name: gbbValue.productTileHeader,
    description: gbbValue.productTileSubHeader || '',
    imageUrl: gbbValue.productImage?.cloudinaryImage?.cloudinaryUrl,
    transformedPrimaryUrl: primaryUrl,
    primaryUrlText: gbbValue.primaryUrlText,
    transformedSecondaryUrl: secondaryUrl,
    secondaryUrlText: gbbValue.secondaryUrlText,
    calloutText: gbbValue.calloutText || '',
    calloutColor: gbbValue.calloutColor || 'standard',
    calloutVariant: gbbValue.calloutVariant || 'overlay',
    showPrice,
  } as GoodBetterBestTile
}

const mergeGBBTiles = (gbbMpvTile: GoodBetterBestTile, gbbProductTile: GoodBetterBestTile) => {
  let transformedSecondaryUrl
  if (gbbProductTile.transformedPrimaryUrl) {
    transformedSecondaryUrl = gbbProductTile.transformedSecondaryUrl
  } else {
    transformedSecondaryUrl =
      gbbProductTile.transformedSecondaryUrl || gbbMpvTile.transformedSecondaryUrl
  }
  return {
    mpvId: gbbProductTile.mpvId || gbbMpvTile.mpvId,
    name: gbbProductTile.name || gbbMpvTile.name,
    description: gbbProductTile.description || gbbMpvTile.description,
    imageUrl: gbbProductTile.imageUrl || gbbMpvTile.imageUrl,
    transformedPrimaryUrl: gbbProductTile.transformedPrimaryUrl || gbbMpvTile.transformedPrimaryUrl,
    primaryUrlText: gbbProductTile.primaryUrlText || gbbMpvTile.primaryUrlText,
    transformedSecondaryUrl,
    secondaryUrlText: gbbProductTile.secondaryUrlText || gbbMpvTile.secondaryUrlText,
    temporarilyOutOfStock: gbbProductTile.temporarilyOutOfStock || gbbMpvTile.temporarilyOutOfStock,
    calloutText: gbbProductTile.calloutText || '',
    calloutColor: gbbProductTile.calloutColor || 'standard',
    calloutVariant: gbbProductTile.calloutVariant || 'overlay',
    showPrice: gbbProductTile.showPrice,
    productId: gbbMpvTile.productId,
    colorInformation: gbbMpvTile.colorInformation,
  }
}

const showGoodBetterBestSection = (goodBetterBestTiles: (GoodBetterBestTile | undefined)[]) => {
  return Boolean(
    goodBetterBestTiles &&
      !goodBetterBestTiles.includes(undefined) &&
      goodBetterBestTiles.length === 3
  )
}

const resolveGbbTileCallout = (
  productTile: GoodBetterBestTile | undefined,
  outOfStockText: string
):
  | {
    callout: string;
    skin: CalloutSkin;
    variant: CalloutVariant;
  }
  | undefined => {
  if (productTile?.temporarilyOutOfStock) {
    return {
      callout: outOfStockText,
      skin: 'error',
      variant: 'overlay',
    }
  }

  if (productTile?.calloutText) {
    return {
      callout: productTile?.calloutText,
      skin: productTile?.calloutColor || 'standard',
      variant: productTile?.calloutVariant || 'overlay',
    }
  }

  return undefined
}

export { buildGoodBetterBestTiles, showGoodBetterBestSection, resolveGbbTileCallout }
