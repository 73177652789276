import React from 'react'
import { pageCategoryPageV3 } from '../types/Contentful/ContentfulTypes'
import { BFFPageData } from '../services/serviceClients/BffClient'
import {
  CategoryPageSection,
  CategoryPageSectionType,
  InformationLaneType,
  InformationMediaType,
  PageHeaderType,
  ProductGridType,
  SideBySideHeroType,
  TemplateCollectionType,
} from '../types/TemplateConfiguration'

import Faq, { showFAQSection } from './FAQ'
import { ProductGrid } from './ProductGrid'
import { ProductLane } from './ProductLane'
import GoodBetterBest from './GoodBetterBest'
import PromoBanner from './PromoBanner'
import Seo, { showSeoSection } from './Seo'
import SocialCurations, { showOlapic } from './SocialCurations'
import VirtualSection from './VirtualSection'
import TemplateLane, { showTemplateLane } from './TemplateLane'
import PageSectionWrapper from './PageSectionWrapper'
import { useAppContext } from '../state/AppContext'
import PageHeaderContainer from './header/PageHeaderContainer'
import { useExperimentsContext } from './ExperimentsProvider'
import { addNewTestSections, ExperimentData, TestSection } from '../ab-tests/utils/TestSection'
import {
  determineSectionAvailability,
  getMarginBottom,
  isAnchorLinksEnabled,
  updateSectionCount,
  updateSectionIdforAnchorLinks,
} from '../utils/pageSectionUtil/pageSectionUtil'
import { InformationLane } from './InformationLane'
import InformationMedia, { showInformationMediaSection } from './InformationMedia'
import SideBySideHero, { showSideBySideHero } from './SideBySideHero'
import { GoodBetterBestSection } from '../types/GoodBetterBest'
import { VirtualSection as VirtualSectionType } from '../types/VirtualSection'
import { TemplateLaneType } from '../types/TemplateLane'
import { ChecksAccessoriesATC } from './ChecksAccessoriesATC'
import AnchorBarWrapper from './AnchorBarWrapper'
import TemplateCollectionGrid from './TemplateCollectionGrid'
import TemplateCollectionLane from './TemplateCollectionLane'
import { BoundedContent, useScreenClass } from '@vp/swan'
import { primaryContentExperimentVariationControl } from '../constants/primaryContentTestData'

interface PageSectionsProps {
  sections: CategoryPageSection[];
  pageCategoryPageV3: pageCategoryPageV3;
  pageDataFromBFF: BFFPageData;
}

const PageSections = (props: PageSectionsProps) => {
  const { sections, pageCategoryPageV3, pageDataFromBFF } = props
  const { locale, merchandisingCategoryId, isPagePartOfPrimarayContentExperiment, primaryContentExperimentVariation, experiments, identity } = useAppContext()
  const experimentData = useExperimentsContext()
  const isMobile = useScreenClass() === 'xs'
  const sectionsWithCount = React.useMemo(() => {
    return updateSectionCount(sections)
  }, [sections])

  const sectionsWithAnchorId = React.useMemo(() => {
    if (isAnchorLinksEnabled(sectionsWithCount)) {
      return updateSectionIdforAnchorLinks(sectionsWithCount)
    }
    return sectionsWithCount
  }, [sections])

  const sectionComponents = sectionsWithAnchorId.map((section, index, sections) => {
    const sectionAvailable = determineSectionAvailability(section, locale)
    if (!sectionAvailable) {
      return null
    }

    const filteredSections = sections.filter((s) => section.type === s.type && s.enabled)
    const number = filteredSections.findIndex((s) => s === sections[index]) + 1
    const sectionId = section.sectionId

    const sectionComponent = getSectionComponent(
      {
        sections,
        section,
        pageDataFromBFF,
        merchandisingCategoryId,
        type: section.type,
        key: index,
        pageCategoryPageV3,
        locale,
        sectionNumber: number,
        nextSection: index < sections.length - 1 ? sections[index + 1] : undefined,
        index,
      },
      experimentData,
      isPagePartOfPrimarayContentExperiment,
      primaryContentExperimentVariation
    )

    const currentSection = sectionsWithAnchorId[index]
    const nextSection = sectionsWithAnchorId[index + 1]

    const marginBottom = getMarginBottom(currentSection, nextSection)

    return sectionComponent && (
      <PageSectionWrapper
        type={section.type}
        marginBottom={marginBottom}
        key={`section-${section.type}-${index}`}
      >
        {section.type === ('anchor links' as CategoryPageSectionType)
          ? (
            <>{sectionComponent}</>
            )
          : (
            <div id={sectionId} tabIndex={-1}>
              {sectionComponent}
            </div>
            )}
      </PageSectionWrapper>
    )
  })

  const sectionComponentsWithTests = addNewTestSections({
    experimentData,
    sections: sectionComponents,
    pageCategoryPageV3,
    pageDataFromBFF,
    locale,
    experiments,
    isMobile,
    identity,
    merchandisingCategoryId
  })

  return <>{sectionComponentsWithTests}</>
}

const getSectionComponent = (
  sectionProps: {
    sections: CategoryPageSection[];
    section: CategoryPageSection;
    pageDataFromBFF: BFFPageData;
    merchandisingCategoryId: string
    type: string;
    key: number;
    pageCategoryPageV3: pageCategoryPageV3;
    locale: string;
    sectionNumber: number;
    nextSection?: CategoryPageSection;
    index: number
  },
  experimentData: ExperimentData,
  isPagePartOfPrimarayContentExperiment: boolean,
  primaryContentExperimentVariation?: string
) => {
  const {
    sections,
    section,
    pageDataFromBFF,
    merchandisingCategoryId,
    type,
    key,
    pageCategoryPageV3,
    locale,
    sectionNumber,
    nextSection,
    index
  } = sectionProps

  const UniqueTestSection = TestSection(experimentData, type)
  if (UniqueTestSection) {
    return <UniqueTestSection {...sectionProps} />
  }

  switch (type) {
    case 'anchor links': {
      return (
        <AnchorBarWrapper
          pageCategoryPageV3={pageCategoryPageV3}
          sections={sections}
        />
      )
    }
    case 'promo banner':
      return (
        <PromoBanner
          key={key}
          configuration={section}
          sectionNumber={sectionNumber}
        />
      )
    case 'section header':
      return (
        <PageHeaderContainer
          configuration={section as PageHeaderType | SideBySideHeroType}
          pageDataFromBFF={pageDataFromBFF}
        />
      )
    case 'side by side hero':
      return (
        showSideBySideHero(section) && (
          <BoundedContent paddingX={0} marginBottom={{ xs: 7, sm: 7, md: 8 }}>
            <SideBySideHero section={section as SideBySideHeroType} />
          </BoundedContent>
        )
      )
    case 'product grid':
    case 'product grid horizontal XL':
    case 'product grid horizontal':
      return (
        <ProductGrid
          key={key}
          section={section as ProductGridType}
          pageDataFromBFF={pageDataFromBFF}
          sectionNumber={sectionNumber}
        />
      )
    case 'product lane':
      return (
        <ProductLane
          key={key}
          section={section as ProductGridType}
          pageDataFromBFF={pageDataFromBFF}
          sectionNumber={sectionNumber}
          nextSectionId={nextSection?.sectionId}
          nextSectionTitle={(nextSection as any)?.title}
        />
      )
    case 'accessories grid':
      return <ChecksAccessoriesATC category={merchandisingCategoryId} />
    case 'information lane':
      return (
        <InformationLane
          key={key}
          section={section as InformationLaneType}
          sectionNumber={sectionNumber}
        />
      )
    case 'good better best':
      return (
        <GoodBetterBest
          section={section as GoodBetterBestSection}
          pageDataFromBFF={pageDataFromBFF}
        />
      )
    case 'information media section':
      return (
        showInformationMediaSection(section as InformationMediaType) && (
          <InformationMedia
            section={section as InformationMediaType}
            sectionNumber={sectionNumber}
          />
        )
      )
    case 'virtual section':
      return (
        <VirtualSection
          data={(section as VirtualSectionType).value}
          mpvs={pageDataFromBFF?.mpvs || []}
          designServices={pageDataFromBFF?.designServices}
          uploadFlowMpv={pageDataFromBFF?.uploadFlowMpv}
          galleries={pageDataFromBFF?.galleries}
        />
      )
    case 'template lane':
      return (
        showTemplateLane(section, locale, pageDataFromBFF?.galleries) && (
          <TemplateLane
            key={key}
            content={section as TemplateLaneType}
            galleries={pageDataFromBFF?.galleries}
            nextSectionId={nextSection?.sectionId}
            nextSectionTitle={(nextSection as any)?.title}
          />
        )
      )
    case 'template collection lane':
      return index === 2 &&
        isPagePartOfPrimarayContentExperiment &&
        primaryContentExperimentVariation !== primaryContentExperimentVariationControl
        ? <TemplateCollectionGrid
            configuration={section as TemplateCollectionType}
            galleries={pageDataFromBFF?.galleries}
            nextSectionId={nextSection?.sectionId}
            nextSectionTitle={(nextSection as any)?.title}
            index={index}
          />
        : <TemplateCollectionLane
            configuration={section as TemplateCollectionType}
            galleries={pageDataFromBFF?.galleries}
            nextSectionId={nextSection?.sectionId}
            nextSectionTitle={(nextSection as any)?.title}
          />
    case 'template collection grid':
      return (
        <TemplateCollectionGrid
          configuration={section as TemplateCollectionType}
          galleries={pageDataFromBFF?.galleries}
          nextSectionId={nextSection?.sectionId}
          nextSectionTitle={(nextSection as any)?.title}
          index={index}
        />
      )
    case 'seo':
      return (
        showSeoSection(
          pageCategoryPageV3.seoSectionTitle,
          pageCategoryPageV3.seoHTML
        ) && (
          <Seo
            key={key}
            sectionTitle={pageCategoryPageV3.seoSectionTitle}
            content={pageCategoryPageV3.seoHTML}
          />
        )
      )
    case 'social module':
      return (
        showOlapic(pageCategoryPageV3.socialCurations) && (
          <SocialCurations
            key={key}
            socialCurationsTitle={pageCategoryPageV3.socialCurationsTitle}
            socialCurationsSubtitle={pageCategoryPageV3.socialCurationsSubtitle}
            data={pageCategoryPageV3.socialCurations}
          />
        )
      )
    case 'faq':
      if (showFAQSection(pageCategoryPageV3)) {
        return (
          <Faq
            key={key}
            title={pageCategoryPageV3.faqTitle}
            frequentlyAskedQuestions={
              pageCategoryPageV3.frequentlyAskedQuestions
            }
          />
        )
      }
      return null
    default:
      return null
  }
}

export default PageSections
