import { useState, lazy, Suspense } from 'react'
import { FlexBox, Button, Link, useScreenClass, Spinner } from '@vp/swan'
import { trackClickEvent } from '../../utils/tracking/tracking'
import { ReorderAddToCartFeedback } from './ReorderAddToCartFeedback'
import { hideExpressCheckout } from './reorderConstants'
import { useAppContext } from '../../state/AppContext'
import { addToCart, getAltCartId } from './reorderUtils'
import { CartTenantDetails } from '@vp/cart-client'
import { formatLocale } from '../../utils/buildHelpers'

const ExpressCheckout = lazy(() =>
  import('@vp/om-express-checkout').then((module) => ({
    default: module.ExpressCheckout,
  }))
)

interface ReorderCTAsProps {
  reorderCTAsContent: Record<string, string>;
  pageZone: string;
  pageDetails: Record<string, string>;
  filteredOrder: Record<string, any>;
  shopperId: string;
}

const ReorderCTAs = (props: ReorderCTAsProps) => {
  const {
    reorderCTAsContent,
    pageZone,
    pageDetails,
    filteredOrder,
    shopperId,
  } = props
  const isMobile = useScreenClass() === 'xs'
  const { auth, vistacartTenant, locale, merchandisingCategoryId } =
    useAppContext()
  const [addToCartfeedback, setAddToCartFeedback] = useState({
    message: '',
    type: '',
  })
  const [dismissed, setDismissed] = useState(false)
  const [cartDetails, setCartDetails] = useState({
    type: 'alt',
    altCartId: '',
  })
  const [awaitingApiResponse, setAwaitingApiResponse] = useState(false)

  const onReorderClick = async (orderDetails: any) => {
    try {
      const altCartId = await getAltCartId(
        auth,
        shopperId,
        (vistacartTenant as CartTenantDetails).tenantId,
        orderDetails
      )
      setCartDetails({ type: 'alt', altCartId })
      trackClickEvent({
        categoryId: merchandisingCategoryId,
        destinationUrl: '',
        ctaValue: 'Buy now',
        pageZone,
      })
    } catch (e) {
      console.log('onReorderClick', e)
    }
  }

  const onAddToCartClick = async (orderDetails: any) => {
    setDismissed(true)
    setAwaitingApiResponse(true)
    try {
      const addToCartResponse = await addToCart(
        auth,
        shopperId,
        (vistacartTenant as CartTenantDetails).tenantId,
        orderDetails
      )

      if (
        addToCartResponse.success === true &&
        addToCartResponse.status === 200
      ) {
        setAddToCartFeedback({
          message: reorderCTAsContent.addToCartSuccess,
          type: 'success',
        })
      } else {
        setAddToCartFeedback({
          message: reorderCTAsContent.addToCartFailure,
          type: 'error',
        })
      }
      setAwaitingApiResponse(false)
      setDismissed(false)
      trackClickEvent({
        categoryId: merchandisingCategoryId,
        destinationUrl: '',
        ctaValue: 'Add to cart',
        pageZone,
      })
    } catch (e) {
      setAwaitingApiResponse(false)
      setDismissed(false)
      console.log('onReorderClick', e)
    }
  }

  return (
    <>
      <FlexBox
        gap='between-actions'
        flexDirection='row'
        flexWrap='wrap'
        mt={2}
      >
        {!hideExpressCheckout.includes(formatLocale(locale, false)) && (
          <Button
            skin='primary'
            onClick={() => onReorderClick(filteredOrder)}
            width={isMobile ? 'full-width' : 'standard'}
            mt={0}
          >
            {reorderCTAsContent.buyNow}
          </Button>
        )}

        <Button
          skin={hideExpressCheckout.includes(formatLocale(locale, false)) ? 'primary' : 'secondary'}
          onClick={() => onAddToCartClick(filteredOrder)}
          width={isMobile ? 'full-width' : 'standard'}
          disabled={awaitingApiResponse}
          mt={0}
        >
          {awaitingApiResponse && (
            <Spinner
              size='standard'
              accessibleText={reorderCTAsContent.loading}
            />
          )}
          {reorderCTAsContent.addToCart}
        </Button>

        {filteredOrder.designData.editDocumentUrl && (
          <Button
            width={isMobile ? 'full-width' : 'standard'}
            mt={0}
            render={(props) => (
              <Link
                to={filteredOrder.designData.editDocumentUrl}
                className={props.className}
                onClick={() =>
                  trackClickEvent({
                    categoryId: merchandisingCategoryId,
                    destinationUrl: filteredOrder.designData.editDocumentUrl,
                    ctaValue: 'Edit Design',
                    pageZone,
                  })}
              >
                {props.children}
              </Link>
            )}
          >
            {reorderCTAsContent.editDesign}
          </Button>
        )}
      </FlexBox>
      {!dismissed && (
        <ReorderAddToCartFeedback
          feedBack={addToCartfeedback}
          setDismissed={setDismissed}
          dismissed={dismissed}
        />
      )}
      {filteredOrder.orderNumber && cartDetails.altCartId && (
        <Suspense fallback={null}>
          <ExpressCheckout
            cartDetails={cartDetails}
            shopperId={shopperId}
            locale={locale}
            auth={auth}
            tenantDetails={vistacartTenant}
            showExpressCheckout={!!cartDetails.altCartId}
            pageDetails={pageDetails}
            setShowExpressCheckout={!!cartDetails.altCartId}
            debug
          />
        </Suspense>
      )}
    </>
  )
}

export default ReorderCTAs
