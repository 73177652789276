import React from 'react'
import { marked } from 'marked'

import { Banner, BannerDescription, BannerFootnote, BannerSubtitle, BannerText, BannerTextContainer, BannerTitle, P, PipeSeparator, PromoCode } from '@vp/swan'
import { DefaultContentfulBannerWrapperProps, PLTC_MARKETING_COLORS, TextProps } from '@vp/banner-wrapper'

import { useAppContext } from '../../state/AppContext'
import { HeroContent, SidebarType } from '../../types/TemplateConfiguration'

interface PrimaryContentTestHeaderProps {
  newBannerData?: DefaultContentfulBannerWrapperProps;
  oldBannerData?: HeroContent;
  sidebar?: SidebarType;
  leftNav?: JSX.Element;
  oldBannerBackgroundColor?: string;
}

const PrimaryContentTestHeader = ({ newBannerData, oldBannerData, sidebar, leftNav, oldBannerBackgroundColor }: PrimaryContentTestHeaderProps) => {
  const { sidebarEnabled } = useAppContext()

  const { title: oldBannerTitle, subtitle: oldBannerSubtitle } = oldBannerData || {}

  const { title, subtitle, description, promocode, footnote, offerEnding } = newBannerData?.textContent || {}
  const titleValue = (title as TextProps)?.value
  const subtitleValue = Array.isArray(subtitle) ? (subtitle[0] as TextProps)?.value : (subtitle as TextProps)?.value
  const descriptionValue = (description as TextProps)?.value
  const promocodeValue = (promocode as TextProps)?.value
  const offerEndingValue = (offerEnding as TextProps)?.value
  const footnoteValue = (footnote as TextProps)?.value

  const darkMode = newBannerData?.layout?.darkMode
  const newBannerBackgroundColor = newBannerData?.layout?.bgc?.startsWith('#')
    ? newBannerData?.layout?.bgc
    : PLTC_MARKETING_COLORS[newBannerData?.layout?.bgc as keyof typeof PLTC_MARKETING_COLORS]
  const backgroundColor = newBannerBackgroundColor || oldBannerBackgroundColor

  return (
    <Banner
      withLeftHandNavigation={!!sidebarEnabled}
      style={
                {
                  '--swan-public-marketing-background': backgroundColor,
                  minHeight: 'fit-content',
                } as React.CSSProperties
            }
      mb={{ xs: 7, sm: 9 }}
      height='short'
      darkMode={darkMode}
    >
      {sidebarEnabled && sidebar && leftNav}
      <BannerTextContainer>
        <BannerText style={{ textAlign: 'left' }} py={0}>
          {/* rendering old banner */}
          {oldBannerTitle?.value && <BannerTitle as='h1'>{oldBannerTitle?.value}</BannerTitle>}
          {oldBannerSubtitle?.value && <BannerDescription>{oldBannerSubtitle?.value}</BannerDescription>}

          {/* rendering new banner */}
          {titleValue && <BannerTitle as='h1'>{titleValue}</BannerTitle>}
          {subtitleValue && <BannerSubtitle as={!titleValue ? 'h1' : undefined}>{subtitleValue}</BannerSubtitle>}
          {descriptionValue &&
            <BannerDescription as='div'>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked.parseInline(descriptionValue, { async: false }) as string,
                }}
              />
            </BannerDescription>}
          {(offerEndingValue || promocodeValue) &&
            <BannerDescription>
              <P m={0}>
                <PromoCode as='span' style={{ textTransform: 'none' }}>
                  {promocodeValue}
                </PromoCode>
                {offerEnding && promocode && <PipeSeparator />}
                {offerEndingValue}
              </P>
            </BannerDescription>}
          {footnoteValue && (
            <BannerFootnote as='div' mt={2}>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked.parseInline(footnoteValue, { async: false }) as string,
                }}
              />
            </BannerFootnote>
          )}
        </BannerText>
      </BannerTextContainer>
    </Banner>
  )
}

export default PrimaryContentTestHeader
