import TokenEngine from '@vp/vp-js-token-engine'
import React from 'react'
import { Translated } from '../types/Global'

import { stylesheet } from '../styles/SkipToNextSectionLink.scss'
import { useAppContext } from '../state/AppContext'
import { useStyles } from '@vp/ubik-context'
import { Link } from '@vp/swan'

export interface SkipToNextSectionLinkProps {
  nextSectionId?: string;
  nextSectionTitle?: Translated;
}

export const SkipToNextSectionLink = ({
  nextSectionId,
  nextSectionTitle,
}: SkipToNextSectionLinkProps) => {
  if (!nextSectionId) {
    return <></>
  }

  const { dictionaryEntries } = useAppContext()
  useStyles(stylesheet)

  const tokenEngine = new TokenEngine()
  const skipLinkContent =
    nextSectionTitle?.value && dictionaryEntries.skipToNextSectionWithTitle
      ? tokenEngine.replace(dictionaryEntries.skipToNextSectionWithTitle, {
        sectionTitle: nextSectionTitle.value,
      })
      : dictionaryEntries.skipToNextSection

  return (
    <Link
      className='skip-to-next-section'
      href={`#${nextSectionId}`}
    >
      {skipLinkContent ?? ''}
    </Link>
  )
}
