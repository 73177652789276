import qs from 'qs'
import { Gallery } from '../../types/Gallery'
import { PageUrl } from '../../types/Url'

export const findGalleryUrl = (
  galleries: Gallery[] | undefined,
  coreProductId: string | undefined,
  galleryCategories: string[] = []
) => {
  return galleries?.find(
    (gallery) =>
      gallery.productKey === coreProductId &&
      (gallery.categories || []).sort().join(',') === galleryCategories.sort().join(',')
  )?.url
}

export const findProductUrl = (pageUrls: PageUrl[], mpvId: string) => {
  return pageUrls.find((url) => url.mpvId === mpvId)?.url
}

export const removeUrlParameter = (url: string, paramKey: string) => {
  const r = new URL(url)
  r.searchParams.delete(paramKey)
  return r.href
}

export const appendQueryParams = (url: string, queryParams: string) => {
  const existingParams = url.split('?')[1]

  const separator = existingParams ? '&' : '?'

  const newUrl = `${url}${separator}${queryParams}`

  return newUrl
}

export const getQueryParametersFromUrl = (): Record<string, string> => {
  try {
    return qs.parse(window?.location?.search?.substring(1) || '') as Record<string, string>
  } catch {
    return {}
  }
}
