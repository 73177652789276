import { Typography } from '@vp/swan'
import React from 'react'

export const GoodBetterBestError = () => {
  return (
    <Typography textColor='error'>
      Good Better Best section has undefined or unavailable tiles
    </Typography>
  )
}
