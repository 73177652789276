import { BoundedContent, Column, GridContainer, Typography, Row, H1 } from '@vp/swan'
import React from 'react'
import { Translated } from '../../types/Global'
import {
  getFontFamilyType,
  getFontFamilyWeight,
  isOfTypePageHeader,
} from '../../utils/standardHeroContents/standardHeroContents'

interface PageHeaderWithoutImageProps {
  type: 'page header' | 'promo banner' | 'sub-category page header' | 'section header';
  title: Translated;
  fontFamily?: string;
  subtitle?: Translated;
}

const PageHeaderWithoutImage = (props: PageHeaderWithoutImageProps) => {
  const { title, fontFamily, subtitle, type } = props

  const isPageHeader = isOfTypePageHeader(type)
  const fontFamilyType = getFontFamilyType(isPageHeader, fontFamily)
  const fontFamilyWeight = getFontFamilyWeight(isPageHeader, fontFamily)
  const fontFamilyProps: Record<string, string> = {}
  if (fontFamilyType) {
    fontFamilyProps.fontFamily = fontFamilyType
  }
  if (fontFamilyWeight) {
    fontFamilyProps.fontWeight = fontFamilyWeight
  }

  return (
    <BoundedContent mb={11}>
      <GridContainer>
        <Row>
          <Column span={6} offset={1} spanXs={12}>
            {type !== 'section header' && type !== 'promo banner'
              ? (
                <H1 {...fontFamilyProps} mt='7' mb='6'>
                  {title.value}
                </H1>
                )
              : (
                <Typography mt='7' mb='6'>
                  {title.value}
                </Typography>
                )}
            <Typography>{subtitle?.value}</Typography>
          </Column>
        </Row>
      </GridContainer>
    </BoundedContent>
  )
}

export default PageHeaderWithoutImage
