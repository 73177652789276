import isEqual from 'lodash.isequal'
import { Gallery } from '../../types/Gallery'
import { getGalleryData } from '../simpleLink/simpleLink'
import { actionFilters } from '../../types/Contentful/ContentfulTypes'

const getGalleryUrl = ({
  galleries,
  actionId,
  actionFilters,
  locale,
}: {
  galleries?: Gallery[];
  actionId: string;
  actionFilters: actionFilters;
  locale: string;
}) => {
  const galleryData = getGalleryData(actionId, actionFilters)
  if (galleryData) {
    const { categories, productKey } = galleryData
    const galleryConfig = galleries?.find(
      (gallery) =>
        isEqual(gallery.categories, categories) &&
        gallery.locale === locale &&
        gallery.productKey === productKey
    )

    if (galleryConfig) {
      return galleryConfig.url
    }
  }
}

export { getGalleryUrl }
