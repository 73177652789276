import { type IdentityProviderProps } from '@vp/ubik-context'

type VPAuthConfig = (typeof IdentityProviderProps)['auth']

export const createAuthConfig = (culture: string): VPAuthConfig => ({
  culture,
  developmentMode: process.env.NODE_ENV === 'development',
  options: {
    requireSession: true,
    customText: 'Sign in to see your account',
  },
})
