export interface Translated {
  contentful_id: string;
  value: string;
}

export interface TransformedUrlQuery {
  transformedTargetUrls: string;
  transformedPrimaryUrls: string;
  transformedSecondaryUrls: string;
}

export interface TrackingConfiguration {
  pageSection: string;
  pageStage: string;
  pageName: string;
}

export interface pageQuery {
  id: string;
  culture: string;
}

export type SpaceLocales = {
  [spaceId: string]: {
    description: string;
    default: string;
    available: string[];
  };
}

export const DisabledAltText = ''
