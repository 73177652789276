import { pageCategoryPageV3 } from '../../types/Contentful/ContentfulTypes'
import { ShortMerchandisingCategory } from '../../types/ShortMerchandisingCategory'
import { ShortProductTile } from '../../types/ShortProductTile'
import { BFFPageData } from '../../services/serviceClients/BffClient'
import { ShortMsxMpv } from '../../types/ShortMsxMpv'

export default class PageDataUtil {
  public static getMpvs (mpvIds: string[], pageDataFromBFF: BFFPageData) {
    return mpvIds
      .map((mpvId) => {
        return this.getMpv(mpvId, pageDataFromBFF)
      })
      .filter((mpv) => !!mpv) as ShortMsxMpv[]
  }

  public static getMpv (mpvId: string, pageDataFromBFF: BFFPageData) {
    return pageDataFromBFF.mpvs.find((mpv) => mpv.mpvId === mpvId)
  }

  public static getProductTiles (mpvIds: string[], pageData: pageCategoryPageV3) {
    return mpvIds
      .map((mpvId) => this.getProductTile(mpvId, pageData))
      .filter((tile) => !!tile) as ShortProductTile[]
  }

  public static getProductTile (
    mpvId: string,
    pageData: pageCategoryPageV3
  ): ShortProductTile | undefined {
    return pageData.referencedFields.find(
      (referencedField) =>
        referencedField.__typename === 'ContentfulProductTile' &&
        (referencedField as ShortProductTile).merchandisingElement.mpvId === mpvId
    ) as ShortProductTile
  }

  public static getMerchandisingCategory (
    mpvId: string,
    pageData: pageCategoryPageV3
  ): ShortMerchandisingCategory | undefined {
    return pageData.referencedFields.find(
      (referencedField) =>
        referencedField.__typename === 'ContentfulMerchandisingCategory' &&
        (referencedField as ShortMerchandisingCategory).mpvId === mpvId
    ) as ShortMerchandisingCategory
  }

  public static sanitizeLocale (locale: string): string {
    return `${locale.slice(0, 2).toLowerCase()}-${locale.slice(-2).toUpperCase()}`
  }
}
