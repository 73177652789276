import React, { useMemo } from 'react'
import sanitizeHtml from 'sanitize-html'
import {
  Accordion,
  BasicCollapsible,
  Column,
  Divider,
  GridContainer,
  Row,
  H2,
  UncustomizableMarkup,
  BoundedContent,
} from '@vp/swan'
import { faqData, pageCategoryPageV3 } from '../types/Contentful/ContentfulTypes'
import { filterAndMapFAQs } from '../utils/faq/faqUtils'
import { DebugPageSections } from './debugComponents/DebugPageSections'

export interface FaqProps {
  frequentlyAskedQuestions: faqData[];
  title: string;
}

const Faq: React.FC<FaqProps> = ({ frequentlyAskedQuestions, title }) => {
  const { expandedCollapsibles, collapsibles } = useMemo(() => {
    const expandedCollapsibles: { [key: string]: boolean } = {}
    const collapsibles = filterAndMapFAQs(
      frequentlyAskedQuestions,
      (faq, index) => {
        const collapsibleId = `faq-${index}`
        expandedCollapsibles[collapsibleId] = index === 0
        return (
          <BasicCollapsible
            collapsibleId={collapsibleId}
            heading={faq.question}
            key={collapsibleId}
          >
            <UncustomizableMarkup
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(faq.answerHTML),
              }}
            />
          </BasicCollapsible>
        )
      }
    )
    return { expandedCollapsibles, collapsibles }
  }, [frequentlyAskedQuestions])

  return (
    <>
      <BoundedContent>

        <GridContainer>
          <Row>
            <Column data-testid='faq' span={12}>
              <H2 fontSize='x2large' mb='7'>
                {title}
              </H2>
              <Accordion defaultExpandedCollapsibles={expandedCollapsibles}>
                {collapsibles}
              </Accordion>
              <Divider />
            </Column>
          </Row>
        </GridContainer>
      </BoundedContent>
      <DebugPageSections rawSectionData={{ frequentlyAskedQuestions, title }} resolvedSectionData={{ frequentlyAskedQuestions, title }} />
    </>

  )
}

const showFAQSection = (pageCategoryPageV3: pageCategoryPageV3) => {
  return (
    pageCategoryPageV3.faqTitle &&
    pageCategoryPageV3.frequentlyAskedQuestions?.length > 0
  )
}

export { showFAQSection }

export default Faq
