import {
  Callout,
  Link,
  StandardTile,
  StandardTileContents,
  StandardTileDescription,
  StandardTileImage,
  StandardTileName,
  StandardTileOverlay,
  StandardTilePrice,
  StandardTileSeparatelyClickableContents,
  useScreenClass,
  Typography,
  FlexBox,
  BasicResponsiveImage, RatingsStars
} from '@vp/swan'

import { Document } from '@contentful/rich-text-types'
import { CampaignCallout } from '@vp/campaign-callouts'
import { CouponCallout } from '@vp/coupon-callout'
import { useStyles } from '@vp/ubik-context'
import { ProductFavoriteHeart } from '@vp/favorites-heart'
import { PriceDisplay } from '@vp/price-platform'

import ProductTileLongDescription from './ProductTileLongDescription'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { stylesheet } from '../styles/ProductTile.scss'
import { trackClickEvent } from '../utils/tracking/tracking'
import { useAppContext } from '../state/AppContext'
import { CalloutSkin, CalloutVariant } from '../types/GoodBetterBest'

import { reviewData } from '../types/Contentful/ContentfulTypes'
import { ProductTileColorSwatches } from './ProductTileColorSwatches'
import { MSXAttibuteValue } from '../types/Msx'
import { showProductHeart } from '../utils/ProductTile/productTile'

export interface ProductTileProps {
  name: string;
  description?: string;
  image?: {
    altTextOverride?: string;
    url?: string;
  };
  linkUrl?: string;
  callout?: string;
  calloutSkin?: CalloutSkin;
  review?: reviewData;
  calloutVariant?: CalloutVariant;
  layout: 'vertical' | 'horizontal';
  productTileLongDescription?: Document;
  disabled: boolean;
  index: number;
  pageZone: string;
  mpvId?: string;
  productId?: string;
  imageRef?: any;
  showPrice: boolean;
  enableCampaignCallouts: boolean;
  colorInformation?: MSXAttibuteValue[];
  colorSwatchCount: number;
  version?: number;
}

export const ProductTile: React.FC<ProductTileProps> = (props: ProductTileProps) => {
  useStyles(stylesheet)
  const { merchandisingCategoryId, auth } = useAppContext()
  const {
    name,
    description,
    productTileLongDescription,
    linkUrl,
    image,
    callout,
    calloutSkin,
    calloutVariant,
    layout,
    disabled,
    index,
    pageZone,
    mpvId,
    productId,
    imageRef,
    showPrice,
    enableCampaignCallouts,
    review,
    colorInformation,
    colorSwatchCount,
    version
  } = props
  const isMobile = useScreenClass() === 'xs'
  const [isAuthReady, setIsAuthReady] = useState(false)

  useEffect(() => {
    if (auth?.getIdentity()?.accessToken) {
      setIsAuthReady(true)
    }
  }, [auth])
  return (
    <StandardTile
      className={classNames({
        'tile-disabled': disabled,
      })}
      layout={layout}
      mb={isMobile && layout !== 'vertical' ? 0 : '5'}
      imageWidth='standard'
      onClick={() => {
        trackClickEvent({
          categoryId: merchandisingCategoryId,
          pageZone,
          ctaValue: mpvId || name,
          destinationUrl: linkUrl!,
          index,
        })
      }}
    >
      <StandardTileOverlay>
        <FlexBox flexDirection='column' gap='2'>
          {callout && (
            <Callout skin={calloutSkin} variant={calloutVariant} style={{ width: 'fit-content' }}>
              {callout}
            </Callout>
          )}
          {enableCampaignCallouts && <CampaignCallout product={mpvId ?? ''} />}
        </FlexBox>
        {showProductHeart(productId, version, name, mpvId, isAuthReady) && (
          <ProductFavoriteHeart
            productKey={productId!}
            productVersion={version!}
            selectedOptions={{}}
            productName={name!}
            mpvId={mpvId!}
            pageZone={pageZone}
            productPosition={index}
          />
        )}
      </StandardTileOverlay>
      <StandardTileImage
        className={classNames({
          'tile-disabled-image': disabled,
        })}
      >
        <Link
          href={linkUrl}
          skin='unstyled'
          data-section={pageZone}
          data-position={index}
          data-translation={mpvId}
          title={name}
        >
          <BasicResponsiveImage
            aspectRatio={1}
            src={image?.url}
            ref={imageRef}
            alt={image?.altTextOverride || ''}
          />
        </Link>
      </StandardTileImage>
      <StandardTileContents
        className={classNames({
          'tile-disabled-contents': disabled,
        })}
      >
        {colorInformation && colorInformation.length > 1 && (
          <ProductTileColorSwatches
            colorInformation={colorInformation}
            colorSwatchCount={colorSwatchCount}
          />
        )}
        <Link
          href={linkUrl}
          skin='unstyled'
          data-section={pageZone}
          data-position={index}
          data-translation={mpvId}
        >
          <StandardTileName>{name}</StandardTileName>
          {productTileLongDescription
            ? (
              <StandardTileDescription>
                <ProductTileLongDescription productTileDescription={productTileLongDescription} />
              </StandardTileDescription>
              )
            : (
              <StandardTileDescription>{description}</StandardTileDescription>
              )}
          {review && (
            <FlexBox alignItems='center' flexWrap='wrap' flexDirection='row'>
              <RatingsStars ratingValue={review.averageRating} aria-label={review.reviewedByNCustomersLabel} />
              <Typography textColor='subtle' paddingLeft='2'>
                ({review.reviewsCount})
              </Typography>
            </FlexBox>
          )}
          {showPrice && mpvId && (
            <StandardTilePrice>
              <PriceDisplay mpvId={mpvId} />
            </StandardTilePrice>
          )}
        </Link>
        {mpvId && (
          <StandardTileSeparatelyClickableContents>
            <CouponCallout mpvId={mpvId} />
          </StandardTileSeparatelyClickableContents>
        )}
      </StandardTileContents>
    </StandardTile>
  )
}
