import React from 'react'
import { Button } from '@vp/swan'
import { useStyles } from '@vp/ubik-context'
import UploadFlow from '@vp/upload-flow'

import PageConfig from '../../utils/pageConfig'
import PricingContext from '../../services/serviceClients/PricingContext'
import { useAppContext } from '../../state/AppContext'
import { MsxMpv } from '../../types/Msx'
import { REQUESTOR } from '../../constants'
import { stylesheet } from '../../styles/StandardHeroContent.scss'

export interface StandardHeroUploadFlowCTAProps {
  pageZone: string;
  uploadFlowMpv?: MsxMpv;
  totalCtaCount: number;
}

const StandardHeroUploadFlowCTA: React.FC<StandardHeroUploadFlowCTAProps> = ({
  pageZone,
  uploadFlowMpv,
  totalCtaCount,
}) => {
  useStyles(stylesheet)
  const { merchandisingCategoryId, locale, vatInclusivity } = useAppContext()

  return uploadFlowMpv
    ? (
      <div className='standard-hero-content-upload-cta'>
        <UploadFlow
          vatInc={vatInclusivity}
          analyticsPageData={{
            pageSection: 'Category Page',
            pageStage: 'Discover',
            pageName: `${merchandisingCategoryId}:Category Page`,
            productName: uploadFlowMpv.name,
            coreProductId: uploadFlowMpv.coreProductId,
          }}
          tenant={PageConfig.tenant}
          locale={locale}
          pricingContextString={PricingContext.getPricingContext()}
          mpvId={uploadFlowMpv.mpvId}
          loader={
            <UploadFlowButton disabled totalCtaCount={totalCtaCount} pageZone={pageZone} />
        }
          productKey={uploadFlowMpv.coreProductId}
          productName={uploadFlowMpv.name}
          productVersion={uploadFlowMpv.version}
          quantity={uploadFlowMpv.quantities?.default}
          requestor={REQUESTOR}
        >
          <UploadFlowButton totalCtaCount={totalCtaCount} pageZone={pageZone} />
        </UploadFlow>
      </div>
      )
    : (
      <></>
      )
}

const UploadFlowButton = (props: {
  totalCtaCount: number;
  pageZone: string;
  disabled?: boolean;
}) => {
  const { totalCtaCount, pageZone, disabled } = props

  const { dictionaryEntries } = useAppContext()

  return (
    <Button
      key='standard-hero-upload-design-cta'
      className='standard-hero-cta'
      skin={totalCtaCount === 1 ? 'primary' : 'secondary'}
      data-section={pageZone}
      data-translation='Upload Design CTA'
      component='span'
      disabled={disabled}
    >
      {dictionaryEntries.uploadDesign}
    </Button>
  )
}

export default StandardHeroUploadFlowCTA
