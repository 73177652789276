import React from 'react'
import { Button, Link } from '@vp/swan'
import { CtaWithUrl } from '../../types/StandardHeroContent'
import { ContentfulEntryLink } from '../../types/Contentful/ContentfulTypes'
import { getCtaSkinColor } from '../../utils/standardHeroContents/standardHeroContents'
import { trackClickEvent } from '../../utils/tracking/tracking'

interface StandardHeroCTAProps {
  index: number;
  cta: CtaWithUrl;
  referencedFields: Record<string, ContentfulEntryLink>;
  merchandisingCategoryId: string;
  pageZone: string;
  ctaUrlsLength: number;
  skin?: React.ComponentPropsWithoutRef<typeof Button>['skin'];
}

const StandardHeroCTA: React.FC<StandardHeroCTAProps> = ({
  cta,
  index,
  merchandisingCategoryId,
  pageZone,
  ctaUrlsLength,
  skin,
}) => {
  const getSizeVariant = () => {
    return ctaUrlsLength === 1 ? undefined : 'mini'
  }

  return (
    <Link
      skin='unstyled'
      href={cta!.targetUrl}
      data-section={pageZone}
      data-translation={`CTA ${index + 1}`}
    >
      <Button
        key={`standard-hero-cta-${index}`}
        component='div'
        className='standard-hero-cta'
        skin={skin ?? getCtaSkinColor(ctaUrlsLength, index)}
        size={getSizeVariant()}
        onClick={() => {
          trackClickEvent({
            categoryId: merchandisingCategoryId,
            pageZone,
            ctaValue: `CTA ${index + 1}`,
            destinationUrl: cta!.targetUrl,
          })
        }}
      >
        {cta!.text}
      </Button>
    </Link>
  )
}

export default StandardHeroCTA
