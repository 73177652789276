import {
  AlertBox,
  AlertBoxDismissButton,
  FlexBox,
  Icon,
  Link,
  Typography,
} from '@vp/swan'
import { useState } from 'react'

import DebugInfoUtil from './DebugInfoUtil'
import { useAppContext } from '../../state/AppContext'

export const DebugPageInfo = () => {
  const { merchandisingCategoryId, spaceId, contentfulId } = useAppContext()
  const [alertVisible, setAlertVisible] = useState(false)
  const [dismissed, setDismissed] = useState(false)
  const pageContentfulLinkMaster = DebugInfoUtil.generateContentfulEntryLink(
    spaceId,
    contentfulId,
    'master'
  )
  const pageContentfulLinkDevelopment =
    DebugInfoUtil.generateContentfulEntryLink(
      spaceId,
      contentfulId,
      'development'
    )

  function handleDismiss () {
    setTimeout(() => {
      setAlertVisible(false)
      setDismissed(true)
    }, 2000)
  }

  async function writeClipboardText () {
    try {
      await navigator.clipboard.writeText(merchandisingCategoryId)
      setAlertVisible(true)
      handleDismiss()
    } catch (error) {
      console.error('Clipboard write failed: ', (error as Error).message)
    }
  }

  return (
    <FlexBox flexDirection='column' mr='6'>
      <Typography mb='2' mr='4' fontWeight='bold'>
        Page Information
      </Typography>
      <FlexBox flexDirection='column'>
        <Typography mb='2' mr='4' fontWeight='normal'>
          mcId: {merchandisingCategoryId}
          <Icon
            ml='4'
            iconType='saveAsCopy'
            size='standard'
            onClick={writeClipboardText}
          />
        </Typography>
        {alertVisible && (
          <AlertBox
            toast
            skin='positive'
            dismissed={dismissed}
            onRequestDismiss={() => {
              setDismissed(true)
            }}
          >
            Copied to clipboard!
            <AlertBoxDismissButton accessibleText='Dismiss alert' />
          </AlertBox>
        )}
        <Link href={pageContentfulLinkMaster} target='_blank' rel='noreferrer'>
          <Typography fontSize='small'>Contentful</Typography>
        </Link>
        <Link
          href={pageContentfulLinkDevelopment}
          target='_blank'
          rel='noreferrer'
        >
          <Typography fontSize='small'>
            Contentful Development (for devs only)
          </Typography>
        </Link>
      </FlexBox>
    </FlexBox>
  )
}
