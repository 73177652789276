import React, { useRef } from 'react'
import { Row, Column, Typography, FlexBox, Link, H2 } from '@vp/swan'
import { useAppContext } from '../state/AppContext'
import { getGalleryUrl } from '../utils/gallery/gallery'
import { Gallery } from '../types/Gallery'
import { trackClickEvent } from '../utils/tracking/tracking'
import { ContentfulSimpleLink } from '../types/Contentful/ContentfulTypes'
import { TemplateCollectionType } from '../types/TemplateConfiguration'
import { Translated } from '../types/Global'
import { DebugPageSections } from './debugComponents/DebugPageSections'
import { SkipToNextSectionLink } from './SkipToNextSectionLink'
import TemplateTile from './TemplateTile'
import { primaryContentExperimentVariationControl, primaryContentExperimentVariationTest2 } from '../constants/primaryContentTestData'
import { stylesheet } from '../styles/TemplateCollectionGrid.scss'
import { useStyles } from '@vp/ubik-context'

interface TemplateCollectionGridProps {
  configuration: TemplateCollectionType;
  galleries?: Gallery[];
  nextSectionId?: string;
  nextSectionTitle?: Translated;
  index?: number;
}

const TemplateCollectionGrid = (props: TemplateCollectionGridProps) => {
  useStyles(stylesheet)

  const { configuration, galleries, nextSectionTitle, nextSectionId, index } = props
  const { title, subtitle, cta, items } = configuration
  const { locale, sidebarEnabled, merchandisingCategoryId, referencedFields, isPagePartOfPrimarayContentExperiment, primaryContentExperimentVariation } =
    useAppContext()
  const imageRef = useRef()

  const ctaReferenceField =
    cta && (referencedFields[`${cta.contentful_id}`] as ContentfulSimpleLink)

  const ctaElement =
    cta && ctaReferenceField
      ? (
        <FlexBox justifyContent='flex-end' alignItems='flex-end'>
          <Typography fontSize='standard'>
            <Link
              href={ctaReferenceField.targetUrl}
              skin='cta'
              data-section='Template Collection Grid'
              data-translation={merchandisingCategoryId}
              onClick={() =>
                trackClickEvent({
                  categoryId: merchandisingCategoryId,
                  destinationUrl: ctaReferenceField.targetUrl,
                  ctaValue: 'CTA',
                  pageZone: 'Template Collection Grid',
                })}
            >
              {cta.text}
            </Link>
          </Typography>
        </FlexBox>
        )
      : undefined
  const templateCollectionGridTiles = items
    .map((item: any, index: number, items: any) => {
      if (!item || !item.link) {
        return <React.Fragment key={index} />
      }
      const { cloudinaryImage, text, contentful_id, actionId, actionFilters } =
        item.link
      const linkReferencedField = referencedFields[`${contentful_id}`] as ContentfulSimpleLink
      if (!cloudinaryImage?.cloudinaryUrl || !linkReferencedField?.targetUrl) {
        return null
      }
      const url: string =
        getGalleryUrl({ galleries, actionId, actionFilters, locale }) ||
        linkReferencedField?.targetUrl

      return (
        <TemplateTile
          link={url}
          imageUrl={cloudinaryImage.cloudinaryUrl}
          text={text}
          mpvId={merchandisingCategoryId}
          index={index + 1}
          imageRef={imageRef}
          sectionType='Template Collection Grid'
          itemsCount={items?.length}
          sectionTypeCount={configuration.sectionTypeCount ?? 1}
          key={index}
        />
      )
    })
    .filter((slide) => !!slide)

  if (!templateCollectionGridTiles.length) {
    return <></>
  }

  // 6 tiles for both test variations
  // usual case for control variation or outside the variation
  const desktopTilePerRow = isPagePartOfPrimarayContentExperiment &&
    primaryContentExperimentVariation !== primaryContentExperimentVariationControl
    ? 6
    : templateCollectionGridTiles.length <= 4
      ? 4
      : 6

  const desktopColSpan = (12 / desktopTilePerRow) as 4 | 3 | 2

  // 3 tiles for both the test variations
  // 2 tiles for control variation or outside the variation
  const mobileTilePerRow = isPagePartOfPrimarayContentExperiment &&
    primaryContentExperimentVariation !== primaryContentExperimentVariationControl
    ? 3
    : 2
  const mobileColSpan = (12 / mobileTilePerRow) as 6 | 4
  return (
    <>
      {
      isPagePartOfPrimarayContentExperiment &&
        primaryContentExperimentVariation !== primaryContentExperimentVariationControl &&
        index === 2
        ? null
        : title && title.value && (
          <Row mb='5'>
            <Column span={10}>
              <H2 fontSize='x2large' mb={0}>
                {title.value}
              </H2>
            </Column>
          </Row>
        )
}
      <SkipToNextSectionLink
        nextSectionId={nextSectionId}
        nextSectionTitle={nextSectionTitle}
      />
      {isPagePartOfPrimarayContentExperiment &&
        primaryContentExperimentVariation !== primaryContentExperimentVariationControl &&
        index === 2
        ? null
        : ((subtitle && subtitle.value) || ctaElement) && (
          <Row>
            <Column span={sidebarEnabled ? 7 : 5}>
              {subtitle && subtitle.value && (
                <Typography>{subtitle.value}</Typography>
              )}
            </Column>
            <Column span={sidebarEnabled ? 5 : 7}>{ctaElement}</Column>
          </Row>
          )}
      <Row marginTop={isPagePartOfPrimarayContentExperiment && primaryContentExperimentVariation !== primaryContentExperimentVariationControl ? 0 : 7}>
        {templateCollectionGridTiles.map((tile, index) => {
          return (
            <Column
              span={desktopColSpan} spanXs={mobileColSpan} key={index}
              className={isPagePartOfPrimarayContentExperiment && primaryContentExperimentVariation === primaryContentExperimentVariationTest2
                ? 'primary-content-test-width-modifier'
                : ''}
            >
              {tile}
            </Column>
          )
        })}
      </Row>
      <DebugPageSections rawSectionData={configuration} />
    </>
  )
}

export default TemplateCollectionGrid
