import { useEffect } from 'react'
import { buildProductAnalyticsData } from '../utils/tracking/tracking'
import { TemplateConfigurationSection } from '../types/TemplateConfiguration'
import { ShortMsxMpv } from '../types/ShortMsxMpv'

type ProductListTrackingValues = {
  sections: TemplateConfigurationSection[];
  mpvs: ShortMsxMpv[];
}
export const useProductListTracking = (productListTrackingValues: ProductListTrackingValues) => {
  const { sections, mpvs } = productListTrackingValues
  const sendTrackTracking = () => {
    const tracking = (window as any).tracking as any
    if (tracking && tracking.track) {
      const splitLength = 25
      const productList = buildProductAnalyticsData({ sections, mpvs })
      for (let i = 0; i < productList.length; i += splitLength) {
        tracking.track('Product List Viewed', {
          label: 'Category page view',
          list_id: 'Category Page',
          products: productList.slice(i, i + splitLength),
        })
      }
    } else {
      setTimeout(() => sendTrackTracking(), 1000)
    }
  }

  useEffect(() => {
    sendTrackTracking()
  }, [])
}
