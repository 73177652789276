import { CTAButtonProps, DefaultContentfulBannerWrapperProps } from '@vp/banner-wrapper'
import { getGalleryData } from './simpleLink/simpleLink'

import { GalleryRequest } from '../services/serviceClients/BffClient'
import {
  PageHeaderType,
  SideBySideHeroType,
  TemplateCollectionType,
  HeroContent,
  NewBanner,
  ProductGridType,
  ProductGridItemType,
  ShortProductTileV2,
} from '../types/TemplateConfiguration'
import { GoodBetterBestSection } from '../types/GoodBetterBest'

export const getAllMpvIdsOnOnePage = (page: any) => {
  const mpvIdsSet = new Set()
  page?.referencedFields?.forEach((referencedField: any) => {
    if (isMpv(referencedField) && referencedField?.mpvId) {
      mpvIdsSet.add(referencedField.mpvId)
    } else if (
      isMpv(referencedField?.merchandisingElement) &&
      referencedField?.merchandisingElement?.mpvId
    ) {
      mpvIdsSet.add(referencedField.merchandisingElement.mpvId)
    }
  })

  return Array.from(mpvIdsSet)
}

export const getAllMcIdsOnOnePage = (page: any) => {
  const mcIdsSet = new Set()
  page?.referencedFields?.forEach((referencedField: any) => {
    if (isMerchCategory(referencedField) && referencedField?.mpvId) {
      mcIdsSet.add(referencedField.mpvId)
    } else if (
      isMerchCategory(referencedField?.merchandisingElement) &&
      referencedField?.merchandisingElement?.mpvId
    ) {
      mcIdsSet.add(referencedField.merchandisingElement.mpvId)
    }
  })

  return Array.from(mcIdsSet)
}

export const getBrowseOurDesignsMpvId = (templateConfiguration: any) => {
  const virtualSection = templateConfiguration?.sections?.find(
    (section: any) => section.type === 'virtual section'
  )

  if (virtualSection) {
    const browseOurDesignsMpvEntryId =
      virtualSection.value?.value?.browseOurDesignsMpv?.contentful_id
    const browseOurDesignsMpv = virtualSection.value?.localizedFields?.find(
      (r: any) => r?.contentful_id === browseOurDesignsMpvEntryId
    )

    if (browseOurDesignsMpv) {
      return browseOurDesignsMpv.mpvId
    }
  }

  return undefined
}

export const getNewTileMpvIds = (templateConfiguration: any) => {
  const mpvIds = new Set()
  for (
    let sectionNumber = 0;
    sectionNumber < templateConfiguration?.sections?.length || 0;
    sectionNumber++
  ) {
    const allSectionData = templateConfiguration?.sections?.[sectionNumber]
    if (
      [
        'product grid',
        'product grid horizontal XL',
        'product grid horizontal',
        'product lane',
      ].includes(allSectionData?.type)
    ) {
      const sectionData = allSectionData as ProductGridType
      if (sectionData?.items) {
        sectionData?.items
          .filter((productItem: ProductGridItemType) => {
            if ((productItem?.product as ShortProductTileV2)?.template) {
              return productItem
            }
            return false
          })
          .forEach((productItem) => {
            if (
              (productItem.product as ShortProductTileV2)?.template?.productsList?.selectedProduct
                ?.mpvId
            ) {
              mpvIds.add(
                (productItem.product as ShortProductTileV2)?.template?.productsList?.selectedProduct
                  ?.mpvId
              )
            }
          })
      }
    }
    if (['good better best'].includes(allSectionData?.type)) {
      const sectionData = allSectionData as GoodBetterBestSection
      const goodValue = sectionData?.goodValue?.template
      const betterValue = sectionData?.betterValue?.template
      const bestValue = sectionData?.bestValue?.template
      if (goodValue?.productsList?.selectedProduct?.mpvId) {
        mpvIds.add(goodValue.productsList?.selectedProduct?.mpvId)
      }
      if (betterValue?.productsList?.selectedProduct?.mpvId) {
        mpvIds.add(betterValue.productsList?.selectedProduct?.mpvId)
      }
      if (bestValue?.productsList?.selectedProduct?.mpvId) {
        mpvIds.add(bestValue.productsList?.selectedProduct?.mpvId)
      }
    }
  }
  return Array.from(mpvIds)
}

export const getUploadFlowMpvId = (templateConfiguration: any) => {
  const virtualSectionUploadFlowMpvId = getUploadFlowMpvIdFromVirtualSection(templateConfiguration)
  const heroUploadFlowMpvId = getUploadFlowMpvIdFromHero(templateConfiguration)
  const sideBySideUploadFlowMpvId = getUploadFlowMpvIdFromSideBySide(templateConfiguration)
  const promoBannerUploadFlowMpvId = getUploadFlowMpvIdFromPromoBanner(templateConfiguration)

  return (
    virtualSectionUploadFlowMpvId ??
    heroUploadFlowMpvId ??
    sideBySideUploadFlowMpvId ??
    promoBannerUploadFlowMpvId
  )
}

const getUploadFlowMpvIdFromVirtualSection = (templateConfiguration: any) => {
  const virtualSection = templateConfiguration?.sections?.find(
    (section: any) => section.type === 'virtual section'
  )

  if (virtualSection) {
    const uploadFlowMpvEntryId = virtualSection.value?.value?.uploadYourDesignMpv?.contentful_id
    const uploadFlowMpv = virtualSection.value?.localizedFields?.find(
      (l: any) => l?.contentful_id === uploadFlowMpvEntryId
    )

    if (uploadFlowMpv) {
      return uploadFlowMpv.mpvId
    }
  }

  return undefined
}

export const getNewBannerUploadFlow = (bannerProps: DefaultContentfulBannerWrapperProps[]) => {
  for (const banner of bannerProps || []) {
    const ctaButton = banner?.ctaButton
    if (Array.isArray(ctaButton)) {
      for (const cta of ctaButton || []) {
        if (cta?.gmpvUploadButton?.mpvId) {
          return cta.gmpvUploadButton.mpvId
        }
      }
    } else {
      if ((ctaButton as CTAButtonProps)?.gmpvUploadButton?.mpvId) {
        return (ctaButton as CTAButtonProps).gmpvUploadButton?.mpvId
      }
    }
  }
  return undefined
}

const getUploadFlowMpvIdFromHero = (templateConfiguration: any) => {
  const heroes = templateConfiguration?.sections?.filter(
    (section: any) => section.type === 'page header' || section.type === 'section header'
  ) as PageHeaderType[]

  for (const hero of heroes || []) {
    for (const header of hero.headers || []) {
      const newBanner = (header as NewBanner).banner
      if (!newBanner) {
        const HeroHeader = header as HeroContent
        if (HeroHeader?.uploadFlowMpv?.mpvId) {
          return HeroHeader?.uploadFlowMpv.mpvId
        }
      } else {
        return getNewBannerUploadFlow(newBanner.bannerTemplate)
      }
    }
  }

  return undefined
}

const getUploadFlowMpvIdFromSideBySide = (templateConfiguration: any) => {
  const heroes = templateConfiguration?.sections?.filter(
    (section: any) =>
      section.type === 'side by side page header' || section.type === 'side by side hero'
  ) as SideBySideHeroType[]

  for (const hero of heroes || []) {
    if (hero.content?.side1.uploadFlowMpv?.mpvId) {
      return hero.content.side1.uploadFlowMpv.mpvId
    } else if (hero.content?.side2.uploadFlowMpv?.mpvId) {
      return hero.content.side2.uploadFlowMpv.mpvId
    } else {
      if (hero.banner?.bannerTemplate) {
        const mpvId = getNewBannerUploadFlow(hero.banner.bannerTemplate)
        if (mpvId) {
          return mpvId
        }
      }
    }
  }

  return undefined
}

const getUploadFlowMpvIdFromPromoBanner = (templateConfiguration: any) => {
  const promoBanners = templateConfiguration?.sections?.filter(
    (section: any) => section.type === 'promo banner'
  )

  for (const promoBanner of promoBanners || []) {
    if (promoBanner?.uploadFlowMpv?.mpvId) {
      return promoBanner?.uploadFlowMpv.mpvId
    } else {
      const newBanner = promoBanner.banner?.bannerTemplate
      const mpvId = getNewBannerUploadFlow(newBanner)
      if (mpvId) {
        return mpvId
      }
    }
  }

  return undefined
}

export const getAllGalleryRequestsOnOnePage = (
  templateConfiguration: any,
  buildLocale: string
): GalleryRequest[] => {
  const templateCollectionSectionGallery = getGalleriesFromTemplateCollectionSection(
    templateConfiguration,
    buildLocale
  )
  const templateLaneGallery = getGalleryFromTemplateLane(templateConfiguration, buildLocale)

  const galleries = templateCollectionSectionGallery
  if (templateLaneGallery) {
    galleries.push(templateLaneGallery)
  }

  return galleries.map(sanitizeGalleryRequest)
}

const getGalleriesFromTemplateCollectionSection = (
  templateConfiguration: any,
  buildLocale: string
): GalleryRequest[] => {
  const galleries: GalleryRequest[] = []
  const templateCollectionSection = templateConfiguration?.sections?.filter(
    (section: any) =>
      section.type === 'template collection lane' || section.type === 'template collection grid'
  )

  templateCollectionSection?.forEach((section: TemplateCollectionType) => {
    if (section?.enabled) {
      section?.items?.forEach((item: any) => {
        const { actionId, actionFilters } = item?.link || {}
        const galleryData = getGalleryData(actionId, actionFilters)
        if (galleryData) {
          const { categories, productKey } = galleryData
          galleries.push({
            locale: buildLocale,
            selectedGallery: {
              productKey,
            },
            selectedCategories: categories.map((category: string) => ({ id: category })),
          })
        }
      })
    }
  })

  return galleries
}

const getGalleryFromTemplateLane = (
  templateConfiguration: any,
  buildLocale: string
): GalleryRequest | undefined => {
  const templateLaneSection = templateConfiguration?.sections?.find(
    (section: any) => section.type === 'template lane'
  )

  if (templateLaneSection?.enabled) {
    const { defaultLocale, galleryInformation } = templateLaneSection || {}

    if (defaultLocale && galleryInformation) {
      if (galleryInformation?.[`${buildLocale}`]) {
        const gallery = galleryInformation[`${buildLocale}`]
        return buildGalleryRequest(gallery, buildLocale)
      } else {
        const gallery = galleryInformation?.[`${defaultLocale}`]
        return buildGalleryRequest(gallery, defaultLocale)
      }
    }
  }
  return undefined
}

const isMpv = (field: any) => {
  return field?.__typename === 'globalMerchandisingProductView'
}

const isMerchCategory = (field: any) => {
  return field?.__typename === 'merchandisingCategory'
}

const sanitizeGalleryRequest = (galleryRequest: GalleryRequest) => {
  const newGalleryRequest = { ...galleryRequest }
  if (newGalleryRequest.selectedCategories?.length === 0) {
    delete newGalleryRequest.selectedCategories
  }
  return newGalleryRequest
}

const buildGalleryRequest = (gallery: any, locale: string) => {
  if (gallery) {
    return {
      locale,
      selectedGallery: {
        productKey: gallery.selectedGallery?.productKey,
      },
      selectedCategories: [
        ...(gallery.selectedCategories?.map((category: any) => ({ id: category.id })) || []),
      ],
    }
  }
}

export const formatLocale = (locale: string, upperCaseCountryCode?:boolean) => {
  const localeParts = locale.split('-')
  const languageCode = localeParts[0].toLocaleLowerCase()
  const countryCode = upperCaseCountryCode
    ? localeParts[1].toUpperCase()
    : localeParts[1].toLowerCase()

  return `${languageCode}-${countryCode}`
}
