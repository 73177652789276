import { useEffect, useState } from 'react'
import { CartTenantDetails } from '@vp/cart-client'
import { useAppContext } from '../../state/AppContext'
import { getFilteredOrderDetails } from './reorderUtils'
import { formatLocale } from '../../utils/buildHelpers'

// Shared module-level variables
let sharedPromise: Promise<void> | null = null
let cachedData: {
  hasReorderableItems: boolean
  shopperId: string
  filteredOrder: Record<string, any>
} = {
  hasReorderableItems: false,
  shopperId: '',
  filteredOrder: {},
}

const useFetchOrders = () => {
  const { auth, vistacartTenant, locale } = useAppContext()

  const [hasReorderableItems, setHasReorderableItems] = useState(cachedData.hasReorderableItems)
  const [shopperId, setShopperId] = useState(cachedData.shopperId)
  const [filteredOrder, setFilteredOrder] = useState(cachedData.filteredOrder)

  useEffect(() => {
    if (!auth?.isSignedIn()) return

    if (!sharedPromise) {
      sharedPromise = (async () => {
        const userId = auth.getCanonicalId() || ''
        if (!userId) return

        const cacheKey = `filteredOrder_${userId}_${formatLocale(locale, false)}`
        const cachedRaw = localStorage.getItem(cacheKey)
        const expiry = localStorage.getItem(`${cacheKey}_expiry`)

        if (cachedRaw && expiry && Date.now() < parseInt(expiry, 10)) {
          cachedData = {
            hasReorderableItems: true,
            shopperId: userId,
            filteredOrder: JSON.parse(cachedRaw),
          }
        } else {
          const order = await getFilteredOrderDetails(
            auth,
            userId,
            locale,
            (vistacartTenant as CartTenantDetails).tenantId
          )

          if (order) {
            localStorage.setItem(cacheKey, JSON.stringify(order))
            localStorage.setItem(`${cacheKey}_expiry`, (Date.now() + 60 * 60 * 1000).toString())
            cachedData = {
              hasReorderableItems: true,
              shopperId: userId,
              filteredOrder: order,
            }
          }
        }
      })()
    }

    sharedPromise.then(() => {
      setHasReorderableItems(cachedData.hasReorderableItems)
      setShopperId(cachedData.shopperId)
      setFilteredOrder(cachedData.filteredOrder)
    })
  }, [auth?.isSignedIn(), locale, vistacartTenant])

  return { hasReorderableItems, shopperId, filteredOrder }
}

export default useFetchOrders
