import React from 'react'
import {
  StandardTileSwatches,
  ColorSwatches,
  ColorSwatch,
  ColorSwatchesMore,
  StandardTileSeparatelyClickableContents,
  useScreenClass,
} from '@vp/swan'
import { MSXAttibuteValue } from '../types/Msx'
import ProductTileCollectionUtil from '../utils/productTileCollection/productTileCollection'

interface ProductTileColorSwatchesProps {
  colorInformation: MSXAttibuteValue[];
  colorSwatchCount: number;
}

export const ProductTileColorSwatches = ({
  colorInformation,
  colorSwatchCount,
}: ProductTileColorSwatchesProps) => {
  const screenClass = useScreenClass()

  const NUMBER_OF_COLORS_TO_DISPLAY = ProductTileCollectionUtil.getNumberOfColorsToDisplay(
    screenClass,
    colorSwatchCount
  )
  const colorsToDisplay = colorInformation.slice(0, NUMBER_OF_COLORS_TO_DISPLAY)
  const colorsRemaining = colorInformation.length - NUMBER_OF_COLORS_TO_DISPLAY

  return (
    <StandardTileSeparatelyClickableContents>
      <StandardTileSwatches>
        <ColorSwatches>
          {colorsToDisplay.length > 1 &&
            colorsToDisplay.map((color, key) => {
              const primaryColor = color.key?.split('/')[0]
              const secondaryColor = color.key?.split('/')[1]

              return (
                <ColorSwatch
                  data-testid='color-swatch'
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                  accessibleText={color.merchandisingName}
                  key={key}
                />
              )
            })}
          {colorsRemaining > 0 && <ColorSwatchesMore>+{colorsRemaining}</ColorSwatchesMore>}
        </ColorSwatches>
      </StandardTileSwatches>
    </StandardTileSeparatelyClickableContents>
  )
}
