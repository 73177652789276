import React from 'react'
import { socialCurationsData } from '../types/Contentful/ContentfulTypes'
import { Column, GridContainer, H2, Row, Typography } from '@vp/swan'
import { SocialNative } from '@vp/social-native-component'
import { useAppContext } from '../state/AppContext'
import { TrackingConfiguration } from '../types/Global'
import { DebugPageSections } from './debugComponents/DebugPageSections'

interface IProps {
  socialCurationsTitle?: string;
  socialCurationsSubtitle?: string;
  data: socialCurationsData;
}

export const SocialCurations = (props: IProps): React.JSX.Element | null => {
  const { locale, merchandisingCategoryId } = useAppContext()

  const { socialCurationsTitle, socialCurationsSubtitle, data } = props

  const analyticsData: TrackingConfiguration = {
    pageName: `${merchandisingCategoryId}:Category Page`,
    pageSection: 'Category Page',
    pageStage: 'Discover',
  }

  return (
    <>
      <GridContainer>
        <Row mb='7'>
          <Column span={5} spanXs={12}>
            <H2 fontSize='x2large'>{socialCurationsTitle}</H2>
          </Column>
          <Column span={12} spanXs={12}>
            <Typography>{socialCurationsSubtitle}</Typography>
          </Column>
        </Row>
        <Row key={1}>
          <Column span={12}>
            <SocialNative
              widgetData={data}
              analyticsData={analyticsData}
              locale={locale}
            />
          </Column>
        </Row>
      </GridContainer>
      <DebugPageSections rawSectionData={{ socialCurationsTitle, socialCurationsSubtitle, data }} />

    </>
  )
}

const showOlapic = (data?: socialCurationsData) =>
  !!data?.apiKey && !!data.widget.instanceId && !!data.source.id

export { showOlapic }

export default SocialCurations
