import React, { useRef } from 'react'
import {
  Carousel,
  CarouselSlide,
  Column,
  FlexBox,
  H2,
  Link,
  Row,
  Typography,
  useScreenClass,
} from '@vp/swan'
import { ProductTile } from './ProductTile'
import ProductTileCollectionUtil from '../utils/productTileCollection/productTileCollection'
import { Translated } from '../types/Global'
import { useAppContext } from '../state/AppContext'
import {
  isProductTileAvailable,
  isProductTileInStock,
  resolveProductTileCallout,
} from '../utils/ProductTile/productTile'
import {
  ProductGridLaneError,
  renderProductDebugError,
} from './errorComponents/ProductGridLaneError'
import { getAccessibleTextForDots } from '../utils/accessibility/accessibility'
import { DebugPageSections } from './debugComponents/DebugPageSections'
import { ProductGridType } from '../types/TemplateConfiguration'
import { SkipToNextSectionLink } from './SkipToNextSectionLink'
import { ProductTileV2 } from './ProductTileV2'
import { BFFPageData } from '../services/serviceClients/BffClient'

export interface ProductLaneProps {
  section: ProductGridType;
  pageDataFromBFF: BFFPageData;
  sectionNumber: number;
  nextSectionId?: string;
  nextSectionTitle?: Translated;
}

export const ProductLane = ({
  section,
  pageDataFromBFF,
  sectionNumber,
  nextSectionId,
  nextSectionTitle,
}: ProductLaneProps) => {
  const {
    enableCampaignCallouts,
    sidebarEnabled,
    referencedFields,
    reviewsData,
    dictionaryEntries,
    debugMode,
    locale
  } = useAppContext()
  const imageRef = useRef()
  const screenClass = useScreenClass()
  const isMobile = screenClass === 'xs'
  // @ts-ignore
  const arrowVerticalPosition = imageRef.current?.clientHeight && imageRef.current?.clientHeight > 0 ? `${Math.round(imageRef.current.clientHeight / 2)}px` : undefined

  const model = ProductTileCollectionUtil.getModel(
    section,
    pageDataFromBFF,
    referencedFields,
    locale
  )

  if (!model.tiles.length || model.tiles.length === 0) {
    return <></>
  }

  let tilesCount = model.tiles.length
  model.tiles.forEach((tile) => {
    if (!debugMode && !isProductTileAvailable(tile)) {
      tilesCount--
    }
  })

  const desktopTilePerRow = ProductTileCollectionUtil.getNumberOfProductTilesPerRow(
    sidebarEnabled,
    section.type,
    tilesCount
  )

  // The slidesToShow property now supports responsive values, helps prevent flicker on page load.
  const slidestoShowCount = {
    xs: 2.1,
    sm: desktopTilePerRow,
    md: desktopTilePerRow,
    lg: desktopTilePerRow,
    xl: desktopTilePerRow,
  }

  const { accessibleTextForNext, accessibleTextForPrevious } = dictionaryEntries
  const accessibleTextForDots = getAccessibleTextForDots(
    dictionaryEntries.accessibleTextForDots,
    model.tiles.length
  )
  const hideProductDescription = section.hideProductDescription
  const colorSwatchCount = ProductTileCollectionUtil.getMaxColorSwatchCountDesktop(
    desktopTilePerRow,
    sidebarEnabled
  )

  return (
    <>
      <Row>
        <Column span={sidebarEnabled ? 7 : 5}>
          <FlexBox alignItems='center' marginBottom='4' gap='4'>
            {model.title && <H2 fontSize='x2large'>{model.title.value}</H2>}
            {section.enableSeeAllCTA &&
              section.seeAllCta?.targetUrl &&
              dictionaryEntries.seeAllCtaText && (
                <Link skin='cta' marginBottom='2' href={section.seeAllCta?.targetUrl}>
                  {dictionaryEntries.seeAllCtaText}
                </Link>
            )}
          </FlexBox>
          <SkipToNextSectionLink
            nextSectionId={nextSectionId}
            nextSectionTitle={nextSectionTitle}
          />
          {model.subtitle && (
            <Typography fontSize='standard' marginBottom={7}>
              {model.subtitle.value}
            </Typography>
          )}
        </Column>
      </Row>
      <Carousel
        slidesToShow={slidestoShowCount}
        arrows={!isMobile}
        swipeToSlide
        gridGutters
        infinite={false}
        initialSlide={0}
        arrowVerticalPosition={arrowVerticalPosition}
        progressIndicator={isMobile ? 'dots' : undefined}
        accessibleTextForPrevious={accessibleTextForPrevious}
        accessibleTextForDots={accessibleTextForDots}
        accessibleTextForNext={accessibleTextForNext}
      >
        {model.tiles.map((tile, i) => {
          if (isProductTileAvailable(tile)) {
            const callout = resolveProductTileCallout(tile, dictionaryEntries.outOfStock)
            const tileProps = tile.product?.template
            const newTileMpvId = tileProps?.productsList?.selectedProduct?.mpvId
            return (
              <CarouselSlide key={i}>
                {tileProps
                  ? (
                    <ProductTileV2
                      name={tileProps?.tileDetails?.header?.value ?? ''}
                      description={
                      !hideProductDescription
                        ? tileProps?.tileDetails?.description?.value ?? ''
                        : ''
                    }
                      image={tileProps?.tileDetails?.image?.cloudinaryImage?.[0]}
                      linkUrl={tileProps?.tileDetails?.link?.href}
                      callout={callout.callout}
                      calloutSkin={callout.skin}
                      calloutVariant={callout.variant}
                      layout='vertical'
                      disabled={!isProductTileInStock(tile)}
                      index={i + 1}
                      imageRef={imageRef}
                      pageZone={`Product Lane ${sectionNumber}`}
                      mpvId={newTileMpvId}
                      review={reviewsData?.[newTileMpvId as string]}
                      productId={tileProps?.productKey}
                      showPrice={!!tile.showPrice}
                      enableCampaignCallouts={enableCampaignCallouts}
                      colorInformation={tile.colorInformation}
                      colorSwatchCount={colorSwatchCount}
                      version={tile.version}
                    />
                    )
                  : (
                    <ProductTile
                      name={tile.name ?? ''}
                      description={!hideProductDescription ? tile.description : ''}
                      image={tile.image}
                      linkUrl={tile.linkUrl}
                      callout={callout.callout}
                      calloutSkin={callout.skin}
                      calloutVariant={callout.variant}
                      layout='vertical'
                      disabled={!isProductTileInStock(tile)}
                      review={reviewsData?.[tile.mpvId as string]}
                      pageZone={`Product Lane ${sectionNumber}`}
                      productTileLongDescription={
                      !hideProductDescription ? tile.productTileLongDescription : undefined
                    }
                      mpvId={tile.mpvId}
                      productId={tile.productId}
                      index={i + 1}
                      imageRef={imageRef}
                      showPrice={!!tile.showPrice}
                      enableCampaignCallouts={enableCampaignCallouts}
                      colorInformation={tile.colorInformation}
                      colorSwatchCount={colorSwatchCount}
                      version={tile.version}
                    />
                    )}
              </CarouselSlide>
            )
          } else if (renderProductDebugError(tile)) {
            return (
              <CarouselSlide key={i}>
                <ProductGridLaneError
                  tile={tile}
                  index={i}
                  hideProductDescription={hideProductDescription}
                  colorSwatchCount={colorSwatchCount}
                />
              </CarouselSlide>
            )
          }
          return null
        })}
      </Carousel>
      <DebugPageSections rawSectionData={section} resolvedSectionData={model} />
    </>
  )
}
