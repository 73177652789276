import Cookies from 'js-cookie'

export interface PcxtCookie {
  market: string;
  vatInclusive: boolean;
}

export default class PcxtCookieProvider {
  static getVat (defaultVatIncl: boolean): boolean {
    const pcxt = this.getPcxtCookie()
    if (pcxt) {
      return pcxt.vatInclusive ?? defaultVatIncl
    }
    return defaultVatIncl
  }

  static getPcxtCookie (): PcxtCookie | undefined {
    const pcxtJson64 = Cookies.get('PCXT')
    if (pcxtJson64) {
      const pcxt = JSON.parse(atob(pcxtJson64)) as PcxtCookie
      return pcxt
    }
    return undefined
  }
}
