import { AlertBox, AlertBoxDismissButton } from '@vp/swan'

interface ReorderAddToCartFeedBackProps {
  feedBack: {
    message: string;
    type: string;
  }
  setDismissed: (dismissed: boolean) => void;
  dismissed: boolean;
}

export const ReorderAddToCartFeedback = (props: ReorderAddToCartFeedBackProps) => {
  const { feedBack, setDismissed, dismissed } = props
  if (feedBack.type === 'error' || feedBack.type === 'success') {
    return (
      <AlertBox
        skin={feedBack.type === 'success' ? 'positive' : 'error'}
        my={3}
        mt={4}
        onRequestDismiss={() => {
          setDismissed(true)
        }}
        dismissed={dismissed}
      >
        {feedBack.message}
        <AlertBoxDismissButton accessibleText='' />
      </AlertBox>
    )
  }
}
