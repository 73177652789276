import { MsxMpv } from '../../types/Msx'
import { Gallery } from '../../types/Gallery'
import { ShortMsxMpv } from '../../types/ShortMsxMpv'
import {
  CategoryVirtualSection,
  DesignServicesData,
  RawVirtualSectionData,
  UploadFlowTile,
  VirtualSectionTile,
} from '../../types/VirtualSection'
import { DesignServices } from '../../types/DesignServices'

export const isTileEnabled = (tile: VirtualSectionTile, requireMpv?: boolean): boolean => {
  return Boolean(
    tile &&
      tile.tileName?.value &&
      (!requireMpv || (tile.mpv && tile.mpv.mpvId && tile.mpv.externallyMarketable)) &&
      tile.url
  )
}

export const isUploadFlowTileEnabled = (uploadFlowTile: UploadFlowTile): boolean => {
  return Boolean(
    uploadFlowTile &&
      uploadFlowTile.mpv &&
      uploadFlowTile.mpv.mpvId &&
      uploadFlowTile.mpv.externallyMarketable &&
      uploadFlowTile.mpv.merchandisingDetails.filter(
        (merchandisingDetail) => merchandisingDetail.type === 'UploadFlow'
      ).length === 1
  )
}

export const buildVirtualSection = (
  data: RawVirtualSectionData,
  mpvs: ShortMsxMpv[],
  designServices: DesignServices | DesignServicesData,
  uploadFlowMpv?: MsxMpv,
  galleries?: Gallery[]
): CategoryVirtualSection => {
  const { value, localizedFields } = data
  const designServicesData = (designServices as DesignServicesData)?.data || designServices
  const browseOurDesignsMpvId = localizedFields.find(
    (field) => field.contentful_id === value.browseOurDesignsMpv?.contentful_id
  )?.mpvId
  const browseOurDesignsMpv = mpvs.find((mpv) => mpv.mpvId === browseOurDesignsMpvId)
  const browseOurDesignsUrl = galleries?.find(
    (gallery) => gallery.productKey === browseOurDesignsMpv?.coreProductId
  )?.url
  const browseOurDesignsTile = {
    tileName: localizedFields.find(
      (field) => field.contentful_id === value.browseOurDesignsHeader?.contentful_id
    ),
    tileDescription: localizedFields.find(
      (field) => field.contentful_id === value.browseOurDesignsSubheader?.contentful_id
    ),
    tileImage: localizedFields.find(
      (field) => field.contentful_id === value.browseOurDesignsImage?.contentful_id
    ),
    ctaText: localizedFields.find(
      (field) => field.contentful_id === value.browseOurDesignsCtaText?.contentful_id
    ),
    mpv: browseOurDesignsMpv,
    url: browseOurDesignsUrl,
  }

  const uploadYourDesignTile = {
    tileName: localizedFields.find(
      (field) => field.contentful_id === value.uploadYourDesignHeader?.contentful_id
    ),
    tileDescription: localizedFields.find(
      (field) => field.contentful_id === value.uploadYourDesignSubheader?.contentful_id
    ),
    tileImage: localizedFields.find(
      (field) => field.contentful_id === value.uploadYourDesignImage?.contentful_id
    ),
    ctaText: localizedFields.find(
      (field) => field.contentful_id === value.uploadYourDesignCtaText?.contentful_id
    ),
    mpv: uploadFlowMpv,
  }

  const designServicesUrl =
    designServicesData?.serviceConfig?.Standard?.status === 'Available'
      ? designServicesData?.urls?.hub
      : ''
  const designServicesTile = {
    tileName: localizedFields.find(
      (field) => field.contentful_id === value.designServicesHeader?.contentful_id
    ),
    tileDescription: localizedFields.find(
      (field) => field.contentful_id === value.designServicesSubheader?.contentful_id
    ),
    tileImage: localizedFields.find(
      (field) => field.contentful_id === value.designServicesImage?.contentful_id
    ),
    ctaText: localizedFields.find(
      (field) => field.contentful_id === value.designServicesCtaText?.contentful_id
    ),
    url: designServicesUrl,
  }

  return {
    browseOurDesignsTile,
    uploadYourDesignTile,
    designServicesTile,
  }
}
