import {
  ContentfulCloudinaryImage,
  ContentfulCloudinaryVideoMedia,
} from '../../types/Contentful/ContentfulTypes'
import React from 'react'
import { FluidImage } from '@vp/swan'

interface CloudinaryImageMediaProps {
  media?: ContentfulCloudinaryImage | ContentfulCloudinaryVideoMedia;
  mediaXs?: ContentfulCloudinaryImage | ContentfulCloudinaryVideoMedia;
  alt?: string;
}

export const CloudinaryImageMedia = (props: CloudinaryImageMediaProps) => {
  const { media, mediaXs, alt } = props

  if (!media && !mediaXs) {
    return null
  }

  const image = media as ContentfulCloudinaryImage
  const imageXs = mediaXs as ContentfulCloudinaryImage | undefined

  const imageSources: JSX.Element[] = []
  if (image) {
    imageSources.push(<ImageSource key='image-source' image={image} />)
  }

  if (imageXs) {
    imageSources.push(<XsImageSource key='xs-image-source' imageXs={imageXs} />)
  }

  if (imageSources.length > 0) {
    return (
      <picture>
        {imageSources}
        <FluidImage
          src={image?.cloudinaryImage?.fullWidthHeroCloudinaryUrl}
          alt={alt ?? image?.altTextOverride ?? ''}
        />
      </picture>
    )
  } else {
    return null
  }
}

interface XsImageSourceProps {
  imageXs?: ContentfulCloudinaryImage;
}

const XsImageSource = (props: XsImageSourceProps) => {
  return (
    <source
      media='(max-width: 767px)'
      srcSet={`${props.imageXs?.cloudinaryImage?.mobileCloudinaryUrl} 768w`}
      sizes='(max-width: 767px) 100vw'
    />
  )
}

interface ImageSourceProps {
  image?: ContentfulCloudinaryImage;
}

const ImageSource = (props: ImageSourceProps) => {
  return (
    <source
      media='(min-width: 767px)'
      srcSet={`${props.image?.cloudinaryImage?.fullWidthHeroCloudinaryUrl} 1920w`}
      sizes='(min-width: 767px) 100vw'
    />
  )
}
