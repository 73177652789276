import {
  BannerTextContainer,
  BannerText,
  BannerTitle,
  BannerDescription,
  BannerImage,
  BannerImageContainer,
  BannerLinks,
} from '@vp/swan'

import {
  getCtasWithUrl,
  getFontFamilyType,
  getFontFamilyWeight,
  isOfTypePageHeader,
} from '../../utils/standardHeroContents/standardHeroContents'

import { useAppContext } from '../../state/AppContext'
import { Translated } from '../../types/Global'
import {
  ContentfulCloudinaryImage,
  ContentfulCloudinaryVideoMedia,
} from '../../types/Contentful/ContentfulTypes'
import { Cta } from '../../types/StandardHeroContent'

import { BannerMedia } from '../BannerMedia'
import StandardHeroCTA from './StandardHeroCTA'
import StandardHeroUploadFlowCTA from './StandardHeroUploadFlowCTA'

export interface StandardHeroContentProps {
  type:
    | 'page header'
    | 'promo banner'
    | 'sub-category page header'
    | 'section header';
  title: Translated;
  fontFamily?: string;
  subtitle?: Translated;
  cta1?: Cta;
  cta2?: Cta;
  cta3?: Cta;
  uploadFlowMpv?: any;
  image?: ContentfulCloudinaryImage | ContentfulCloudinaryVideoMedia;
  imageXs?: ContentfulCloudinaryImage | ContentfulCloudinaryVideoMedia;
  sectionNumber?: number;
  fullWidthImage?: boolean;
  isH1?: boolean;
}

export const getPageZone = (sectionType: string, sectionNumber?: number) => {
  if (sectionType === 'page header') return 'Page Header'
  else if (sectionType === 'section header') { return `Section Header ${sectionNumber}` }
  return `Promo Banner ${sectionNumber}`
}

const StandardHeroContent = (props: StandardHeroContentProps) => {
  const {
    merchandisingCategoryId,
    referencedFields,
    uploadFlowMpv: serverSideUploadFlowMpv,
  } = useAppContext()
  const {
    type: sectionType,
    title,
    fontFamily,
    subtitle,
    cta1,
    cta2,
    cta3,
    uploadFlowMpv,
    image,
    imageXs,
    sectionNumber,
    isH1,
  } = props

  const pageZone = getPageZone(sectionType, sectionNumber)
  const ctasWithUrl = getCtasWithUrl(referencedFields, cta1, cta2, cta3)
  const isPageHeader = isOfTypePageHeader(sectionType)
  const fontFamilyType = getFontFamilyType(isPageHeader, fontFamily)
  const fontFamilyWeight = getFontFamilyWeight(isPageHeader, fontFamily)
  const fontFamilyProps: Record<string, string> = {}
  if (fontFamilyType) {
    fontFamilyProps.fontFamily = fontFamilyType
  }
  if (fontFamilyWeight) {
    fontFamilyProps.fontWeight = fontFamilyWeight
  }
  const fullUploadFlowMpv = uploadFlowMpv?.mpvId
    ? serverSideUploadFlowMpv
    : undefined
  const totalCtaCount = fullUploadFlowMpv
    ? ctasWithUrl.length + 1
    : ctasWithUrl.length

  const buildCTAs = () => {
    const ctaArray = ctasWithUrl.map((cta, i) => (
      <StandardHeroCTA
        key={`hero-cta-${i}`}
        index={i}
        cta={cta}
        referencedFields={referencedFields}
        merchandisingCategoryId={merchandisingCategoryId}
        pageZone={pageZone}
        ctaUrlsLength={ctasWithUrl.length}
      />
    ))

    if (fullUploadFlowMpv) {
      const uploadCta = (
        <StandardHeroUploadFlowCTA
          key='upload-flow-cta'
          pageZone={pageZone}
          uploadFlowMpv={fullUploadFlowMpv}
          totalCtaCount={totalCtaCount}
        />
      )
      ctaArray.splice(1, 0, uploadCta)
    }
    return ctaArray
  }
  return (
    <>
      <BannerTextContainer>
        <BannerText>
          <BannerTitle {...fontFamilyProps} component={isH1 ? 'h1' : 'div'}>
            {title?.value}
          </BannerTitle>
          {subtitle && (
            <BannerDescription>
              <>{subtitle.value}</>
            </BannerDescription>
          )}
          <BannerLinks>{buildCTAs()}</BannerLinks>
        </BannerText>
      </BannerTextContainer>
      <BannerImageContainer>
        <BannerImage>
          <BannerMedia media={image} mediaXs={imageXs} />
        </BannerImage>
      </BannerImageContainer>
    </>
  )
}

export default StandardHeroContent
