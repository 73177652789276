import {
  Column,
  FluidImage,
  GridContainer,
  H2,
  Link,
  Row,
  StandardTile,
  StandardTileImage,
  TabContent,
  TabHeader,
  Tabs,
  TabsContents,
  TabsHeaders,
  Typography,
} from '@vp/swan'
import React, { useRef } from 'react'
import {
  IMAGE_ALIGN,
  config,
  BASE_URL,
  ANCHOR_BAR_CONFIG,
  QUICK_VIEW_PATH,
} from './constants'
import { useAppContext } from '../../state/AppContext'
import { trackNavigationClicks, trackTabSelected } from '../../utils/tracking/tracking'
import { formatLocale } from '../../utils/buildHelpers'

// This is PM ask to replace " and " with " & "
const getHeaderText = (text: string = '') => {
  return text.replace(' and ', ' & ')
}

const DesignTemplate = () => {
  const { designTemplateExperimentData: data, locale } = useAppContext()
  const modifiedLocale = formatLocale(locale, true)
  const ref = useRef<HTMLDivElement>(null)
  const { merchandisingCategoryId } = useAppContext()
  const [selectedTabId, setSelectedTabId] = React.useState<string | null>('1')

  if (!data || !data.length) {
    return <>Something went wrong!</>
  }

  const { title, description, cta } = config[modifiedLocale as keyof typeof config]

  const getBrowseAllLink = (categoryKey?: string) => {
    const baseURL = BASE_URL[modifiedLocale as keyof typeof BASE_URL]
    if (categoryKey) {
      return `${baseURL}${
        QUICK_VIEW_PATH[modifiedLocale as keyof typeof QUICK_VIEW_PATH]
      }?categories=${categoryKey}`
    }
    return `${baseURL}${QUICK_VIEW_PATH[modifiedLocale as keyof typeof QUICK_VIEW_PATH]}`
  }

  const handleTabChange = (tabId: string | null) => {
    if (tabId) {
      setSelectedTabId(tabId)
      sessionStorage.setItem('designTemplateTabId', tabId)
    }
  }

  return (
    <GridContainer
      id={ANCHOR_BAR_CONFIG[modifiedLocale as keyof typeof ANCHOR_BAR_CONFIG].categorySectionSectionId}
      mb='9'
    >
      <Row>
        <Column span={12}>
          <H2 fontSize='x2large' marginBottom='5'>
            {title}
          </H2>
        </Column>
      </Row>
      <Row marginBottom='4'>
        <Column span={12}>
          <Typography>{description}</Typography>
        </Column>
      </Row>

      <Row>
        <Column span={12}>
          <Tabs
            selectedTabId={selectedTabId}
            onRequestTabChange={(requestedTabId) => {
              trackTabSelected({
                categoryId: merchandisingCategoryId,
                pageZone: 'designTemplate',
                tabIndex: requestedTabId,
              })
              handleTabChange(requestedTabId)
            }}
          >
            <TabsHeaders ref={ref}>
              {data.map((item: any, tabIndex: number) => (
                <TabHeader
                  tabId={`${tabIndex + 1}`}
                  key={`${tabIndex + 1}`}
                >
                  {getHeaderText(item.tabHeader)}
                </TabHeader>
              ))}
            </TabsHeaders>
            <TabsContents>
              {data.map((tab: any, tabIndex: number) => (
                <TabContent key={tab.tabHeader} tabId={`${tabIndex + 1}`}>
                  <GridContainer>
                    <Row>
                      {tab.templates.map((template: any, templateIndex: number) => (
                        <Column key={template.designId} span={3} spanXs={6}>
                          <StandardTile
                            skin='product'
                            onClick={() => {
                              trackNavigationClicks({
                                categoryId: merchandisingCategoryId,
                                pageZone: 'designTemplate',
                                destinationUrl: template.urlToQuickView,
                                tabIndex: tabIndex + 1,
                                templateIndex: templateIndex + 1,
                                designId: template.designId,
                              })
                            }}
                          >
                            <StandardTileImage
                              style={{
                                margin: 0,
                              }}
                            >
                              <FluidImage
                                src={template.image}
                                style={{
                                  verticalAlign: IMAGE_ALIGN,
                                }}
                              />
                              <Link
                                href={template.urlToQuickView}
                                covering
                                style={{
                                  verticalAlign: IMAGE_ALIGN,
                                }}
                              />
                            </StandardTileImage>
                          </StandardTile>
                        </Column>
                      ))}
                    </Row>
                  </GridContainer>
                  <Row>
                    <Column span={12}>
                      <Link
                        id='see-all-cta'
                        skin='cta'
                        marginBottom='2'
                        href={getBrowseAllLink(tab.categoryKey)}
                        onClick={() => {
                          trackNavigationClicks({
                            categoryId: merchandisingCategoryId,
                            pageZone: 'designTemplate',
                            destinationUrl: getBrowseAllLink(tab.categoryKey),
                            tabIndex: tabIndex + 1,
                            isDesignTemplateCTA: true,
                          })
                        }}
                      >
                        {cta}
                      </Link>
                    </Column>
                  </Row>
                </TabContent>
              ))}
            </TabsContents>
          </Tabs>
        </Column>
      </Row>
    </GridContainer>
  )
}

export default DesignTemplate
