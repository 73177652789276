import { useEffect } from 'react'
import {
  Column,
  Card,
  BoundedContent,
  GridContainer,
  Icon,
  Link,
  Row,
  Typography,
  StandardTile,
  StandardTileContents,
  StandardTileName,
  StandardTileDescription,
  useScreenClass,
} from '@vp/swan'
import { PriceDisplay } from '@vp/price-platform'

import { useAppContext } from '../../state/AppContext'
import reorderConstants from '../../constants/experiment/reorder.json'
import { trackClickEvent } from '../../utils/tracking/tracking'
import useFetchOrders from './useFetchOrders'
import ReorderCTAs from './ReorderCTAs'
import ProductPreview from './ProductPreview'

export const Reorder = () => {
  const { locale, merchandisingCategoryId } = useAppContext()
  const { filteredOrder, shopperId } = useFetchOrders()
  const isMobile = useScreenClass() === 'xs'

  const reorderStrings =
    reorderConstants[
      locale.toLocaleLowerCase() as keyof typeof reorderConstants
    ]

  const reorderCTAsContent = {
    buyNow: reorderStrings.buyNow,
    addToCart: reorderStrings.addToCart,
    editDesign: reorderStrings.editDesign,
    addToCartSuccess: reorderStrings.addToCartSuccess,
    addToCartFailure: reorderStrings.addToCartFailure,
    loading: reorderStrings.loading,
  }

  const pageDetails = {
    pageSection: `${merchandisingCategoryId}:Category Page`,
    pageStage: 'Category Page',
    pageName: 'Discover',
  }
  const pageZone = 'Reorder Section'

  const formatDate = (locale: string): string => {
    return new Intl.DateTimeFormat(locale, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(new Date())
  }

  const sendTrackTracking = () => {
    const tracking = (window as any).tracking as any
    if (tracking && tracking.track) {
      const productList = {
        product_id: filteredOrder.mpvId,
        name: filteredOrder.productData.defaultDisplayName || '',
        core_product_id: filteredOrder.productData.productKey || '',
        position: 1,
        list_section_id: 'Category Page: 2',
      }

      tracking.track('Product List Viewed', {
        label: pageZone,
        list_id: 'Category Page',
        products: [productList],
      })
    } else {
      setTimeout(() => sendTrackTracking(), 1000)
    }
  }

  useEffect(() => {
    if (filteredOrder.mpvId) {
      sendTrackTracking()
    }
  }, [filteredOrder.mpvId])

  return (
    <>
      {filteredOrder.orderNumber && (
        <BoundedContent paddingX={0} marginBottom={{ xs: 7, sm: 7, md: 8 }}>
          <Card bordered>
            <GridContainer
              marginLeft={{ xs: 0, sm: 3, md: 3 }}
              mr={3}
              mb={5}
              mt={3}
              padding={3}
            >
              <Row>
                <Column span={6} mb={{ xs: 7, sm: 7, md: 0 }}>
                  <Typography fontSkin='title-section' mb={4}>
                    {reorderStrings.sectionTitle}
                  </Typography>
                  <Typography fontSize='standard' mr={7}>
                    {reorderStrings.sectionDescription}
                  </Typography>
                  <Link
                    mb={6}
                    href={reorderStrings.viewOrderHistoryLink}
                    onClick={() =>
                      trackClickEvent({
                        categoryId: merchandisingCategoryId,
                        destinationUrl: reorderStrings.viewOrderHistoryLink,
                        ctaValue: 'View Order History',
                        pageZone,
                      })}
                  >
                    <Typography fontSize='standard' mt={5}>
                      {reorderStrings.viewOrderHistory}
                      <Icon
                        iconType='arrowRight'
                        size='standard'
                        skin='standard'
                        ml='between-icon-and-text'
                      />
                    </Typography>
                  </Link>
                </Column>
                <Column
                  span={6} mb={{ xs: 7, sm: 7, md: 0 }}
                  ml={{ xs: 7, sm: 0, md: 0 }}
                  mt={6}
                >
                  <StandardTile layout='horizontal'>
                    <ProductPreview
                      filteredOrder={filteredOrder}
                      productPreviewMessages={{
                        previous:
                          reorderStrings.previewProductAccessibleTextForPrevious,
                        next: reorderStrings.previewProductAccessibleTextForNext,
                        close: reorderStrings.close,
                      }}
                    />
                    <StandardTileContents pt={0}>
                      <StandardTileName>
                        <Typography fontSkin='title-item'>
                          {filteredOrder.productData.defaultDisplayName}
                        </Typography>
                      </StandardTileName>
                      <StandardTileDescription mb={4}>
                        <Typography>
                          {reorderStrings.quantity}:{' '}
                          {filteredOrder.originalOrderedQuantity}
                        </Typography>
                        <Typography textColor='subtle'>
                          <PriceDisplay
                            mpvId={filteredOrder.mpvId}
                            selections={filteredOrder.selections}
                            quantity={filteredOrder.originalOrderedQuantity}
                          />
                        </Typography>
                        <Typography textColor='subtle'>
                          {reorderStrings.lastOrdered}: {formatDate(locale)}
                        </Typography>
                      </StandardTileDescription>
                      {!isMobile && (
                        <ReorderCTAs
                          reorderCTAsContent={reorderCTAsContent}
                          pageZone={pageZone}
                          pageDetails={pageDetails}
                          filteredOrder={filteredOrder}
                          shopperId={shopperId}
                        />
                      )}
                    </StandardTileContents>
                  </StandardTile>
                </Column>
              </Row>
              {isMobile && (
                <Row>
                  <Column span={12}>
                    <ReorderCTAs
                      reorderCTAsContent={reorderCTAsContent}
                      pageZone={pageZone}
                      pageDetails={pageDetails}
                      filteredOrder={filteredOrder}
                      shopperId={shopperId}
                    />
                  </Column>
                </Row>
              )}
            </GridContainer>
          </Card>
        </BoundedContent>
      )}
    </>
  )
}
