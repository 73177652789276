import PageDataUtil from '../../utils/pageDataUtil/pageDataUtil'
import { useAppContext } from '../../state/AppContext'
import { PageHeaderType, SidebarType, SideBySideHeroType } from '../../types/TemplateConfiguration'
import PageHeader from './PageHeader'
import PageHeaderCarousel from './PageHeaderCarousel'
import { useExperimentsContext } from '../ExperimentsProvider'
import { TestSection } from '../../ab-tests/utils/TestSection'
import SideBySideHero from '../SideBySideHero'
import { BoundedContent } from '@vp/swan'
import { BFFPageData } from '../../services/serviceClients/BffClient'

interface PageHeaderContainerProps {
  configuration: PageHeaderType | SideBySideHeroType;
  pageDataFromBFF: BFFPageData;
  sidebar?: SidebarType;
}

export const PAGEHEADER_TYPES = [
  'page header',
  'sub-category page header',
  'side by side page header',
]

const getPageHeaderConfig = (templateConfiguration: any) =>
  (templateConfiguration?.sections || []).find((section: any) =>
    PAGEHEADER_TYPES.includes(section.type.toLowerCase())
  ) as PageHeaderType | SideBySideHeroType

const determineSectionAvailability = (
  section: PageHeaderType | SideBySideHeroType,
  locale: string
) => {
  if (
    section.specificLocales &&
    section.specificLocales.length > 0 &&
    !section.specificLocales.includes(PageDataUtil.sanitizeLocale(locale))
  ) {
    return false
  }

  return true
}

const PageHeaderContainer = (props: PageHeaderContainerProps) => {
  const { configuration, pageDataFromBFF, sidebar } = props
  const { locale } = useAppContext()
  const experimentData = useExperimentsContext()

  const sectionAvailable = determineSectionAvailability(configuration, locale)
  if (!sectionAvailable) {
    return <></>
  }

  // todo : figure out what this TestSection is and why is pageContext passed to it
  const TestPageHeader = TestSection(experimentData, configuration.type)
  if (TestPageHeader) {
    return (
      <TestPageHeader configuration={configuration} pageContext={pageDataFromBFF} sidebar={sidebar} />
    )
  }
  const marginBottom = PAGEHEADER_TYPES.includes(configuration.type) ? 8 : 0

  switch (configuration.type) {
    case 'side by side page header':
      return (
        <BoundedContent paddingX={0} marginBottom={{ xs: 7, sm: 7, md: 8 }}>
          <SideBySideHero section={configuration} />
        </BoundedContent>
      )
    case 'page header':
    case 'sub-category page header':
    case 'section header':
      return renderPageHeader(configuration, pageDataFromBFF, marginBottom, sidebar)
    default:
      return <></>
  }
}

const renderPageHeader = (
  configuration: PageHeaderType,
  pageDataFromBFF: BFFPageData,
  marginBottom?: Record<string, number> | number,
  sidebar?: SidebarType
) => {
  // Backwards compatibility
  if (!configuration.headers) {
    return (
      <PageHeader
        headerType={configuration.type}
        // @ts-ignore
        configuration={configuration}
        pageDataFromBFF={pageDataFromBFF}
        sidebar={sidebar}
        marginBottom={marginBottom}
      />
    )
  }

  if ((configuration.headers || []).length > 1 && !sidebar?.enabled) {
    return (
      <PageHeaderCarousel
        configuration={configuration}
        pageDataFromBFF={pageDataFromBFF}
        marginBottom={marginBottom}
      />
    )
  }

  return (
    <PageHeader
      headerType={configuration.type}
      configuration={{ ...configuration.headers[0] }}
      pageDataFromBFF={pageDataFromBFF}
      sidebar={sidebar}
      marginBottom={marginBottom}
    />
  )
}

export { getPageHeaderConfig }

export default PageHeaderContainer
