import { useEffect, useState } from 'react'
import { getVariation, whenAvailable, fireImpression } from '@vp/ab-reader'
import { useAppContext } from '../../state/AppContext'
import { BUSINESS_CARDS_REORDER_EXPERIMENT_NAME } from '../../components/reorderComponents/reorderExperimentData'
import useFetchOrders from '../../components/reorderComponents/useFetchOrders'

const useExperiment = <T extends string = string>(
  supportedVariations: T[],
  supportedLocales?: string[],
  supportedMerchandisingCategories?: string[],
  experimentKey?: string
): T | undefined => {
  const { locale, merchandisingCategoryId } = useAppContext()
  const [variationKey, setVariationKey] = useState<T | undefined>()
  const { hasReorderableItems } = useFetchOrders()

  const showExperiment: boolean =
    !!variationKey &&
    supportedVariations.includes(variationKey) &&
    (supportedLocales
      ? supportedLocales.includes(locale.toLocaleLowerCase())
      : true) &&
    (supportedMerchandisingCategories
      ? supportedMerchandisingCategories.includes(merchandisingCategoryId)
      : true)

  useEffect(() => {
    let mounted = true
    if (experimentKey) {
      whenAvailable(() => {
        if (mounted) {
          const variation = (getVariation(experimentKey) as T) || undefined
          setVariationKey(variation)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [experimentKey])

  useEffect(() => {
    if (showExperiment) {
      const analytics = async () => {
        if (
          hasReorderableItems &&
          experimentKey === BUSINESS_CARDS_REORDER_EXPERIMENT_NAME &&
          variationKey
        ) {
          fireImpression(experimentKey, variationKey)
        }
        if (
          experimentKey &&
          variationKey &&
          experimentKey !== BUSINESS_CARDS_REORDER_EXPERIMENT_NAME
        ) {
          fireImpression(experimentKey, variationKey)
        }
      }
      analytics()
    }
  }, [variationKey, hasReorderableItems])

  return showExperiment ? variationKey : undefined
}

export default useExperiment
