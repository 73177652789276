export const GALLERY_CONTENT_QUERY_API = 'https://gallery-content-query.cdn.vpsvc.com/api/v2'
export const GALLERY_CONTENT_API =
  'https://gallery-content-api-experiment.gallery.vpsvc.com/api/v4'
export const REQUESTOR = 'requestor=category-page'

export const GALLERY_NAME_NA = 'standard-business-cards'
export const GALLERY_NAME_GB = 'business-cards-standard'

export const GALLERY_INDUSTRY_CATEGORY_KEY = 1
export const GALLERY_USE_CASE_CATEGORY_KEY = 1299

export const QUERY_PARAM_TAB = 'tab'

export const ALL_POPULAR_CATEGORY = {
  'en-US': 'Most Popular',
  'en-GB': 'Most Popular',
  'es-US': 'Más Popular',
}

export const IMAGE_ALIGN = 'middle'
export const BASE_URL = {
  'en-US': 'https://www.vistaprint.com',
  'es-US': 'https://www.vistaprint.com/es',
  'en-GB': 'https://www.vistaprint.co.uk',
}

export const QUICK_VIEW_PATH = {
  'en-US': '/business-cards/standard/templates',
  'es-US': '/tarjetas-de-presentacion/estandar/plantillas',
  'en-GB': '/business-cards/standard/templates',
}

export const ANCHOR_BAR_CONFIG = {
  'en-US': {
    categorySectionSectionId: 'design-template',
    categorySectionType: 'Popular templates',
    title: {
      contentful_id: '73E1Cu8mr6Ut7vuOYgBDkY',
      value: 'Popular templates',
    },
  },
  'es-US': {
    categorySectionSectionId: 'design-template',
    categorySectionType: 'Popular templates',
    title: {
      contentful_id: '73E1Cu8mr6Ut7vuOYgBDkY',
      value: 'Plantillas populares',
    },
  },
  'en-GB': {
    categorySectionSectionId: 'design-template',
    categorySectionType: 'Popular templates',
    title: {
      contentful_id: '73E1Cu8mr6Ut7vuOYgBDkY',
      value: 'Popular templates',
    },
  },
}

export const config = {
  'en-US': {
    title: 'Popular templates',
    description:
      'Check out bestselling designs for your type of business. Simply plug in your info, or use them as a starting point for creating your card.',
    cta: 'Browse all templates',
  },
  'es-US': {
    title: 'Plantillas populares',
    description:
      'Échele un vistazo a nuestros diseños más populares según su tipo de negocio. Simplemente ingrese su información o úselos como un punto de partida para crear su tarjeta.',
    cta: 'Ver todas las plantillas',
  },
  'en-GB': {
    title: 'Popular templates',
    description:
      'Check out bestselling design templates for your type of business. Simply plug in your info to use them as a starting point for creating your card.',
    cta: 'Browse all templates',
  },
}
