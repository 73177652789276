import {
  FlexBox,
  Button,
  Icon,
  ModalDialog,
  ModalDialogContent,
  ModalDialogNav,
  ModalDialogCloseButton,
  ModalDialogBody,
  Box,
  ModalDialogFooter,
  ModalDialogButtons,
} from '@vp/swan'

interface ProductPreviewModalProps {
  children: React.ReactNode;
  productKey: string;
  closeText: string;
  openPreviewModal: (isOpen: boolean) => void;
  isOpenPreviewModal: boolean;
}

const ProductPreviewModal = (props: ProductPreviewModalProps) => {
  const {
    children,
    productKey,
    closeText,
    openPreviewModal,
    isOpenPreviewModal,
  } = props
  return (
    <>
      <FlexBox alignItems='center' flexDirection='column' mt={1}>
        <Button skin='unstyled' onClick={() => openPreviewModal(true)}>
          <Icon size='standard' iconType='zoomIn' alt='' margin={4} />
        </Button>
      </FlexBox>
      <ModalDialog
        isOpen={isOpenPreviewModal}
        onRequestDismiss={() => openPreviewModal(false)}
        bodyWidth='capped'
        onlyRenderWhenOpen
      >
        <ModalDialogContent style={{ width: '600px' }} aria-label={productKey}>
          <ModalDialogNav>
            <ModalDialogCloseButton accessibleText='close' />
          </ModalDialogNav>
          <ModalDialogBody>
            <Box mt={5} px={6}>
              {children}
            </Box>
          </ModalDialogBody>
          <ModalDialogFooter>
            <ModalDialogButtons>
              <Button
                width='full-width'
                skin='primary'
                onClick={() => openPreviewModal(false)}
              >
                {closeText}
              </Button>
            </ModalDialogButtons>
          </ModalDialogFooter>
        </ModalDialogContent>
      </ModalDialog>
    </>
  )
}
export default ProductPreviewModal
