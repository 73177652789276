import React, { useRef } from 'react'
import {
  Row,
  Column,
  Typography,
  FlexBox,
  Link,
  Carousel,
  CarouselSlide,
  useScreenClass,
  H2,
} from '@vp/swan'
import { useAppContext } from '../state/AppContext'
import { getGalleryUrl } from '../utils/gallery/gallery'
import { Gallery } from '../types/Gallery'
import { trackClickEvent } from '../utils/tracking/tracking'
import { ContentfulSimpleLink } from '../types/Contentful/ContentfulTypes'
import { TemplateCollectionType } from '../types/TemplateConfiguration'
import { Translated } from '../types/Global'
import { getAccessibleTextForDots } from '../utils/accessibility/accessibility'
import { DebugPageSections } from './debugComponents/DebugPageSections'
import { SkipToNextSectionLink } from './SkipToNextSectionLink'
import TemplateTile from './TemplateTile'
import { primaryContentExperimentVariationControl, primaryContentExperimentVariationTest, primaryContentExperimentVariationTest2 } from '../constants/primaryContentTestData'

interface TemplateCollectionLaneProps {
  configuration: TemplateCollectionType;
  galleries?: Gallery[];
  nextSectionId?: string;
  nextSectionTitle?: Translated;
}

const TemplateCollectionLane = (props: TemplateCollectionLaneProps) => {
  const { configuration, galleries, nextSectionTitle, nextSectionId } = props
  const { title, subtitle, cta, items } = configuration
  const {
    locale,
    sidebarEnabled,
    merchandisingCategoryId,
    referencedFields,
    dictionaryEntries,
    isPagePartOfPrimarayContentExperiment,
    primaryContentExperimentVariation
  } = useAppContext()
  const imageRef = useRef()
  const screenClass = useScreenClass()
  const isMobile = screenClass === 'xs'
  let tilesCount = items.length
  items.forEach((item: any) => {
    if (
      !item.link.cloudinaryImage?.cloudinaryUrl ||
      !(referencedFields[`${item.link.contentful_id}`] as ContentfulSimpleLink)?.targetUrl
    ) {
      tilesCount--
    }
  })

  /*
    render 6 tiles for primary content experiment variation test
    render 8 tiles for primary content experiment variation test2
    render by existing logic otherwise
  */
  const slidesToShow = isPagePartOfPrimarayContentExperiment
    ? primaryContentExperimentVariation === primaryContentExperimentVariationTest
      ? 6
      : primaryContentExperimentVariation === primaryContentExperimentVariationTest2
        ? 8
        : sidebarEnabled ? (tilesCount <= 4 ? 4 : 6) : tilesCount <= 6 ? 6 : 8
    : sidebarEnabled ? (tilesCount <= 4 ? 4 : 6) : tilesCount <= 6 ? 6 : 8

  const slidesToShowCountMobile = isPagePartOfPrimarayContentExperiment &&
    primaryContentExperimentVariation !== primaryContentExperimentVariationControl
    ? 3
    : 2.05

  // The slidesToShow property now supports responsive values, helps prevent flicker on page load.
  const slidestoShowCount = {
    xs: slidesToShowCountMobile,
    sm: slidesToShow,
    md: slidesToShow,
    lg: slidesToShow,
    xl: slidesToShow,
  }

  const arrowVerticalPosition =
    imageRef.current?.clientHeight && imageRef.current?.clientHeight > 0 ? `${Math.round(imageRef.current.clientHeight / 2)}px` : undefined
  const ctaReferenceField =
    cta && (referencedFields[`${cta.contentful_id}`] as ContentfulSimpleLink)

  const ctaElement =
    cta && ctaReferenceField
      ? (
        <FlexBox justifyContent='flex-end' alignItems='flex-end'>
          <Typography fontSize='standard'>
            <Link
              href={ctaReferenceField.targetUrl}
              skin='cta'
              data-section='Template Collection Lane'
              data-translation={merchandisingCategoryId}
              onClick={() =>
                trackClickEvent({
                  categoryId: merchandisingCategoryId,
                  destinationUrl: ctaReferenceField.targetUrl,
                  ctaValue: 'CTA',
                  pageZone: 'Template Collection Lane',
                })}
            >
              {cta.text}
            </Link>
          </Typography>
        </FlexBox>
        )
      : undefined

  const carouselSlides = items

    .map((item: any, index: number, items: any) => {
      const { cloudinaryImage, text, contentful_id, actionId, actionFilters } = item?.link || {}
      const linkReferencedField = referencedFields[`${contentful_id}`] as ContentfulSimpleLink

      if (!cloudinaryImage?.cloudinaryUrl || !linkReferencedField?.targetUrl) {
        return null
      }

      const url: string =
        getGalleryUrl({ galleries, actionId, actionFilters, locale }) ||
        linkReferencedField?.targetUrl

      return (
        <CarouselSlide paddingX='3' key={`template-collection-tile-${contentful_id}`}>
          <TemplateTile
            link={url}
            imageUrl={cloudinaryImage.cloudinaryUrl}
            text={text}
            mpvId={merchandisingCategoryId}
            index={index + 1}
            imageRef={imageRef}
            sectionType='Template Collection Lane'
            itemsCount={items?.length}
            sectionTypeCount={configuration.sectionTypeCount ?? 1}
          />
        </CarouselSlide>
      )
    })
    .filter((slide) => !!slide)

  if (!carouselSlides.length) {
    return <></>
  }

  const { accessibleTextForNext, accessibleTextForPrevious } = dictionaryEntries
  const accessibleTextForDots = getAccessibleTextForDots(
    dictionaryEntries.accessibleTextForDots,
    carouselSlides.length
  )

  return (
    <>
      {title && title.value && (
        <Row mb='5'>
          <Column span={10}>
            <H2 fontSize='x2large' mb={0}>
              {title.value}
            </H2>
          </Column>
        </Row>
      )}
      <SkipToNextSectionLink nextSectionId={nextSectionId} nextSectionTitle={nextSectionTitle} />
      {((subtitle && subtitle.value) || ctaElement) && (
        <Row>
          <Column span={sidebarEnabled ? 7 : 5}>
            {subtitle && subtitle.value && <Typography>{subtitle.value}</Typography>}
          </Column>
          <Column span={sidebarEnabled ? 5 : 7}>{ctaElement}</Column>
        </Row>
      )}
      <Row marginTop={7} mb='5'>
        <Column span={12}>
          <Carousel
            swipeToSlide
            slidesToShow={slidestoShowCount}
            arrows={!isMobile}
            infinite={false}
            arrowVerticalPosition={arrowVerticalPosition}
            progressIndicator={isMobile ? 'dots' : undefined}
            accessibleTextForPrevious={accessibleTextForPrevious}
            accessibleTextForDots={accessibleTextForDots}
            accessibleTextForNext={accessibleTextForNext}
          >
            {carouselSlides}
          </Carousel>
        </Column>
      </Row>
      <DebugPageSections rawSectionData={configuration} />
    </>
  )
}

export default TemplateCollectionLane
