import {
  Banner,
  BannerDescription,
  BannerImage,
  BannerImageContainer,
  BannerLinks,
  BannerText,
  BannerTextContainer,
  BannerTitle,
  DoubleBanner,
} from '@vp/swan'
import React from 'react'
import { BannerMedia } from './BannerMedia'
import { useAppContext } from '../state/AppContext'
import { SideBySideHeroType } from '../types/TemplateConfiguration'
import StandardHeroCTA from './StandardHeroContent/StandardHeroCTA'
import StandardHeroUploadFlowCTA from './StandardHeroContent/StandardHeroUploadFlowCTA'
import {
  getCtasWithUrl,
  getFontFamilyType,
  getFontFamilyWeight,
  isOfTypePageHeader,
} from '../utils/standardHeroContents/standardHeroContents'
import CustomBannerWrapper from './Banner'
import { MARKETING_COLORS } from '@vp/marketing-colors'

interface SideBySideHeroProps {
  section: SideBySideHeroType;
}

const SideBySideHero = (props: SideBySideHeroProps) => {
  const { section } = props
  const {
    referencedFields,
    merchandisingCategoryId,
    uploadFlowMpv,
  } = useAppContext()

  const newBanner = section?.banner
  if (newBanner) {
    if (newBanner?.bannerTemplate) {
      return (
        <CustomBannerWrapper
          bannerProps={newBanner?.bannerTemplate}
          headerType='Side by Side Hero'
        />
      )
    } else {
      return <></>
    }
  }
  return (
    <DoubleBanner mb={section.type === 'side by side page header' ? 8 : 0}>
      {['side1', 'side2'].map((side) => {
        const typedSide = side as 'side1' | 'side2'
        const color = section.content?.[`${typedSide}`].backgroundColor.name

        const fullUploadFlowMpv = section.content?.[`${typedSide}`].uploadFlowMpv?.mpvId
          ? uploadFlowMpv
          : undefined
        const ctasWithUrl = getCtasWithUrl(
          referencedFields,
          section.content?.[`${typedSide}`].cta1,
          section.content?.[`${typedSide}`].cta2,
          section.content?.[`${typedSide}`].cta3
        )
        const totalCtaCount = fullUploadFlowMpv ? ctasWithUrl.length + 1 : ctasWithUrl.length
        const fontFamily = section.content?.[`${typedSide}`].fontFamily
        const imageFocalPoint = section.content?.[`${typedSide}`].imageFocalPoint
        const isPageHeader = isOfTypePageHeader(section.type)
        const fontFamilyType = getFontFamilyType(isPageHeader, fontFamily)
        const fontFamilyWeight = getFontFamilyWeight(isPageHeader, fontFamily)
        const fontFamilyProps: Record<string, string> = {}
        if (fontFamilyType) {
          fontFamilyProps.fontFamily = fontFamilyType
        }
        if (fontFamilyWeight) {
          fontFamilyProps.fontWeight = fontFamilyWeight
        }

        const ctaArray = ctasWithUrl.map((cta, i) => {
          return (
            <StandardHeroCTA
              key={`hero-cta-${i}`}
              index={i}
              cta={cta}
              referencedFields={referencedFields}
              merchandisingCategoryId={merchandisingCategoryId}
              pageZone={`Side by Side Hero ${side}`}
              ctaUrlsLength={totalCtaCount}
            />
          )
        })

        if (fullUploadFlowMpv) {
          const uploadCta = (
            <StandardHeroUploadFlowCTA
              key='upload-flow-cta'
              pageZone={`Side by Side Hero ${side}`}
              uploadFlowMpv={fullUploadFlowMpv}
              totalCtaCount={totalCtaCount}
            />
          )
          ctaArray.splice(1, 0, uploadCta)
        }

        return (
          <Banner
            key={`side-by-side-hero-${typedSide}`}
            style={
              {
                '--swan-public-marketing-background': color ? MARKETING_COLORS[color]?.code : '',
              } as React.CSSProperties
            }
            darkMode={color ? MARKETING_COLORS[color]?.mode.dark : false}
            textBoxHorizontalAlign={
              section.content?.[`${typedSide}`].horizontalTextAlignment ?? 'left'
            }
            variant='full-width-image'
            textAlign={
              (section.content?.[`${typedSide}`].verticalTextAlignment ??
                'bottom') as Responsive<StyleTextAlign>
            }
            imageFocalPoint={imageFocalPoint ?? 'right bottom'}
          >
            <BannerImageContainer>
              <BannerImage>
                <BannerMedia
                  media={section.content?.[`${typedSide}`].image}
                  mediaXs={section.content?.[`${typedSide}`].imageXs}
                />
              </BannerImage>
            </BannerImageContainer>
            <BannerTextContainer>
              <BannerText>
                <BannerTitle {...fontFamilyProps}>
                  {section.content?.[`${typedSide}`].title.value}
                </BannerTitle>
                <BannerDescription>
                  {section.content?.[`${typedSide}`].subtitle?.value}
                </BannerDescription>
                <BannerLinks>{ctaArray}</BannerLinks>
              </BannerText>
            </BannerTextContainer>
          </Banner>
        )
      })}
    </DoubleBanner>
  )
}

const showSideBySideHero = (section: any) => {
  if (typeof section?.banner !== 'undefined') {
    return section?.banner?.bannerTemplate?.length === 2
  }
  return section?.content?.side1?.title?.value && section?.content?.side2?.title?.value
}

export { showSideBySideHero }

export default SideBySideHero
