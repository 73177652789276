import { BLOCKS, Document } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Li, Ol, P, Ul, tokens } from '@vp/swan'
import React, { ReactNode } from 'react'

interface Props {
  productTileDescription: Document;
}

const ProductTileLongDescription: React.FC<Props> = ({ productTileDescription }) => {
  let productTileLongDescriptionHtml: ReactNode
  let numberOfBullets: number = 0
  const bulletsStyle = {
    padding: `${tokens.SwanSemSpaceNone} ${tokens.SwanSemSpaceNone} ${tokens.SwanSemSpace2} ${tokens.SwanSemSpaceNone}`,
  }

  enum BoxOrient {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
  }

  const lineStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: BoxOrient.Vertical,
    MozBoxOrient: BoxOrient.Vertical, // Mozilla fallback
    msBoxOrient: BoxOrient.Vertical, // IE fallback
    lineHeight: tokens.SwanBaseFontLineHeight500,
    maxHeight: `calc(${tokens.SwanBaseFontLineHeight500} * 2)`,
  }

  const options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node: any, children: ReactNode | undefined) => {
        return <Ul>{children}</Ul>
      },
      [BLOCKS.OL_LIST]: (node: any, children: ReactNode | undefined) => {
        return <Ol>{children}</Ol>
      },
      [BLOCKS.LIST_ITEM]: (node: any, children: ReactNode | undefined) => {
        numberOfBullets++
        const UnTaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => children,
          },
          renderText: (text: string) => {
            return <span style={lineStyle}>{text}</span>
          },
        })
        if (numberOfBullets <= 3) return <Li style={bulletsStyle}>{UnTaggedChildren}</Li>
      },
      [BLOCKS.PARAGRAPH]: (node: any, children: ReactNode | undefined) => {
        const isEmptyChildren = children?.toString().trim() === ''
        if (isEmptyChildren) return null
        return <P>{children}</P>
      },
    },
  }
  if (productTileDescription !== undefined) {
    productTileLongDescriptionHtml = documentToReactComponents(productTileDescription, options)
  }
  return <>{productTileLongDescriptionHtml}</>
}
export default ProductTileLongDescription
