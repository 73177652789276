import { useScreenClass } from '@vp/swan'
import React, { useEffect, useState } from 'react'
import CloudinaryUtil from '../utils/cloudinaryUtil/cloudinaryUtil'
import {
  ContentfulCloudinaryImage,
  ContentfulCloudinaryVideoMedia,
} from '../types/Contentful/ContentfulTypes'
import { CloudinaryImageMedia } from '../components/cloudinary/CloudinaryImageMedia'
import { AutoPlayVideo } from '../components/cloudinary/AutoPlayVideo'

interface StandardHeroMediaProps {
  media?: ContentfulCloudinaryImage | ContentfulCloudinaryVideoMedia;
  mediaXs?: ContentfulCloudinaryImage | ContentfulCloudinaryVideoMedia;
}

export const BannerMedia = (props: StandardHeroMediaProps) => {
  const { media, mediaXs } = props

  const screenClass = useScreenClass()
  const isScreenXs = screenClass === 'xs'

  const mediaXsIsVideo = CloudinaryUtil.isVideo(mediaXs)
  const mediaIsVideo = CloudinaryUtil.isVideo(media)

  let component = <></>

  const [screenClassInitialized, setScreenClassInitialized] = useState(false)

  // Attempt to handle the fact that the screen class is always initially xs
  useEffect(() => {
    setScreenClassInitialized(true)
  }, [])

  if (!mediaXsIsVideo && !mediaIsVideo) {
    // No videos
    component = (
      <CloudinaryImageMedia
        media={media}
        mediaXs={mediaXs}
        alt={(media as ContentfulCloudinaryImage)?.altTextOverride}
      />
    )
  } else if (screenClassInitialized) {
    if (isScreenXs) {
      // Use xs media if it exists, otherwise use the non-xs media
      // Using different components (rather than just changing the urls) for each screen size is intentional,
      // simply changing the <video> url does not update the video, like an image would
      if (mediaXs) {
        component = mediaXsIsVideo
          ? (
            <AutoPlayVideo media={mediaXs} screenClass={screenClass} />
            )
          : (
            <CloudinaryImageMedia
              mediaXs={mediaXs}
              alt={(mediaXs as ContentfulCloudinaryImage)?.altTextOverride}
            />
            )
      } else {
        component = mediaIsVideo
          ? (
            <AutoPlayVideo media={media} screenClass={screenClass} />
            )
          : (
            <CloudinaryImageMedia
              media={media}
              alt={(media as ContentfulCloudinaryImage)?.altTextOverride}
            />
            )
      }
    } else {
      component = mediaIsVideo
        ? (
          <AutoPlayVideo media={media} screenClass={screenClass} />
          )
        : (
          <CloudinaryImageMedia
            media={media}
            alt={(media as ContentfulCloudinaryImage)?.altTextOverride}
          />
          )
    }
  }

  return component
}
